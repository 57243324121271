import React, { CSSProperties, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TrellisLogo from 'src/assets/logo.png';
import firebase from 'src/firebaseConfig';
import { Colors } from 'src/utils/colors';
import Swal from 'sweetalert2';

import {
    finishVerification,
    getSecondsUntilCanResendVerificationEmail,
    getWasEmailVerificationSentRecently,
    SECONDS_BETWEEN_VERIFICATION
} from './lib';

export const VerifyEmail = () => {
    const history = useHistory();
    const [
        emailVerificationTimestampStr,
        setEmailVerificationTimestampStr
    ] = useState<string>(
        localStorage.getItem('sentEmailVerificationTimestamp') || ''
    );
    const [secondsUntilCanResend, setSecondsUntilCanResend] = useState<number>(
        SECONDS_BETWEEN_VERIFICATION
    );
    const [wasEmailSentRecently, setWasEmailSentRecently] = useState<boolean>(
        true
    );
    const currentFirebaseUser = firebase.auth().currentUser;
    const email = currentFirebaseUser?.email;

    useEffect(() => {
        if (currentFirebaseUser?.emailVerified) {
            history.push('/');
        }
    }, [currentFirebaseUser?.emailVerified]);

    useEffect(() => {
        let secondsUpdater = setInterval(() => {
            setSecondsUntilCanResend(
                getSecondsUntilCanResendVerificationEmail(
                    emailVerificationTimestampStr
                )
            );
            setWasEmailSentRecently(
                getWasEmailVerificationSentRecently(
                    emailVerificationTimestampStr
                )
            );
        }, 1000);
        return () => {
            clearInterval(secondsUpdater);
        };
    }, [wasEmailSentRecently]);

    const resendEmail = () => {
        if (wasEmailSentRecently) {
            Swal.fire({
                text: `Please wait ${secondsUntilCanResend} seconds before sending another verification email.`,
                icon: 'info'
            });
            return;
        }
        const newTimestampStr = new Date().getTime().toString();
        localStorage.setItem('sentEmailVerificationTimestamp', newTimestampStr);
        currentFirebaseUser?.sendEmailVerification();
        setEmailVerificationTimestampStr(newTimestampStr);
        setWasEmailSentRecently(true);
        setSecondsUntilCanResend(SECONDS_BETWEEN_VERIFICATION);
        Swal.fire({
            text: `Resent a verification email to ${email}`,
            icon: 'success'
        });
    };

    return (
        <div style={styles.container}>
            <div style={styles.innerContainer}>
                <div style={styles.innerTitleContainer}>
                    <img src={TrellisLogo} style={styles.logo} alt="" />
                    <label style={styles.title}>Almost There!</label>
                </div>
                <label style={styles.desc1}>
                    We sent a verification email to{' '}
                    <label style={styles.innerEmailText}>{email}</label>
                </label>
                <label style={styles.desc2}>
                    Please click the link in the email and hit the button below
                    when done.
                </label>
                <button style={styles.continueBtn} onClick={finishVerification}>
                    I'm Ready
                </button>
                <div style={styles.lowerContainer}>
                    {secondsUntilCanResend ===
                    SECONDS_BETWEEN_VERIFICATION ? null : (
                        <button
                            onClick={resendEmail}
                            style={{
                                ...styles.smallActionBtn,
                                marginRight: 15
                            }}
                        >
                            {wasEmailSentRecently
                                ? `Can resend email in ${secondsUntilCanResend} seconds`
                                : 'Resend email'}
                        </button>
                    )}
                    <button
                        onClick={() => firebase.auth().signOut()}
                        style={styles.smallActionBtn}
                    >
                        Use different email
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.steelBlueHint,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    } as CSSProperties,
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '80%',
        maxHeight: '80%',
        alignItems: 'flex-start',
        backgroundColor: Colors.white,
        padding: 30,
        borderRadius: 20,
        boxShadow: '0px 0px 20px 8px rgba(0,0,0,0.2)'
    } as CSSProperties,
    smallActionBtn: {
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: Colors.transparent,
        color: Colors.steelBlueHint,
        textDecoration: 'underline',
        marginTop: 30
    },
    title: {
        fontSize: 30,
        fontWeight: 700,
        marginLeft: 15
    },
    desc1: {
        fontSize: 18,
        fontWeight: 400,
        marginTop: 25
    },
    desc2: {
        fontSize: 18,
        fontWeight: 400
    },
    continueBtn: {
        backgroundColor: Colors.purple,
        borderRadius: 20,
        paddingLeft: 22,
        paddingRight: 22,
        paddingTop: 10,
        paddingBottom: 10,
        color: Colors.white,
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        marginTop: 20,
        fontWeight: 600
    },
    innerEmailText: {
        color: Colors.purple,
        fontWeight: 600
    },
    innerTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    } as CSSProperties,
    logo: {
        width: 50,
        height: 50
    },
    lowerContainer: {
        display: 'flex',
        flexDirection: 'row'
    } as CSSProperties
};
