import 'src/screens/botWorkspace/index.css';

import { prop } from 'lodash/fp';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NEW_BOT_EDITOR_PATH } from 'src/App';
import { getAuthStatus } from 'src/redux/auth/selector';
import { fetchCurrentBot } from 'src/redux/bot/action';
import {
    getBotId,
    getBotName,
    getIsRealMoneyModalVisible,
    getPerformanceInterval,
    getTicker
} from 'src/redux/bot/selector';
import { WorkspaceSidebar } from 'src/screens/botWorkspace/components/WorkspaceSidebar';
import { getLastPath } from 'src/utils/helpers';
import Swal from 'sweetalert2';

import { RealMoneyModal } from './components/RealMoneyModal';
import { ShareLink } from './components/ShareLink';
import { useIsVisitorUsingAccessCode } from './lib';
import { IntegrationsScreen } from './screens/Integrations';
import { MetricsScreen } from './screens/Metrics';
import { TriggersScreen } from './screens/Triggers';
import { isMobile } from 'react-device-detect';

export const BotWorkspace = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const authStatus = useSelector(getAuthStatus);
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const ticker = useSelector(getTicker);
    const botName = useSelector(getBotName);
    const botId = useSelector(getBotId);
    const performanceInterval = useSelector(getPerformanceInterval);
    const isRealMoneyModalVisible = useSelector(getIsRealMoneyModalVisible);
    const { isVisitorUsingAccessCode, accessCode } =
        useIsVisitorUsingAccessCode();

    const handleFetchBot = async () => {
        try {
            setIsLoading(true);
            const paramBotId = prop('id', params);
            if (paramBotId !== NEW_BOT_EDITOR_PATH) {
                await fetchCurrentBot({
                    dispatch,
                    botId: paramBotId,
                    accessCode: accessCode as string,
                    performanceInterval
                });
            }
            if ((!ticker || !botName) && paramBotId === NEW_BOT_EDITOR_PATH) {
                history.push('/setup-bot/stocks');
            }
        } catch {
            Swal.fire({
                title: 'Sorry',
                text: "We can't find this bot.",
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile) {
            Swal.fire({
                title: 'Please Note',
                text: 'Our mobile website is a work in progress. Please use desktop for the best experience.',
                icon: 'info'
            });
        }
    }, []);

    useEffect(() => {
        if (
            authStatus === 'SIGNED_IN' ||
            (isVisitorUsingAccessCode && authStatus !== 'UNDETERMINED')
        ) {
            handleFetchBot();
        }
    }, [authStatus, isVisitorUsingAccessCode]);

    let screen: JSX.Element | null = null;
    switch (getLastPath(location.pathname)) {
        case 'metrics':
            screen = <MetricsScreen />;
            break;
        case 'integrations':
            if (isVisitorUsingAccessCode) {
                screen = null;
            } else {
                screen = <IntegrationsScreen />;
            }
            break;
        case 'buy-triggers':
            screen = <TriggersScreen />;
            break;
        case 'sell-triggers':
            screen = <TriggersScreen />;
            break;
        default:
            break;
    }

    return (
        <div style={styles.container}>
            <ShareLink botId={botId} />
            <WorkspaceSidebar isLoading={isLoading} />
            <div style={styles.screenContainer}>{screen}</div>
            <RealMoneyModal isVisible={isRealMoneyModalVisible} />
        </div>
    );
};

const styles = {
    screenContainer: {
        paddingLeft: 60,
        marginRight: 60,
        width: 'calc(100% - 300px)',
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    } as CSSProperties
};
