import { PlanEnum } from 'trellis-types';
import { StringLocale } from 'yup';
import axios from '../utils/axios';

type CreateUserBody = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
};

type UpdateUserBody = {
    acceptedTermsAndConditions?: boolean;
    profilePictureUrl?: string;
};

const Users = {
    createUser: ({ firstName, lastName, email, password }: CreateUserBody) =>
        axios.post('/users/', {
            firstName,
            lastName,
            email,
            password
        }),
    updateUser: ({
        acceptedTermsAndConditions,
        profilePictureUrl
    }: UpdateUserBody) =>
        axios.put('/users/', {
            acceptedTermsAndConditions,
            profilePictureUrl
        }),
    getCurrentUser: () => axios.get('/users/current'),
    sendPhoneVerificationCode: (phoneNumber: string) =>
        axios.post('/users/phone/send-code', {
            phoneNumber
        }),
    submitVerificationCode: (phoneNumber: string, code: string) =>
        axios.post('/users/phone/verify', {
            phoneNumber,
            code
        }),
    getCheckoutPageUrl: (plan: PlanEnum) =>
        axios.put('/users/plan', {
            plan
        }),
    referUser: (email: string) =>
        axios.post('/users/refer', {
            email
        })
};

export default Users;
