import { CSSProperties } from 'react';
import { Colors } from 'src/utils/colors';

export const responsiveStyles = (isWindowSkinny: boolean) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        backgroundColor: Colors.white,
        marginLeft: 65,
        marginRight: 65,
        ...(isWindowSkinny
            ? { paddingTop: 50 }
            : { justifyContent: 'center', height: '100%', flex: 1 })
    } as CSSProperties,
    signInTitle: {
        fontWeight: 'bold',
        fontSize: 24
    } as CSSProperties,
    inputSection: {
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    inputDesc: {
        marginTop: 20,
        marginBottom: 10,
        fontSize: 16
    },
    inputTextfield: {
        fontSize: 16,
        outline: 'none',
        borderStyle: 'solid',
        borderColor: Colors.alto,
        borderWidth: 1,
        borderRadius: 3,
        height: 32,
        maxWidth: 480,
        paddingLeft: 10
    },
    signInInsteadButton: {
        textDecoration: 'none',
        color: Colors.purple,
        marginTop: 10
    },
    signUpButton: {
        outline: 'none',
        border: 'none',
        width: '18%',
        minWidth: 140,
        height: 38,
        marginTop: 30,
        color: Colors.white,
        backgroundColor: Colors.purple,
        fontWeight: 700,
        fontSize: 16,
        cursor: 'pointer',
        borderRadius: 12
    },
    errorMessage: {
        marginTop: 10,
        color: Colors.red,
        fontSize: 14,
        fontWeight: 600
    },
    nameSectionMobile: {
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    nameSectionDesktop: {
        display: 'flex',
        flexDirection: 'row'
    } as CSSProperties,
    submitSection: {
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    termsAndConditionsText: {
        textDecoration: 'none',
        color: Colors.darkGray1,
        marginTop: 15
    } as CSSProperties
});
