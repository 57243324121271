import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser } from 'src/redux/user/selector';
import { Colors } from 'src/utils/colors';
import { SegmentEvents } from 'src/utils/segmentClient';
import Swal from 'sweetalert2';
import { BotDocument, PlanEnum } from 'trellis-types';

type Props = {
    bot: BotDocument;
};

export const ViewStrategy = ({ bot }: Props) => {
    const user = useSelector(getUser);
    const history = useHistory();

    const onPress = () => {
        if (user?.currentPlan === PlanEnum.Free) {
            Swal.fire({
                title: 'Premium Feature',
                text: `Sorry, you have to be a premium user to unlock this feature. Click the "Premium" button on the sidebar and follow steps to become a premium user.`
            });
            return;
        }
        analytics.track(SegmentEvents.ClickedSeeBotStrategy, {
            ticker: bot.ticker,
            name: bot.name,
            performance: bot.performance
        });
        history.push(`/bot-workspace/${bot._id}/metrics?accessCode=premium`);
    };

    return bot.isPrivate ? null : (
        <button style={styles.button} onClick={onPress}>
            View Strategy
        </button>
    );
};

const styles = {
    button: {
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: Colors.purple,
        color: Colors.white,
        marginRight: 19,
        borderRadius: 10,
        fontFamily: 'Manrope',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 4,
        paddingBottom: 4,
        boxShadow: '0px 0px 18px rgba(0,0,0,0.2)',
        fontWeight: 600,
        fontSize: 13
    }
};
