import React, { CSSProperties } from 'react';
import { Colors } from 'src/utils/colors';

const ValidationError = ({ message }: { message: any }) => {
    if (!message) {
        return null;
    }
    return <label style={styles.errorText}>{message}</label>;
};

const styles = {
    errorText: {
        marginTop: 10,
        color: Colors.red,
        fontSize: 14,
        fontWeight: 600
    } as CSSProperties
};

export default ValidationError;
