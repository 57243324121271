import * as Sentry from '@sentry/react';
import { Dispatch } from 'redux';
import { CompetitionDocument, UserDocument } from 'trellis-types';
import { Api } from '../../api';

const SET_USER = 'SET_USER';
type SET_USER = typeof SET_USER;
const SET_COMPETITION = 'SET_COMPETITION';
type SET_COMPETITION = typeof SET_COMPETITION;

type ISetUser = {
    type: SET_USER;
    user: UserDocument | null;
};
export const setUser = (user: UserDocument | null): ISetUser => ({
    type: SET_USER,
    user
});

// actions identifiable by the reducer
export type UserActionsIndex = ISetUser;

// api-related actions
export const fetchUser = async (dispatch: Dispatch) => {
    try {
        const userRes = await Api.users.getCurrentUser();
        const userObj: UserDocument | null = userRes.data.user;
        dispatch(setUser(userObj));
    } catch (e) {
        Sentry.captureException(e);
        dispatch(setUser(null));
    }
};
