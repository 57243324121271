import { useWindowHeight } from '@react-hook/window-size';
import React, { CSSProperties, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useScreenReloadSegment } from 'src/utils/helpers';
import { SegmentEvents, analytics } from 'src/utils/segmentClient';
import { NEW_BOT_EDITOR_PATH } from 'src/App';
import { getTicker } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { useResetSetupFlow } from '../lib';
import { PackCell } from './components/PackCell';
import { getStarterPacks, StarterPack } from './lib';
import { Row, Col } from 'react-flexbox-grid';
import { ContinueButton } from './components/ContinueButton';
import { setBuyTriggers, setSellTriggers } from 'src/redux/bot/action';
import { PackModal } from './components/PackModal';

export const StarterPacks = () => {
    useScreenReloadSegment(SegmentEvents.ViewedStarterPacks);
    useResetSetupFlow('PACKS');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [starterPack, setStarterPack] = useState<StarterPack | null>(null);
    const dispatch = useDispatch();
    const windowHeight = useWindowHeight();
    const styles = responsiveStyles(windowHeight);
    const ticker = useSelector(getTicker);
    const history = useHistory();

    const starterPacks = useMemo(() => getStarterPacks(ticker), [ticker]);

    const handleContinue = () => {
        if (starterPack) {
            analytics.track(SegmentEvents.ClickedUseStarterPack, {
                id: starterPack.id
            });
            dispatch(setBuyTriggers(starterPack.buyTriggers));
            dispatch(setSellTriggers(starterPack.sellTriggers));
        }
        history.push(`/bot-workspace/${NEW_BOT_EDITOR_PATH}/buy-triggers`);
    };

    const handleOpenModalForPack = (pack: StarterPack) => {
        analytics.track(SegmentEvents.ClickedStarterPack, {
            id: pack.id
        });
        setIsModalVisible(true);
        setStarterPack(pack);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setStarterPack(null);
    };

    return (
        <>
            <PackModal
                pack={starterPack}
                isModalVisible={isModalVisible}
                closeModal={handleCloseModal}
                utilizePack={handleContinue}
            />
            <div style={styles.container}>
                <div style={styles.headerContainer}>
                    <label style={styles.title}>Want to use a template?</label>
                    <label style={styles.description}>
                        Templates give your bot a pre-setup trading strategy.
                    </label>
                </div>
                <ContinueButton onPress={handleContinue} />
                <Row style={styles.gridContainer}>
                    {starterPacks.map(pack => (
                        <Col key={pack.id} style={styles.packCellContainer}>
                            <PackCell
                                pack={pack}
                                openModalForPack={handleOpenModalForPack}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

const responsiveStyles = (windowHeight: number) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 45,
        alignItems: 'center',
        flex: 1,
        minHeight: windowHeight,
        backgroundColor: Colors.paper
    } as CSSProperties,
    gridContainer: {
        marginTop: 60,
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    } as CSSProperties,
    packCellContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 30,
        marginBottom: 30
    } as CSSProperties,
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 40,
        alignItems: 'center'
    } as CSSProperties,
    title: {
        fontSize: 32,
        fontWeight: 700,
        color: Colors.tundora,
        textAlign: 'center'
    } as CSSProperties,
    description: {
        fontSize: 16,
        fontWeight: 500,
        color: Colors.tundora,
        marginTop: 10,
        textAlign: 'center'
    } as CSSProperties,
    featuredTemplatesTitle: {
        marginTop: 20,
        marginLeft: 10,
        fontSize: 28,
        fontWeight: 700,
        color: Colors.tundora
    } as CSSProperties
});
