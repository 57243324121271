import React from 'react';
import { integrationMap } from 'src/screens/botWorkspace/screens/Integrations/lib';

/* 

This component exists because the RealMoneyTwilioModal is wrapped in conditional logic.

If we were to try to render the modal body inside the conditional logic, there would
be an error with wrapping hooks in conditional logic. We implemented a hacky solution
here by treating this as a component rather than a function which should get rid of
the hooks issue.

*/

export const RealMoneyTwilioModal = () => {
    const IntegrationBody = integrationMap['TWILIO'];
    return (
        <div>
            <IntegrationBody.renderModal
                onClose={() => null}
                isRealMoneyIntegration
            />
        </div>
    );
};
