import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Colors } from 'src/utils/colors';
import ClockIcon from 'src/assets/clockIcon.svg';
import ReactTooltip from 'react-tooltip';
import { BotDocument } from 'trellis-types';
import { useIsWindowSkinny } from 'src/utils/windowHooks';
import { useSelector } from 'react-redux';
import { getMarketIsForcefullyClosed } from 'src/redux/bot/selector';
import { isValidTicker } from 'src/utils/helpers';

type Props = {
    bot: BotDocument | null;
    ignoreWindowSize?: boolean;
};

const getNextRunTimeString = (
    bot: BotDocument | null,
    isMarketForcefullyClosed: boolean
) => {
    if (!bot) {
        return '';
    }
    const currentTime = moment().tz('America/New_York');
    const minutesTillNextRun = 15 - (currentTime.minutes() % 15);
    const timeOfNextRun = moment().add(minutesTillNextRun, 'minutes');
    const isBeforeMarketHours =
        timeOfNextRun.hours() < 9 ||
        (timeOfNextRun.hours() === 9 && timeOfNextRun.minutes() < 30);
    const isAfterMarketHours = timeOfNextRun.hours() >= 16;
    const isWeekend = timeOfNextRun.day() === 6 || timeOfNextRun.day() === 0; // saturday = 6, sunday = 0
    const isOutsideMarketHours =
        isBeforeMarketHours || isAfterMarketHours || isWeekend;

    if (isOutsideMarketHours || isMarketForcefullyClosed) {
        return 'Next run when market opens.';
    }

    const minDateOfNextRun = moment(bot.minDateOfNextRun);
    if (minDateOfNextRun > moment()) {
        return `Sleeping until ${
            minDateOfNextRun.isSame(moment(), 'day')
                ? minDateOfNextRun.format('h:mm A')
                : minDateOfNextRun.format('MM/DD/YY h:mm A')
        }`;
    }

    return `Next run in ${timeOfNextRun.diff(
        currentTime,
        'minutes'
    )} mins (every 15m)`;
};

export const TimeToolTip = ({ bot, ignoreWindowSize }: Props) => {
    const isMarketForcefullyClosed = useSelector(getMarketIsForcefullyClosed);
    const isWindowSkinny = useIsWindowSkinny();
    const [nextRunTimeString, setNextRunTimeString] = useState<string>('');

    useEffect(() => {
        setNextRunTimeString(
            getNextRunTimeString(bot, isMarketForcefullyClosed)
        );
        let intervalFxn = setInterval(() => {
            setNextRunTimeString(
                getNextRunTimeString(bot, isMarketForcefullyClosed)
            );
        }, 10000);
        return () => clearInterval(intervalFxn);
    }, [bot?._id]);

    if (
        !bot ||
        !bot.isActive ||
        !nextRunTimeString ||
        (isWindowSkinny && !ignoreWindowSize) ||
        !isValidTicker(bot.ticker)
    ) {
        return null;
    }

    return (
        <div style={styles.container}>
            <img
                data-tip
                data-for={`clock-icon-${bot._id}`}
                src={ClockIcon}
                alt=""
                style={styles.clockIcon}
            />
            <ReactTooltip
                id={`clock-icon-${bot._id}`}
                effect="solid"
                className="react-tooltip-overrides"
                backgroundColor={Colors.darkerPurple}
            >
                {nextRunTimeString}
            </ReactTooltip>
        </div>
    );
};

const styles = {
    container: {
        marginRight: 15
    },
    clockIcon: {
        width: 20
    }
};
