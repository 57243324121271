import { FrontendTrigger, IndicatorEnum } from 'trellis-types';
import { uuid } from 'uuidv4';
import MovingAverageCrossoverImg from 'src/assets/starterPackBackgrounds/movingAverageCrossover.png';
import RsiImg from 'src/assets/starterPackBackgrounds/basicRsi.jpg';
import MacdImg from 'src/assets/starterPackBackgrounds/macdMomentum.png';

export type StarterPack = {
    id: string; // never change this (for analytics purposes)
    name: string;
    longDescription: string;
    youtubeVideoId: string;
    backgroundCellImage: string;
    buyTriggers: FrontendTrigger[];
    sellTriggers: FrontendTrigger[];
};

export const getStarterPacks = (ticker: string): StarterPack[] => [
    {
        id: 'SMA_CROSSOVER',
        name: 'Simple Moving Average Crossover',
        longDescription: `The 50 day simple moving average is the average price of ${ticker} over the last 50 days. When this crosses above the 200 day moving average, it indicates bullish momentum. If it crosses below, it indicates bearish momentum.\n\nFor this starter pack, we buy when the 50 day simple moving average of ${ticker} crosses above the 200 day simple moving average of ${ticker}. We sell when the 50 day simple moving average of ${ticker} crosses below the 200 day simple moving average of ${ticker}.`,
        youtubeVideoId: 'ktToRNpKNgI',
        backgroundCellImage: MovingAverageCrossoverImg,
        buyTriggers: [
            {
                blockId: uuid(),
                indicator: IndicatorEnum.Ma50,
                operator: 'CROSSES_ABOVE',
                operand: IndicatorEnum.Ma200,
                conjunction: null
            }
        ],
        sellTriggers: [
            {
                blockId: uuid(),
                indicator: IndicatorEnum.Ma50,
                operator: 'CROSSES_BELOW',
                operand: IndicatorEnum.Ma200,
                conjunction: null
            }
        ]
    },
    {
        id: 'RSI_DETECTION',
        name: 'RSI Overbought or Oversold Detection',
        longDescription: `The RSI is a measurement of how quickly traders are bidding the price of ${ticker} up or down, measured on a scale of 0-100. An RSI of 70 generally means that the stock is overbought while an RSI of 30 means that the stock is oversold.\n\nFor this starter pack, we buy when the RSI of ${ticker} crosses below 30. We sell when the RSI of ${ticker} crosses above 70.`,
        youtubeVideoId: 'VH84ppzmq9Q',
        backgroundCellImage: RsiImg,
        buyTriggers: [
            {
                blockId: uuid(),
                indicator: IndicatorEnum.Rsi,
                operator: 'CROSSES_BELOW',
                operand: 30,
                conjunction: null
            }
        ],
        sellTriggers: [
            {
                blockId: uuid(),
                indicator: IndicatorEnum.Rsi,
                operator: 'CROSSES_ABOVE',
                operand: 70,
                conjunction: null
            }
        ]
    },
    {
        id: 'MACD_MOMENTUM',
        name: 'MACD Momentum',
        longDescription: `MACD is an indicator that shows the difference between the previous 12 bars of the faster moving average and the previous 26 bars of the slower moving average. The MACD signal line is an indicator that shows the 9 day exponential moving average of the MACD. Whenever the MACD crosses above the signal line it indicates bullish momentum, while crossing below indicates bearish momentum.\n\nFor this starter pack, we buy when the MACD of ${ticker} crosses above the MACD signal line of ${ticker}. We sell when the MACD of ${ticker} crosses below the MACD signal line of ${ticker}.`,
        youtubeVideoId: 'gF0Ab4WV5uQ',
        backgroundCellImage: MacdImg,
        buyTriggers: [
            {
                blockId: uuid(),
                indicator: IndicatorEnum.Macd,
                operator: 'CROSSES_ABOVE',
                operand: IndicatorEnum.MacdSignal,
                conjunction: null
            }
        ],
        sellTriggers: [
            {
                blockId: uuid(),
                indicator: IndicatorEnum.Macd,
                operator: 'CROSSES_BELOW',
                operand: IndicatorEnum.MacdSignal,
                conjunction: null
            }
        ]
    }
];
