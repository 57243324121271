import * as Sentry from '@sentry/react';
import { prop } from 'lodash/fp';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthStatus } from 'src/redux/auth/selector';
import { fetchIntegrations } from 'src/redux/bot/action';
import { getActiveIntegrations, getBot } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { IntegrationEnum } from 'trellis-types';
import { isUsingRealMoney, useScreenReloadSegment } from 'src/utils/helpers';
import Swal from 'sweetalert2';

import { IntegrationRow } from './components/IntegrationRow';
import { getSortedIntegrationKeys, NonRealIntegrationKeysEnum } from './lib';
import { SegmentEvents } from 'src/utils/segmentClient';
import { JustSavedBot } from './components/JustSavedBot';

export const IntegrationsScreen = () => {
    useScreenReloadSegment(SegmentEvents.ViewedIntegrations);
    const dispatch = useDispatch();
    const bot = useSelector(getBot);
    const botId = prop('_id', bot);
    const authStatus = useSelector(getAuthStatus);
    const showAlpacaIntegrationRow = bot ? isUsingRealMoney(bot) : false;

    const integrations = useSelector(getActiveIntegrations);
    const integrationsCount =
        integrations.length + (showAlpacaIntegrationRow ? 1 : 0);
    const [hasFetchedIntegrations, setHasFetchedIntegrations] =
        useState<boolean>(false);

    // sorts integrations when active integrations are loaded
    const allPossibleIntegrationKeys = getSortedIntegrationKeys(integrations);

    useEffect(() => {
        if (authStatus === 'SIGNED_IN' && botId) {
            handleFetchIntegrations();
        }
    }, [botId, authStatus]);

    const handleFetchIntegrations = async () => {
        try {
            setHasFetchedIntegrations(false);
            await fetchIntegrations(dispatch, botId);
        } catch (e) {
            Sentry.captureException(e);
            Swal.fire({
                title: 'Oops',
                text: 'There was a problem fetching the integrations for your bot.',
                icon: 'error'
            });
        } finally {
            setHasFetchedIntegrations(true);
        }
    };

    if (!bot || !hasFetchedIntegrations) return null;

    return (
        <div style={styles.container}>
            <JustSavedBot />
            <label style={styles.title}>
                Connected to {integrationsCount} integration
                {integrationsCount === 1 ? '' : 's'}
            </label>
            <label style={styles.titleDesc}>
                Integrations will notify you when your bot decides to buy or
                sell.
            </label>
            <div style={styles.integrationsContainer}>
                {showAlpacaIntegrationRow && (
                    <IntegrationRow
                        key="alpaca"
                        fakeIntegrationKey={NonRealIntegrationKeysEnum.Alpaca}
                        isFirstInList={true}
                    />
                )}
                {allPossibleIntegrationKeys.map(
                    (integrationKey: string, i: number) => (
                        <IntegrationRow
                            key={i}
                            realIntegrationKey={
                                integrationKey as IntegrationEnum
                            }
                            existingIntegration={integrations.find(
                                integration =>
                                    integration.type === integrationKey
                            )}
                            isFirstInList={i === 0 && !showAlpacaIntegrationRow}
                        />
                    )
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 60
    } as CSSProperties,
    title: {
        fontSize: 26,
        fontWeight: 600
    } as CSSProperties,
    integrationsContainer: {
        width: '100%',
        marginTop: 27,
        borderRadius: 15,
        backgroundColor: Colors.white,
        boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.1)'
    } as CSSProperties,
    titleDesc: {
        color: Colors.darkGray1,
        fontSize: 15,
        marginTop: 8
    },
    integrationsOptionalMsg: {
        fontSize: 20,
        color: Colors.tundora,
        margin: 40,
        textAlign: 'center'
    } as CSSProperties,
    clickHereBtn: {
        outline: 'none',
        border: 'none',
        color: Colors.purple,
        backgroundColor: Colors.transparent,
        textDecoration: 'none',
        fontWeight: 600
    }
};
