import React, { CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBuyQuantity,
    setBuyQuantityMaximized,
    setSellQuantity,
    setSellQuantityMaximized,
    setShouldForceBuyOnNextRun
} from 'src/redux/bot/action';
import {
    getBotWillForceBuyOnNextRun,
    getTicker,
    useBotCurrentDecisionType,
    useQuantity,
    useQuantityMaximized
} from 'src/redux/bot/selector';
import { useIsVisitorUsingAccessCode } from 'src/screens/botWorkspace/lib';
import { Colors } from 'src/utils/colors';
import { DecisionEnum } from 'trellis-types';

export const DecisionBlock = () => {
    const dispatch = useDispatch();
    const decisionType = useBotCurrentDecisionType();
    const ticker = useSelector(getTicker);
    const quantity = useQuantity();
    const quantityMaximized = useQuantityMaximized();
    const forceBuyOnNextRun = useSelector(getBotWillForceBuyOnNextRun);
    const titleCaseDecisionType =
        decisionType.charAt(0).toUpperCase() +
        decisionType.substr(1).toLowerCase();
    const { isVisitorUsingAccessCode } = useIsVisitorUsingAccessCode();

    const handleQuantityChange = (e: any) => {
        const newQuantityStr = e.target.value.replace(/[^0-9.]/g, '');
        const newQuantityInt = parseInt(newQuantityStr);
        decisionType === DecisionEnum.Buy
            ? dispatch(setBuyQuantity(newQuantityInt))
            : dispatch(setSellQuantity(newQuantityInt));
    };

    const renderQuantityInput = () => (
        <input
            type="number"
            placeholder="Insert quantity"
            onChange={handleQuantityChange}
            value={quantity}
            style={styles.decisionQuantityInput}
            disabled={isVisitorUsingAccessCode}
        />
    );

    const handleBoxChange = (e: any) =>
        decisionType === DecisionEnum.Buy
            ? dispatch(setBuyQuantityMaximized(!quantityMaximized))
            : dispatch(setSellQuantityMaximized(!quantityMaximized));

    const handleForceBuyDecisionBoxChange = () =>
        dispatch(setShouldForceBuyOnNextRun(!forceBuyOnNextRun));

    return (
        <div style={styles.outerContainer}>
            <div style={styles.innerContainer}>
                <label>
                    <b>Then,</b> your bot will {decisionType.toLowerCase()}{' '}
                    {quantityMaximized ? (
                        `as many ${ticker} shares as it can.`
                    ) : (
                        <label>
                            {renderQuantityInput()} shares of {ticker}.
                        </label>
                    )}
                </label>
            </div>
            <div style={styles.innerContainer}>
                <input
                    type="checkbox"
                    onChange={handleBoxChange}
                    checked={!quantityMaximized}
                    style={{ cursor: 'pointer' }}
                    disabled={isVisitorUsingAccessCode}
                />
                <label style={{ marginLeft: 5, fontStyle: 'italic' }}>
                    {titleCaseDecisionType} a custom number of shares
                </label>
            </div>
            {decisionType == DecisionEnum.Buy && (
                <div style={{ ...styles.innerContainer, marginTop: 22 }}>
                    <input
                        type="checkbox"
                        onChange={handleForceBuyDecisionBoxChange}
                        checked={forceBuyOnNextRun}
                        style={{ cursor: 'pointer' }}
                        disabled={isVisitorUsingAccessCode}
                    />
                    <label style={{ marginLeft: 5, fontStyle: 'italic' }}>
                        Force a buy decision on your bot's next run
                    </label>
                </div>
            )}
        </div>
    );
};

const styles = {
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    } as CSSProperties,
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8
    } as CSSProperties,
    decisionQuantityInput: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.lightGray3,
        padding: 5,
        borderRadius: 10,
        fontFamily: 'Manrope',
        fontSize: 13
    } as CSSProperties
};
