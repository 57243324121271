import { useWindowHeight } from '@react-hook/window-size';
import * as Sentry from '@sentry/react';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Colors } from 'src/utils/colors';
import { Constants } from 'src/utils/constants';
import { useScreenReloadSegment } from 'src/utils/helpers';
import { SegmentEvents } from 'src/utils/segmentClient';
import Swal from 'sweetalert2';
import { DecisionEnum, Stock } from 'trellis-types';

import { Api } from '../../../api';
import { getAuthStatus } from '../../../redux/auth/selector';
import { clearCurrentBot, setTicker } from '../../../redux/bot/action';
import { RequestsButton } from '../../botWorkspace/components/RequestsButton';
import { StockInfo } from './components/StockInfo';

const INITIAL_DECISION_TYPE = DecisionEnum.Buy;

export const StockSelection = () => {
    useScreenReloadSegment(SegmentEvents.ViewedStocks);
    const history = useHistory();
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [stocks, setStocks] = useState<Stock[]>([]);
    const authStatus = useSelector(getAuthStatus);
    const windowHeight = useWindowHeight();
    const styles = responsiveStyles(windowHeight);

    useEffect(() => {
        dispatch(clearCurrentBot());
        if (authStatus === 'SIGNED_IN') {
            loadStocks();
        }
    }, [authStatus]);

    const loadStocks = async () => {
        try {
            const apiResponse = await Api.stocks.getStockData();
            const stocks = apiResponse.data.stocks || [];
            setStocks(stocks);
        } catch (e) {
            Sentry.captureException(e);
            Swal.fire(
                'There was an issue fetching the tickers. Please try again.'
            );
        }
    };
    const handleStockOptionPressed = (stock: Stock) => {
        dispatch(setTicker(stock.ticker));
        history.push('/setup-bot/form');
    };

    const renderStocks = () => {
        const filteredStocks = stocks.filter((stock: Stock) =>
            stock.ticker.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (filteredStocks.length) {
            return filteredStocks.map((stock: Stock, index: number) => (
                <StockInfo
                    key={index}
                    stock={stock}
                    onClick={() => handleStockOptionPressed(stock)}
                    isEven={index % 2 === 0}
                    isLast={index === stocks.length - 1}
                />
            ));
        }

        return (
            <label style={styles.noMatchesLbl}>
                Sorry, we couldn't find any matches for the ticker you entered.
                Click{' '}
                <a
                    style={{ color: Colors.purple }}
                    href={Constants.REQUESTS_FORM_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>{' '}
                to request a new ticker.
            </label>
        );
    };

    return (
        <div style={styles.container}>
            <div style={styles.requestBtnContainer}>
                <a style={styles.backBtn} href="/">
                    {'<'} Back to My Bots
                </a>
            </div>
            <label style={styles.title}>
                First, select a ticker for your bot.
            </label>
            <input
                type="text"
                placeholder="Search tickers"
                onChange={e => setSearchQuery(e.target.value.toUpperCase())}
                value={searchQuery}
                style={styles.searchBarInput}
            />
            {stocks.length ? (
                <div style={styles.stocksContainer}>
                    <div style={styles.tableHeader}>
                        <label style={styles.headerText}>Ticker</label>
                        <label style={styles.headerText}>Price</label>
                    </div>
                    {renderStocks()}
                </div>
            ) : (
                <label style={styles.loadingLbl}>Loading stocks...</label>
            )}
        </div>
    );
};

const responsiveStyles = (windowHeight: number) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 30,
        fontSize: 20,
        fontFamily: 'Manrope',
        paddingBottom: 80,
        backgroundColor: Colors.paper,
        flex: 1,
        minHeight: windowHeight
    } as CSSProperties,
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        backgroundColor: Colors.steelBlueHint,
        height: 50,
        borderTopRightRadius: 17,
        borderTopLeftRadius: 17
    } as CSSProperties,
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '20px',
        width: '50%'
    } as CSSProperties,
    searchBarInput: {
        width: '90%',
        outline: 'none',
        border: 'none',
        fontSize: 16,
        borderStyle: 'solid',
        borderColor: Colors.lightGray4,
        borderWidth: 1,
        borderRadius: 10,
        height: 38,
        paddingLeft: 13,
        marginBottom: 20,
        backgroundColor: Colors.lightGray3
    } as CSSProperties,
    searchBarButton: {
        width: '10%',
        fontSize: '20px'
    } as CSSProperties,
    loadingLbl: {
        fontSize: 20,
        fontWeight: 600,
        color: Colors.lightGray5,
        marginTop: 40
    },
    stocksContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        alignItems: 'center',
        borderRadius: 17,
        overflow: 'hidden',
        boxShadow: '0px 0px 25px rgba(0,0,0,0.15)'
    } as CSSProperties,
    headerText: {
        fontSize: 20,
        fontWeight: 600,
        color: Colors.white
    },
    title: {
        marginTop: 20,
        fontWeight: 600,
        fontSize: 32,
        color: Colors.tundora,
        marginBottom: 30
    },
    subtitle: {
        marginBottom: 33,
        fontSize: 17,
        color: Colors.darkGray1,
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: 'center'
    } as CSSProperties,
    requestBtnContainer: {
        position: 'absolute',
        top: 10,
        left: 20
    } as CSSProperties,
    noMatchesLbl: {
        backgroundColor: Colors.lightGray1,
        width: '100%',
        borderBottomLeftRadius: 17,
        borderBottomRightRadius: 17,
        paddingTop: 15,
        paddingBottom: 15,
        textAlign: 'center',
        color: Colors.tundora
    } as CSSProperties,
    backBtn: {
        outline: 'none',
        border: 'none',
        color: Colors.darkGray1,
        backgroundColor: Colors.transparent,
        fontSize: 15,
        fontWeight: 600,
        cursor: 'pointer'
    }
});
