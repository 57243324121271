import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentIndicator } from 'src/redux/bot/action';
import { useIsVisitorUsingAccessCode } from 'src/screens/botWorkspace/lib';
import { Colors } from 'src/utils/colors';
import { Constants, indicatorMap } from 'src/utils/constants';
import { getKeyFromValueInMap } from 'src/utils/helpers';
import { IndicatorEnum, FrontendTrigger } from 'trellis-types';

type UpdateFunction = (
    blockId: string,
    updatedTriggerBlock: FrontendTrigger | null
) => any;

type Props = {
    update: UpdateFunction;
    triggerBlock: FrontendTrigger;
};

export const IndicatorPicker = ({ update, triggerBlock }: Props) => {
    const dispatch = useDispatch();
    const possibleIndicatorKeys = Object.keys(indicatorMap); // array of all possible/usable indicators
    const { blockId, indicator } = triggerBlock;
    const { isVisitorUsingAccessCode } = useIsVisitorUsingAccessCode();

    useEffect(() => {
        dispatch(setCurrentIndicator(Constants.defaultIndicatorKey));
    }, []);

    const handleChange = (e: any) => {
        const triggerObject: FrontendTrigger = {
            ...triggerBlock,
            indicator: getKeyFromValueInMap(
                indicatorMap,
                e.target.value
            ) as IndicatorEnum
        };
        update(blockId, triggerObject);
    };

    return (
        <select
            onChange={handleChange}
            style={styles.indicatorPicker}
            disabled={isVisitorUsingAccessCode}
        >
            {possibleIndicatorKeys.map(key =>
                indicator === key ? (
                    <option selected key={key}>
                        {indicatorMap[key as IndicatorEnum]}
                    </option>
                ) : (
                    <option key={key}>
                        {indicatorMap[key as IndicatorEnum]}
                    </option>
                )
            )}
        </select>
    );
};

const styles = {
    indicatorPicker: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.lightGray3,
        padding: 5,
        borderRadius: 10,
        fontFamily: 'Manrope',
        fontSize: 13,
        marginLeft: 7,
        marginRight: 7,
        height: 32
    }
};
