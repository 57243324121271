import React, { CSSProperties } from 'react';
import { Styles } from 'src/utils/styles';
import TrellisTexts from 'src/assets/trellis-texts.png';
import { Constants } from 'src/utils/constants';
import { Colors } from 'src/utils/colors';
import { useSelector } from 'react-redux';
import { getUser } from 'src/redux/user/selector';

const TEXT_IMAGE_WIDTH = 275;
const TEXT_IMAGE_HEIGHT = 300;

type Props = {
    isTermsOfServiceChecked: boolean;
    onTermsOfServiceChange: (isChecked: boolean) => void;
};

export const RealMoneyExplanationModal = ({
    isTermsOfServiceChecked,
    onTermsOfServiceChange
}: Props) => {
    const user = useSelector(getUser);

    return (
        <div style={styles.contentContainer}>
            <label style={Styles.modalSubheaderText}>Real Money Trading</label>
            {!user?.acceptedTermsAndConditions && (
                <div style={styles.termsOfServiceContainer}>
                    <input
                        type="checkbox"
                        checked={isTermsOfServiceChecked}
                        onChange={() =>
                            onTermsOfServiceChange(!isTermsOfServiceChecked)
                        }
                        style={styles.termsOfServiceCheckbox}
                    />
                    <label>
                        I accept Trellis's Terms of Service which can be found{' '}
                        <a
                            href={Constants.TERMS_AND_CONDITIONS_LINK}
                            style={styles.linkToTermsOfService}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here.
                        </a>
                    </label>
                </div>
            )}
            <label style={Styles.modalDescriptionText}>
                To use real money to buy/sell stocks, you will set triggers as
                you normally do to watch for market events. However, when the
                triggers pass, you will receive a text asking you to confirm a
                purchase or sale of the stock your bot is trading. You can then
                text back to approve the trade.
            </label>
            <div style={styles.imageContainer}>
                <img src={TrellisTexts} style={styles.textImage} alt="" />
            </div>
        </div>
    );
};

const styles = {
    contentContainer: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingBottom: 60,
        textAlign: 'left',
        marginTop: 100,
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    textImage: {
        width: TEXT_IMAGE_WIDTH,
        height: TEXT_IMAGE_HEIGHT,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        paddingTop: 20
    } as CSSProperties,
    imageContainer: {
        width: '100%'
    } as CSSProperties,
    termsOfServiceContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 15,
        padding: 15,
        backgroundColor: Colors.catskillWhite,
        borderRadius: 10
    } as CSSProperties,
    linkToTermsOfService: {
        color: Colors.purple
    },
    termsOfServiceCheckbox: {
        marginRight: 8
    }
};
