import 'src/screens/dashboard/index.css';

import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { getIsMobileMenuOpen } from 'src/redux/dashboard/selector';
import { useIsWindowSkinny } from 'src/utils/windowHooks';

import { Sidebar } from './components/Sidebar';
import { useDashboardScreenManager } from './lib';
import { Colors } from 'src/utils/colors';
import { isMobile } from 'react-device-detect';

export const Dashboard = () => {
    const { renderActiveScreen } = useDashboardScreenManager();
    const isWindowSkinny = useIsWindowSkinny();
    const isSidebarOpen = useSelector(getIsMobileMenuOpen);

    return (
        <div style={styles.container}>
            <Sidebar />
            {!(isWindowSkinny && isSidebarOpen) && (
                // hide dashboard when menu is open on mobile
                // to prevent scrolling bug
                <div style={styles.screenContainer}>{renderActiveScreen()}</div>
            )}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    } as CSSProperties,
    screenContainer: {
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: Colors.paper,
        ...(isMobile && { paddingTop: 20 })
    } as CSSProperties
};
