import React, { CSSProperties, useState } from 'react';
import Swal from 'sweetalert2';
import firebase from 'src/firebaseConfig';
import { CropProfilePic } from './CropProfilePic';
import { Colors } from 'src/utils/colors';
import { Styles } from 'src/utils/styles';

type Props = {
    file: any | null;
    setFile: (file: any | null) => void;
    editorRef: any;
};
const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg'];
export const ProfilePictureModal = ({ file, setFile, editorRef }: Props) => {
    const fileHandler = e => {
        let picture = e.target.files[0];
        if (picture && ALLOWED_FILE_TYPES.includes(picture?.type)) {
            setFile(picture);
        } else if (!picture) {
            Swal.fire({
                title: 'Oops',
                text: `Please choose an image`,
            });
            e.target.value = null;
        } else {
            Swal.fire({
                title: 'Oops',
                text: `Please select an image file (jpeg or png).`,
            });
            e.target.value = null;
        }
    };

    return (
        <div>
            <input
                type="file"
                onChange={fileHandler}
                style={styles.input}
                {...(!file && { value: '' })}
            />
            <CropProfilePic file={file} editorRef={editorRef} />
        </div>
    );
};

const styles = {
    input: {
        marginBottom: 15
    } as CSSProperties
};
