import React, { CSSProperties, useState } from 'react';
import { Api } from 'src/api';
import { Colors } from 'src/utils/colors';
import { getErrorMessage } from 'src/utils/helpers';
import { SegmentEvents } from 'src/utils/segmentClient';
import Swal from 'sweetalert2';

export const ReferUser = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');

    const sendReferral = async () => {
        try {
            if (!email) {
                Swal.fire({
                    title: 'Oops',
                    text: 'Please enter an email.',
                    icon: 'error'
                });
                return;
            }
            setIsLoading(true);
            await Api.users.referUser(email);
            analytics.track(SegmentEvents.ClickedReferPerson, {
                emailReferred: email
            });
            Swal.fire({
                title: 'Success',
                text: `You successfully referred ${email}. If at least 2 of your referrals sign up for an account, we'll give you premium for free.`,
                icon: 'success'
            });
            setEmail('');
        } catch (e: any) {
            Swal.fire({
                title: 'Sorry',
                text: getErrorMessage(e),
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div style={styles.outerContainer}>
            <label style={styles.description}>
                If at least 2 people who you refer sign up, we'll give you
                premium for free.
            </label>
            <div style={styles.container}>
                <input
                    style={styles.input}
                    placeholder="Friend's Email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                />
                <button
                    onClick={sendReferral}
                    style={{
                        ...styles.sendBtn,
                        ...(isLoading && { opacity: 0.4 })
                    }}
                    disabled={isLoading}
                >
                    Refer
                </button>
            </div>
        </div>
    );
};

const styles = {
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    } as CSSProperties,
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: 20,
        backgroundColor: Colors.white,
        padding: 5,
        boxShadow: '0px 5px 8px rgba(0,0,0,0.2)',
        marginBottom: 35,
        width: 325
    } as CSSProperties,
    input: {
        outline: 'none',
        border: 'none',
        fontFamily: 'Manrope',
        paddingLeft: 10,
        paddingRight: 8,
        width: '100%'
    },
    sendBtn: {
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        fontFamily: 'Manrope',
        backgroundColor: Colors.purple,
        color: Colors.white,
        height: '100%',
        fontWeight: 600,
        borderRadius: 20,
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 12,
        paddingLeft: 12
    },
    description: {
        color: Colors.white,
        marginBottom: 20,
        maxWidth: 500,
        textAlign: 'center'
    } as CSSProperties
};
