import React, { CSSProperties, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LeaderboardIcon from 'src/assets/competitionRibbonIcon.png';
import BotIcon from 'src/assets/robotIcon.png';
import StarIcon from 'src/assets/star.png';
import { setIsMobileSidebarOpen } from 'src/redux/actionIndex';
import { getIsMobileMenuOpen } from 'src/redux/dashboard/selector';
import { Colors } from 'src/utils/colors';
import { useIsWindowSkinny } from 'src/utils/windowHooks';

import { ActiveScreenEnum } from '../lib';
import { LoggedInUser } from './LoggedInUser';
import { ShowMobileMenuButton } from './MobileMenuButton';
import { SidebarRow } from './SidebarRow';

export const Sidebar = () => {
    const dispatch = useDispatch();
    const isMobileSidebarOpen = useSelector(getIsMobileMenuOpen);
    const isWindowSkinny = useIsWindowSkinny();
    const styles = responsiveStyles(isWindowSkinny);

    const isSidebarVisible = !isWindowSkinny || isMobileSidebarOpen;

    useEffect(() => {
        return () => {
            dispatch(setIsMobileSidebarOpen(false));
        };
    }, []);

    return isSidebarVisible ? (
        <div style={styles.container}>
            <div style={styles.topHalf}>
                <LoggedInUser />
                <SidebarRow
                    title="My Bots"
                    screenIdentifier={ActiveScreenEnum.MyBots}
                    icon={BotIcon}
                />
                <SidebarRow
                    title="Leaderboard"
                    screenIdentifier={ActiveScreenEnum.Leaderboard}
                    icon={LeaderboardIcon}
                />
                <SidebarRow
                    title="Premium"
                    screenIdentifier={ActiveScreenEnum.Premium}
                    icon={StarIcon}
                />
            </div>
        </div>
    ) : (
        <ShowMobileMenuButton />
    );
};

const responsiveStyles = (isWindowSkinny: boolean) => ({
    container: isWindowSkinny
        ? ({
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 100,
              overflowX: 'hidden',
              justifyContent: 'space-between',
              backgroundColor: Colors.blackSqueeze
          } as CSSProperties)
        : ({
              display: 'flex',
              flexDirection: 'column',
              width: 270,
              boxShadow: '2px 0px 10px rgba(200,200,200,0.25)',
              height: window.innerHeight,
              justifyContent: 'space-between',
              position: 'sticky',
              top: 0,
              backgroundColor: Colors.blackSqueeze
          } as CSSProperties),
    topHalf: {
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties
});
