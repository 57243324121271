import React, { CSSProperties } from 'react';
import Logo from 'src/assets/logo.png';
import { Colors } from 'src/utils/colors';

export const Branding = () => (
    <div style={styles.container}>
        <img style={styles.logo} src={Logo} alt="Logo" />
        <label style={styles.name}>Trellis</label>
    </div>
);

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 27
    } as CSSProperties,
    logo: {
        width: 40,
        height: 40
    },
    name: {
        fontSize: 28,
        marginLeft: 10,
        fontWeight: 600,
        color: Colors.purple
    }
};
