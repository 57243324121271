import React from 'react';
import { useSelector } from 'react-redux';
import { botReducer } from 'src/redux/bot/reducer';
import { getBot } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { SegmentEvents } from 'src/utils/segmentClient';

type Props = {
    isShowingTickerGraph: boolean;
    setIsShowingTickerGraph: (isShowingTickerGraph: boolean) => void;
};

export const GraphToggle = ({
    isShowingTickerGraph,
    setIsShowingTickerGraph
}: Props) => {
    const bot = useSelector(getBot);
    const handleToggle = (tempIsShowingTickerGraph: boolean) => {
        setIsShowingTickerGraph(tempIsShowingTickerGraph);
        analytics.track(
            tempIsShowingTickerGraph
                ? SegmentEvents.ClickedSeeTickerChart
                : SegmentEvents.ClickedSeeBotValueChart,
            {
                ticker: bot?.ticker,
                bot_id: bot?.id
            }
        );
    };
    return (
        <div style={styles.container}>
            <button
                style={{
                    ...styles.btn,
                    ...(!isShowingTickerGraph && styles.selectedBtn)
                }}
                onClick={() => handleToggle(false)}
            >
                Bot Value
            </button>
            <button
                style={{
                    ...styles.btn,
                    ...(isShowingTickerGraph && styles.selectedBtn)
                }}
                onClick={() => handleToggle(true)}
            >
                {bot?.ticker} Chart
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 255,
        height: 35,
        overflow: 'hidden',
        borderRadius: 14,
        backgroundColor: Colors.white,
        boxShadow: '0px 0px 10px rgba(0,0,0,0.15)',
        marginTop: 20
    },
    btn: {
        flex: 1,
        height: '100%',
        backgroundColor: Colors.transparent,
        color: Colors.tundora,
        outline: 'none',
        border: 'none',
        fontFamily: 'Manrope',
        cursor: 'pointer',
        fontWeight: 600
    },
    selectedBtn: {
        backgroundColor: Colors.purple,
        color: Colors.white,
        cursor: 'default'
    }
};
