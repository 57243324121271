import { AuthReducerType } from './auth/reducer';
import { BotReducerType } from './bot/reducer';
import { CompetitionReducerType } from './competition/reducer';
import { DashboardReducerType } from './dashboard/reducer';
import { UserReducerType } from './user/reducer';

export interface ReduxState {
    authReducer: AuthReducerType;
    userReducer: UserReducerType;
    botReducer: BotReducerType;
    dashboardReducer: DashboardReducerType;
    competitionReducer: CompetitionReducerType;
}

export const getAuthReducer = (state: ReduxState) => state.authReducer;
export const getUserReducer = (state: ReduxState) => state.userReducer;
export const getBotReducer = (state: ReduxState) => state.botReducer;
export const getDashboardReducer = (state: ReduxState) =>
    state.dashboardReducer;
export const getCompetitionReducer = (state: ReduxState) =>
    state.competitionReducer;
