import { combineReducers } from 'redux';

import { authReducer } from './auth/reducer';
import { userReducer } from './user/reducer';
import { botReducer } from './bot/reducer';
import { dashboardReducer } from './dashboard/reducer';
import { competitionReducer } from './competition/reducer';

export const ReduxReducers = combineReducers({
    authReducer,
    userReducer,
    botReducer,
    dashboardReducer,
    competitionReducer
});
