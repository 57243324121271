import React, { CSSProperties, useState } from 'react';
import PiggyBankGraphic from '../../../../assets/piggyBankMan.png';
import BulletPoint from '../../../../assets/trellisBulletPoint.png';
import { Colors } from '../../../../utils/colors';
import { useIsWindowSkinny } from '../../../../utils/windowHooks';

type QuestionAnswerType = {
    question: string;
    answer: string;
};

const NUM_FAQ_IN_PREVIEW = 3;

const FAQList: QuestionAnswerType[] = [
    {
        question: 'How can I build a good trading bot?',
        answer: "Once you sign up, you can book a free appointment with us and we'll help you translate your existing trading strategy into a trading bot."
    },
    {
        question: 'Do you support both live trading and paper trading?',
        answer: 'Yes!'
    }
];

export const FAQ = () => {
    const isWindowSkinny = useIsWindowSkinny();
    const [isShowingAllFAQ, setIsShowingAllFAQ] = useState<boolean>(false);
    const isWindowSkinnyOrShowingAllFAQs = isShowingAllFAQ || isWindowSkinny;
    const handleCollapseOrShowAllPressed = () =>
        setIsShowingAllFAQ(!isShowingAllFAQ);
    return (
        <div
            style={{
                ...styles.container,
                ...(isWindowSkinnyOrShowingAllFAQs && {
                    justifyContent: 'flex-start'
                }),
                ...(isWindowSkinny
                    ? { marginTop: 50, paddingBottom: 60, paddingTop: 20 }
                    : { overflowY: 'auto', height: '100%', flex: 1 })
            }}
        >
            <img
                src={PiggyBankGraphic}
                style={{
                    ...styles.image,
                    ...(isWindowSkinnyOrShowingAllFAQs && { marginTop: 40 })
                }}
                alt=""
            />
            <label style={styles.title}>FAQs</label>
            <div
                style={{
                    ...styles.allFAQs,
                    ...(isWindowSkinnyOrShowingAllFAQs && {
                        justifyContent: 'flex-start'
                    }),
                    ...(!isWindowSkinny && {
                        maxWidth: '75%'
                    })
                }}
            >
                {FAQList.slice(
                    0,
                    isShowingAllFAQ ? FAQList.length : NUM_FAQ_IN_PREVIEW
                ).map(qa => (
                    <QuestionAnswer qa={qa} key={qa.question} />
                ))}
                {FAQList.length > NUM_FAQ_IN_PREVIEW ? (
                    <button
                        onClick={handleCollapseOrShowAllPressed}
                        style={{
                            ...styles.collapseOrSeeAllFAQBtn,
                            ...(isShowingAllFAQ && { marginBottom: 40 })
                        }}
                    >
                        {isShowingAllFAQ
                            ? 'Minimize list'
                            : `See all (${FAQList.length})`}
                    </button>
                ) : null}
            </div>
        </div>
    );
};

const QuestionAnswer = ({ qa }: { qa: QuestionAnswerType }) => (
    <div style={styles.qaContainer}>
        <img src={BulletPoint} style={styles.bulletPointImg} alt="" />
        <div style={styles.qaRightSide}>
            <label style={styles.questionText}>{qa.question}</label>
            <label style={styles.answerText}>{qa.answer}</label>
        </div>
    </div>
);

const styles = {
    container: {
        backgroundColor: Colors.blackSqueeze,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    } as CSSProperties,
    image: {
        objectFit: 'contain',
        width: '50%',
        maxWidth: 250
    } as CSSProperties,
    title: {
        fontSize: 30,
        fontWeight: 600,
        marginTop: 50,
        marginBottom: 30
    },
    qaContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 20,
        width: '100%'
    } as CSSProperties,
    qaRightSide: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginLeft: 15
    } as CSSProperties,
    bulletPointImg: {
        width: 18,
        height: 18
    },
    questionText: {
        fontWeight: 700,
        fontSize: 20
    },
    answerText: {
        fontSize: 16,
        marginTop: 5
    },
    collapseOrSeeAllFAQBtn: {
        outline: 'none',
        border: 'none',
        color: Colors.purple,
        backgroundColor: Colors.transparent,
        fontSize: 15,
        cursor: 'pointer'
    },
    allFAQs: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 20,
        marginRight: 20
    } as CSSProperties
};
