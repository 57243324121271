import { Dispatch } from 'redux';
import { Api } from 'src/api';
import { convertBackendTriggersToFrontendTriggers } from 'src/screens/botWorkspace/screens/Triggers/lib';
import {
    BotDocument,
    DecisionEnum,
    FrontendTrigger,
    IndicatorEnum,
    IntegrationDocument,
    PerformanceInterval
} from 'trellis-types';

const SET_MY_BOTS = 'SET_MY_BOTS';
type SET_MY_BOTS = typeof SET_MY_BOTS;
const SET_BOT = 'SET_BOT';
type SET_BOT = typeof SET_BOT;
const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
type SET_INTEGRATIONS = typeof SET_INTEGRATIONS;
const SET_BUY_TRIGGERS = 'SET_BUY_TRIGGERS';
type SET_BUY_TRIGGERS = typeof SET_BUY_TRIGGERS;
const SET_SELL_TRIGGERS = 'SET_SELL_TRIGGERS';
type SET_SELL_TRIGGERS = typeof SET_SELL_TRIGGERS;
const SET_TICKER = 'SET_TICKER';
type SET_TICKER = typeof SET_TICKER;
const SET_BOT_NAME = 'SET_BOT_NAME';
type SET_BOT_NAME = typeof SET_BOT_NAME;
const SET_DECISION_TYPE = 'SET_DECISION_TYPE';
type SET_DECISION_TYPE = typeof SET_DECISION_TYPE;
const SET_CURRENT_INDICATOR = 'SET_CURRENT_INDICATOR';
type SET_CURRENT_INDICATOR = typeof SET_CURRENT_INDICATOR;
const CLEAR_CURRENT_BOT = 'CLEAR_CURRENT_BOT';
type CLEAR_CURRENT_BOT = typeof CLEAR_CURRENT_BOT;
const SET_BUY_QUANTITY = 'SET_BUY_QUANTITY';
type SET_BUY_QUANTITY = typeof SET_BUY_QUANTITY;
const SET_SELL_QUANTITY = 'SET_SELL_QUANTITY';
type SET_SELL_QUANTITY = typeof SET_SELL_QUANTITY;
const SET_BUY_QUANTITY_MAXIMIZED = 'SET_BUY_QUANTITY_MAXIMIZED';
type SET_BUY_QUANTITY_MAXIMIZED = typeof SET_BUY_QUANTITY_MAXIMIZED;
const SET_SELL_QUANTITY_MAXIMIZED = 'SET_SELL_QUANTITY_MAXIMIZED';
type SET_SELL_QUANTITY_MAXIMIZED = typeof SET_SELL_QUANTITY_MAXIMIZED;
const SET_INITIAL_BUYING_POWER = 'SET_INITIAL_BUYING_POWER';
type SET_INITIAL_BUYING_POWER = typeof SET_INITIAL_BUYING_POWER;
const SET_COOLDOWN_MINS = 'SET_COOLDOWN_MINS';
type SET_COOLDOWN_MINS = typeof SET_COOLDOWN_MINS;
const SET_REAL_MONEY_ENABLED = 'SET_REAL_MONEY_ENABLED';
type SET_REAL_MONEY_ENABLED = typeof SET_REAL_MONEY_ENABLED;
const SET_LEADERBOARD_BOTS = 'SET_LEADERBOARD_BOTS';
type SET_LEADERBOARD_BOTS = typeof SET_LEADERBOARD_BOTS;
const SET_MY_RANKED_BOTS = 'SET_MY_RANKED_BOTS';
type SET_MY_RANKED_BOTS = typeof SET_MY_RANKED_BOTS;
const SET_IS_MARKET_FORCEFULLY_CLOSED = 'SET_IS_MARKET_FORCEFULLY_CLOSED';
type SET_IS_MARKET_FORCEFULLY_CLOSED = typeof SET_IS_MARKET_FORCEFULLY_CLOSED;
const SET_PERFORMANCE_INTERVAL = 'SET_PERFORMANCE_INTERVAL';
type SET_PERFORMANCE_INTERVAL = typeof SET_PERFORMANCE_INTERVAL;
const SET_FORCE_BUY_ON_NEXT_RUN = 'SET_FORCE_BUY_ON_NEXT_RUN';
type SET_FORCE_BUY_ON_NEXT_RUN = typeof SET_FORCE_BUY_ON_NEXT_RUN;
const SET_IS_PRIVATE = 'SET_IS_PRIVATE';
type SET_IS_PRIVATE = typeof SET_IS_PRIVATE;

type ISetMyBots = {
    type: SET_MY_BOTS;
    myBots: BotDocument[];
};

export const setMyBots = (myBots: BotDocument[]): ISetMyBots => ({
    type: SET_MY_BOTS,
    myBots
});

type ISetMyRankedBots = {
    type: SET_MY_RANKED_BOTS;
    myRankedBots: BotDocument[];
};

export const setMyRankedBots = (
    myRankedBots: BotDocument[]
): ISetMyRankedBots => ({
    type: SET_MY_RANKED_BOTS,
    myRankedBots
});

type ISetBot = {
    type: SET_BOT;
    bot: BotDocument | null;
};

export const setBot = (bot: BotDocument | null): ISetBot => ({
    type: SET_BOT,
    bot
});

type ISetIntegrations = {
    type: SET_INTEGRATIONS;
    integrations: IntegrationDocument[];
};

export const setIntegrations = (
    integrations: IntegrationDocument[]
): ISetIntegrations => ({
    type: SET_INTEGRATIONS,
    integrations
});

type ISetBuyTriggers = {
    type: SET_BUY_TRIGGERS;
    buyTriggers: FrontendTrigger[];
};

export const setBuyTriggers = (
    buyTriggers: FrontendTrigger[]
): ISetBuyTriggers => ({
    type: SET_BUY_TRIGGERS,
    buyTriggers
});

type ISetSellTriggers = {
    type: SET_SELL_TRIGGERS;
    sellTriggers: FrontendTrigger[];
};

export const setSellTriggers = (
    sellTriggers: FrontendTrigger[]
): ISetSellTriggers => ({
    type: SET_SELL_TRIGGERS,
    sellTriggers
});

type ISetTicker = {
    type: SET_TICKER;
    ticker: string;
};

export const setTicker = (ticker: string): ISetTicker => ({
    type: SET_TICKER,
    ticker
});

type ISetBotName = {
    type: SET_BOT_NAME;
    name: string;
};

export const setBotName = (name: string): ISetBotName => ({
    type: SET_BOT_NAME,
    name
});

type ISetCurrentIndicator = {
    type: SET_CURRENT_INDICATOR;
    indicator: IndicatorEnum;
};

export const setCurrentIndicator = (
    indicator: IndicatorEnum
): ISetCurrentIndicator => ({
    type: SET_CURRENT_INDICATOR,
    indicator
});

type IClearCurrentBot = {
    type: CLEAR_CURRENT_BOT;
};

export const clearCurrentBot = (): IClearCurrentBot => ({
    type: CLEAR_CURRENT_BOT
});

type ISetBuyQuantity = {
    type: SET_BUY_QUANTITY;
    buyQuantity: number | '';
};

export const setBuyQuantity = (buyQuantity: number | ''): ISetBuyQuantity => ({
    type: SET_BUY_QUANTITY,
    buyQuantity
});

type ISetSellQuantity = {
    type: SET_SELL_QUANTITY;
    sellQuantity: number | '';
};

export const setSellQuantity = (
    sellQuantity: number | ''
): ISetSellQuantity => ({
    type: SET_SELL_QUANTITY,
    sellQuantity
});

type ISetBuyQuantityMaximized = {
    type: SET_BUY_QUANTITY_MAXIMIZED;
    buyQuantityMaximized: boolean;
};

export const setBuyQuantityMaximized = (
    buyQuantityMaximized: boolean
): ISetBuyQuantityMaximized => ({
    type: SET_BUY_QUANTITY_MAXIMIZED,
    buyQuantityMaximized
});

type ISetSellQuantityMaximized = {
    type: SET_SELL_QUANTITY_MAXIMIZED;
    sellQuantityMaximized: boolean;
};

export const setSellQuantityMaximized = (
    sellQuantityMaximized: boolean
): ISetSellQuantityMaximized => ({
    type: SET_SELL_QUANTITY_MAXIMIZED,
    sellQuantityMaximized
});

type ISetInitialBuyingPower = {
    type: SET_INITIAL_BUYING_POWER;
    initialBuyingPower: number;
};

export const setInitialBuyingPower = (
    initialBuyingPower: number
): ISetInitialBuyingPower => ({
    type: SET_INITIAL_BUYING_POWER,
    initialBuyingPower
});

type ISetCooldownMins = {
    type: SET_COOLDOWN_MINS;
    cooldownMins: number;
};

export const setCooldownMins = (cooldownMins: number): ISetCooldownMins => ({
    type: SET_COOLDOWN_MINS,
    cooldownMins
});

type ISetRealMoneyEnabled = {
    type: SET_REAL_MONEY_ENABLED;
    realMoneyEnabled: boolean;
};

export const setRealMoneyEnabled = (
    realMoneyEnabled: boolean
): ISetRealMoneyEnabled => ({
    type: SET_REAL_MONEY_ENABLED,
    realMoneyEnabled
});

type ISetIsPrivate = {
    type: SET_IS_PRIVATE;
    isPrivate: boolean;
};

export const setIsBotPrivate = (isPrivate: boolean): ISetIsPrivate => ({
    type: SET_IS_PRIVATE,
    isPrivate
});

type ISetLeaderboardBots = {
    type: SET_LEADERBOARD_BOTS;
    leaderboardBots: BotDocument[];
};

export const setLeaderboardBots = (
    leaderboardBots: BotDocument[]
): ISetLeaderboardBots => ({
    type: SET_LEADERBOARD_BOTS,
    leaderboardBots
});

type ISetIsMarketForcefullyClosed = {
    type: SET_IS_MARKET_FORCEFULLY_CLOSED;
    isMarketForcefullyClosed: boolean;
};

export const setIsMarketForcefullyClosed = (
    isMarketForcefullyClosed: boolean
): ISetIsMarketForcefullyClosed => ({
    type: SET_IS_MARKET_FORCEFULLY_CLOSED,
    isMarketForcefullyClosed
});

type ISetPerformanceInterval = {
    type: SET_PERFORMANCE_INTERVAL;
    performanceInterval: PerformanceInterval;
};

export const setPerformanceInterval = (
    performanceInterval: PerformanceInterval
): ISetPerformanceInterval => ({
    type: SET_PERFORMANCE_INTERVAL,
    performanceInterval
});

type ISetForceBuyOnNextRun = {
    type: SET_FORCE_BUY_ON_NEXT_RUN;
    forceBuyOnNextRun: boolean;
};

export const setShouldForceBuyOnNextRun = (
    forceBuyOnNextRun: boolean
): ISetForceBuyOnNextRun => ({
    type: SET_FORCE_BUY_ON_NEXT_RUN,
    forceBuyOnNextRun
});

// actions identifiable by the reducer
export type BotActionsIndex =
    | ISetMyBots
    | ISetMyRankedBots
    | ISetBot
    | ISetIntegrations
    | ISetBuyTriggers
    | ISetTicker
    | ISetBotName
    | ISetSellTriggers
    | ISetCurrentIndicator
    | IClearCurrentBot
    | ISetBuyQuantity
    | ISetSellQuantity
    | ISetBuyQuantityMaximized
    | ISetSellQuantityMaximized
    | ISetInitialBuyingPower
    | ISetRealMoneyEnabled
    | ISetLeaderboardBots
    | ISetIsMarketForcefullyClosed
    | ISetPerformanceInterval
    | ISetCooldownMins
    | ISetForceBuyOnNextRun
    | ISetIsPrivate;

// dispatchable, api-related actions
export const fetchLeaderboardBots = async (dispatch: Dispatch) => {
    const leaderboardBotsRes = await Api.bots.getLeaderboardBots();
    const leaderboardBots = leaderboardBotsRes.data.bots;
    dispatch(setLeaderboardBots(leaderboardBots));
};

export const fetchMyBots = async (dispatch: Dispatch) => {
    const myBotsRes = await Api.bots.getMyBots(true);
    const myBots = myBotsRes.data.bots;
    dispatch(setMyBots(myBots));
};

export const fetchIsMarketForcefullyClosed = async (dispatch: Dispatch) => {
    const marketClosedRes = await Api.stocks.getIsMarketForcefullyClosed();
    const { isForcedClosed } = marketClosedRes.data;
    dispatch(setIsMarketForcefullyClosed(isForcedClosed));
};

export const fetchMyRankedBots = async (dispatch: Dispatch, userId: string) => {
    const myRankedBotsRes = await Api.bots.getMyRankedBots(userId);
    const myRankedBots = myRankedBotsRes.data.bots;
    dispatch(setMyRankedBots(myRankedBots));
};

type FetchCurrentBotInput = {
    dispatch: Dispatch;
    botId: string;
    performanceInterval: PerformanceInterval;
    accessCode?: string;
};

export const fetchCurrentBot = async ({
    dispatch,
    botId,
    accessCode,
    performanceInterval
}: FetchCurrentBotInput) => {
    const fetchedBotRes = await Api.bots.getBotById(
        botId,
        accessCode,
        performanceInterval
    );
    const { bot: fetchedBot } = fetchedBotRes.data;
    const {
        frontendBuyTriggers: buyTriggers,
        frontendSellTriggers: sellTriggers
    } = convertBackendTriggersToFrontendTriggers(
        fetchedBot?.triggers || [],
        fetchedBot?.triggerGroups || []
    );
    dispatch(setBuyTriggers(buyTriggers));
    dispatch(setSellTriggers(sellTriggers));
    dispatch(setBot(fetchedBot));
    dispatch(setTicker(fetchedBot.ticker));
    dispatch(setBotName(fetchedBot.name));
    dispatch(setCooldownMins(fetchedBot.cooldownMins));
    dispatch(setBuyQuantity(fetchedBot.buyQuantity));
    dispatch(setSellQuantity(fetchedBot.sellQuantity));
    dispatch(setBuyQuantityMaximized(fetchedBot.buyQuantityMaximized));
    dispatch(setSellQuantityMaximized(fetchedBot.sellQuantityMaximized));
    dispatch(setInitialBuyingPower(fetchedBot.initialBuyingPower));
    dispatch(setRealMoneyEnabled(fetchedBot.realMoneyEnabled));
    dispatch(setShouldForceBuyOnNextRun(fetchedBot.forceBuyOnNextRun));
    dispatch(setIsBotPrivate(fetchedBot.isPrivate));
};

export const fetchIntegrations = async (dispatch: Dispatch, botId: string) => {
    if (!botId) return;
    const integrationsRes = await Api.bots.getIntegrations(botId);
    const { integrations } = integrationsRes.data;
    dispatch(setIntegrations(integrations || []));
};
