import { CompetitionDocument } from 'trellis-types';
import { CompetitionActionsIndex } from './action';

export type CompetitionReducerType = {
    isGlobalCompetitionActive: boolean;
    currentCompetition: CompetitionDocument | null;
};

const initialState: CompetitionReducerType = {
    isGlobalCompetitionActive: false,
    currentCompetition: null
};

export const competitionReducer = (
    state: CompetitionReducerType = initialState,
    action: CompetitionActionsIndex
): CompetitionReducerType => {
    switch (action.type) {
        case 'SET_CURRENT_COMPETITION':
            return {
                ...state,
                currentCompetition: action.currentCompetition
            };
        case 'SET_IS_GLOBAL_COMPETITION_ACTIVE':
            return {
                ...state,
                isGlobalCompetitionActive: action.isGlobalCompetitionActive
            };
        default:
            return state;
    }
};
