import * as Sentry from '@sentry/react';
import _ from 'lodash';
import React, { CSSProperties, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Api } from 'src/api';
import TrashIcon from 'src/assets/trash.png';
import { fetchMyBots } from 'src/redux/bot/action';
import { Colors } from 'src/utils/colors';
import {
    getBotPerformanceTextColor,
    getErrorMessage,
    shortenString
} from 'src/utils/helpers';
import { analytics, SegmentEvents } from 'src/utils/segmentClient';
import { Styles } from 'src/utils/styles';
import Swal from 'sweetalert2';
import { BotDocument } from 'trellis-types';
import { RealMoneyToolTip } from './RealMoneyToolTip';

import { ToggleBotIsActive } from './ToggleBotIsActive';
import { WarningToolTip } from './WarningToolTip';
import { TimeToolTip } from 'src/screens/myBots/components/Time';

type Props = {
    bot: BotDocument;
    isLastInList?: boolean;
};

export const BotRow = ({ bot, isLastInList }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isHoveringCell, setIsHoveringCell] = useState<boolean>(false);

    const handlePressedCell = () => {
        analytics.track(SegmentEvents.ClickedBotRow, {
            ticker: bot.ticker,
            name: bot.name,
            initial_buying_power: bot.initialBuyingPower,
            buy_quantity: bot.buyQuantity,
            sell_quantity: bot.sellQuantity,
            performance: bot.performance,
            is_ranked_cell: false
        });

        isMobile
            ? Swal.fire({
                  title: 'Sorry',
                  text: 'You can only edit a bot on a computer or tablet (for now).'
              })
            : history.push(`/bot-workspace/${bot._id}/metrics`);
    };

    const handleConfirmDeleteBot = () =>
        Swal.fire({
            title: 'Please Confirm',
            text: `Are you sure you want to delete ${bot.name}? This cannot be undone.`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: 'warning'
        }).then(async res => {
            if (res.isConfirmed) {
                try {
                    setIsLoading(true);
                    await Api.bots.deleteBot(bot._id);
                    analytics.track(SegmentEvents.DeletedBot, {
                        ticker: bot.ticker,
                        name: bot.name,
                        initial_buying_power: bot.initialBuyingPower,
                        buy_quantity: bot.buyQuantity,
                        sell_quantity: bot.sellQuantity
                    });
                    await fetchMyBots(dispatch);
                } catch (e) {
                    Sentry.captureException(e);
                    Swal.fire({
                        title: 'Oops',
                        text: getErrorMessage(e),
                        icon: 'error'
                    });
                } finally {
                    setIsLoading(false);
                }
            }
        });

    const BotPerformance = () => {
        const performance = bot.performance || '';
        const todayPerformance = bot.oneDayPerformance || '';
        const textColor = getBotPerformanceTextColor(bot.performance);
        const todayTextColor = getBotPerformanceTextColor(
            bot.oneDayPerformance
        );

        return (
            <div style={styles.performanceContainer}>
                <label
                    style={{ color: textColor, fontSize: 22, fontWeight: 700 }}
                >
                    {performance}
                </label>
                <label
                    style={{
                        ...styles.outerOneDayPerformanceLbl,
                        backgroundColor: todayTextColor
                    }}
                >
                    {todayPerformance} today
                </label>
            </div>
        );
    };

    return (
        <div
            style={{
                ...styles.container,
                borderBottomWidth: isLastInList ? 0 : 1,
                opacity: isLoading ? 0.5 : 1
            }}
        >
            <button
                style={styles.leftSide}
                disabled={isLoading}
                onClick={handlePressedCell}
                onMouseEnter={() => setIsHoveringCell(true)}
                onMouseLeave={() => setIsHoveringCell(false)}
            >
                <label
                    style={{
                        ...styles.botName,
                        ...(isHoveringCell && { color: Colors.purple })
                    }}
                >
                    {isMobile ? shortenString(bot.name, 10) : bot.name}
                </label>
                <label style={styles.ticker}>{bot.ticker}</label>
            </button>
            <div style={styles.rightSide}>
                <BotPerformance />
                {!isMobile && (
                    <>
                        <div style={styles.verticalDivider} />
                        <WarningToolTip bot={bot} />
                        <RealMoneyToolTip bot={bot} />
                        <TimeToolTip bot={bot} />
                        <ToggleBotIsActive
                            bot={bot}
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                        />
                        <button
                            style={styles.deleteBtn}
                            onClick={handleConfirmDeleteBot}
                        >
                            <img
                                src={TrashIcon}
                                style={styles.trashIcon}
                                alt=""
                            />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        outline: 'none',
        border: 'none',
        borderColor: Colors.lightGray4,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderStyle: 'solid',
        width: '95%',
        height: 90,
        alignItems: 'center'
    } as CSSProperties,
    botName: {
        fontSize: 18,
        fontWeight: 600,
        color: Colors.tundora,
        cursor: 'pointer',
        ...Styles.textOverflowEllipsis
    } as CSSProperties,
    ticker: {
        fontSize: 12,
        fontWeight: 600,
        color: Colors.lightGray5,
        cursor: 'pointer'
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        cursor: 'pointer',
        flex: 4
    } as CSSProperties,
    rightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 1
    } as CSSProperties,
    deleteBtn: {
        borderRadius: 10,
        height: 30,
        outline: 'none',
        border: 'none',
        width: 30,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.transparent
    } as CSSProperties,
    botNotRunningText: {
        marginRight: 23,
        color: Colors.red,
        fontFamily: 'Manrope',
        fontWeight: 600
    },
    trashIcon: {
        width: 22,
        height: 22,
        filter: 'invert(30%)'
    },
    performanceContainer: {
        marginRight: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end'
    } as CSSProperties,
    outerOneDayPerformanceLbl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        color: Colors.white,
        fontSize: 12,
        fontWeight: 500,
        borderRadius: 8,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 7,
        paddingRight: 7,
        minWidth: 100
    } as CSSProperties,
    verticalDivider: {
        width: 1,
        height: 60,
        backgroundColor: Colors.lightGray4,
        marginRight: 20
    }
};
