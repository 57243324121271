import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { getBot } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { interpretDecisionStatus, isUsingRealMoney } from 'src/utils/helpers';
import { DecisionDocument, DecisionEnum } from 'trellis-types';

type Props = {
    decision: DecisionDocument;
};

export const DecisionRow = ({ decision }: Props) => {
    const bot = useSelector(getBot);
    const decisionQuantity = isUsingRealMoney(bot!)
        ? decision.realMoneyQuantity
        : decision.quantity;
    return bot ? (
        <div
            style={{
                ...styles.container,
                ...(decision.type === DecisionEnum.Buy
                    ? {
                          backgroundColor: Colors.superLightGreen,
                          borderColor: Colors.lightGreen
                      }
                    : {
                          backgroundColor: Colors.superLightRed,
                          borderColor: Colors.lightRed
                      })
            }}
        >
            <label style={styles.decisionText}>
                <label style={styles.decisionTypeText}>
                    {decision.type}
                    {isUsingRealMoney(bot)
                        ? ` (${interpretDecisionStatus(bot, decision)})`
                        : ''}
                </label>
                {decisionQuantity} {decisionQuantity === 1 ? 'share' : 'shares'}{' '}
                of {decision.ticker} at around ${decision.price.toFixed(2)}
            </label>
            <label style={styles.date}>
                {new Date(_.get(decision, 'createdAt')).toLocaleString()}
            </label>
        </div>
    ) : null;
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 60,
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
        borderWidth: 2,
        borderRadius: 10,
        borderStyle: 'solid'
    } as CSSProperties,
    decisionText: {
        fontSize: 16,
        fontWeight: 500
    },
    date: {
        color: Colors.darkGray1
    },
    decisionTypeText: {
        color: Colors.darkGray1,
        fontWeight: 700,
        marginRight: 10
    }
};
