import React, { CSSProperties } from 'react';
import { isMobile } from 'react-device-detect';
import { Colors } from 'src/utils/colors';
import { getBotPerformanceTextColor, shortenString } from 'src/utils/helpers';
import { Styles } from 'src/utils/styles';
import { BotDocument } from 'trellis-types';
import { SeeStrategy } from './SeeStrategy';
import { ViewStrategy } from './ViewStrategy';

type Props = {
    bot: BotDocument;
    isLastInList?: boolean;
    rank: number;
};

export const LeaderboardRow = ({ bot, isLastInList, rank }: Props) => {
    const performance = bot.performance || '';
    const textColor = getBotPerformanceTextColor(bot.performance);

    const BotPerformance = () => {
        return (
            <div style={{ marginRight: 15 }}>
                <label style={{ color: textColor }}>{performance}</label>
            </div>
        );
    };

    return (
        <div
            style={{
                ...styles.container,
                borderBottomWidth: isLastInList ? 0 : 1
            }}
        >
            <div style={styles.rankContainer}>
                <label style={styles.rank}>{rank}</label>
            </div>
            <div style={styles.leftSide}>
                <label style={styles.botName}>
                    {shortenString(bot.name, isMobile ? 14 : 30)}
                </label>
                <label style={styles.ticker}>{bot.ticker}</label>
            </div>
            <div style={styles.rightSide}>
                <ViewStrategy bot={bot}/>
                <BotPerformance />
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        outline: 'none',
        border: 'none',
        borderColor: Colors.lightGray4,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderStyle: 'solid',
        width: '95%',
        height: 68,
        alignItems: 'center'
    } as CSSProperties,
    botName: {
        fontSize: 16,
        fontWeight: 600,
        color: Colors.tundora,
        ...Styles.textOverflowEllipsis
    } as CSSProperties,
    rank: {
        fontSize: 16,
        fontWeight: 800,
        color: Colors.lightPurple,
        ...Styles.textOverflowEllipsis
    },
    rankContainer: {
        width: 30,
        textAlign: 'right',
        marginRight: 20
    } as CSSProperties,
    ticker: {
        fontSize: 12,
        fontWeight: 600,
        color: Colors.lightGray5
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        flex: 4
    } as CSSProperties,
    rightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 1
    } as CSSProperties
};
