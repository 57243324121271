import { Button, Dropdown, Menu, Modal } from 'antd';
import _ from 'lodash';
import React, { CSSProperties, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'src/api';
import ChevronDown from 'src/assets/chevronDown.png';
import DefaultProfilePicture from 'src/assets/defaultProfilePic.jpg';
import firebase from 'src/firebaseConfig';
import { fetchUser } from 'src/redux/actionIndex';
import { getUser } from 'src/redux/user/selector';
import { Colors } from 'src/utils/colors';
import { getErrorMessage } from 'src/utils/helpers';
import { SegmentEvents } from 'src/utils/segmentClient';
import { Styles } from 'src/utils/styles';
import Swal from 'sweetalert2';
import { PlanEnum } from 'trellis-types';

import { ProfilePictureModal } from './ProfilePictureModal';

export const LoggedInUser = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const lastNameInitial = user?.lastName ? user?.lastName.charAt(0) : '';
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [file, setFile] = useState<any | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const editorRef = useRef(null);

    const handleLogout = () =>
        Swal.fire({
            title: 'Please Confirm',
            text: `Are you sure you want to logout?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Logout',
            cancelButtonText: 'Nevermind'
        }).then(async res => {
            if (res.isConfirmed) {
                firebase.auth().signOut();
            }
        });

    const showEditPicture = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);

    const uploadFile = async () => {
        if (editorRef.current) {
            try {
                setIsSaving(true);
                const newImgCanvas: HTMLCanvasElement = (
                    editorRef as any
                ).current
                    .getImage()
                    .toDataURL();
                const fetchedCanvas = await fetch(newImgCanvas as any);
                const blob = await fetchedCanvas.blob();
                const imageURL = window.URL.createObjectURL(blob);
                const storageRef = firebase.storage().ref(imageURL);
                const data = await storageRef.put(blob);
                const url = await data.ref.getDownloadURL();
                await Api.users.updateUser({ profilePictureUrl: url });
                fetchUser(dispatch);
                handleCancel();
                setIsSaving(false);
                setFile(null);
                analytics.track(SegmentEvents.ChangedProfilePicture);
            } catch (e) {
                Swal.fire({
                    title: 'Oops',
                    text: getErrorMessage(e),
                    icon: 'error'
                });
                setIsSaving(false);
            }
        }
    };

    const DropDownMenu = (
        <Menu style={isMobile ? {} : styles.desktopMenu}>
            <label style={styles.email}>{_.get(user, 'email', '')}</label>
            <Menu.Item onClick={showEditPicture}>
                {user?.profilePictureUrl
                    ? 'Change Profile Picture'
                    : 'Add Profile Picture'}
            </Menu.Item>
            <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
        </Menu>
    );

    return user ? (
        <>
            <Modal
                title="Set Profile Picture"
                visible={isModalVisible}
                onOk={uploadFile}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        type="primary"
                        onClick={uploadFile}
                        loading={isSaving}
                        disabled={!file}
                    >
                        {isSaving ? 'Saving' : 'Save'}
                    </Button>
                ]}
            >
                <div>
                    <ProfilePictureModal
                        file={file}
                        setFile={setFile}
                        editorRef={editorRef}
                    />
                </div>
            </Modal>
            <Dropdown overlay={DropDownMenu} trigger={['click']}>
                <button style={styles.container} className="logged-in-user">
                    <img
                        src={ChevronDown}
                        alt=""
                        style={styles.chevronDownIcon}
                    />
                    <div style={styles.profilePicContainer}>
                        <img
                            src={
                                user.profilePictureUrl || DefaultProfilePicture
                            }
                            style={styles.profilePicIcon}
                            alt=""
                        />
                    </div>
                    <div style={styles.rightSideSmaller}>
                        <label style={styles.name}>
                            {_.get(user, 'firstName', '')} {lastNameInitial}
                        </label>
                        {user?.currentPlan !== PlanEnum.Free && (
                            <label style={styles.premiumTag}>Premium</label>
                        )}
                    </div>
                </button>
            </Dropdown>
        </>
    ) : null;
};

const styles = {
    container: {
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        display: 'flex',
        textAlign: 'left',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 15,
        width: '100%',
        overflow: 'hidden',
        fontFamily: 'Manrope',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: Colors.lightGray3,
        borderStyle: 'solid',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        position: 'relative'
    } as CSSProperties,
    email: {
        color: Colors.tundora,
        fontSize: 14,
        fontWeight: 700,
        width: '100%',
        ...Styles.textOverflowEllipsis,
        paddingLeft: 10,
        paddingTop: 7,
        paddingBottom: 5,
        marginBottom: 5,
        backgroundColor: Colors.white,
        boxShadow: '0px 1px 2px rgba(50,50,50,0.14)',
        height: 35,
        cursor: 'default'
    } as CSSProperties,
    name: {
        color: Colors.tundora,
        fontSize: 20,
        fontWeight: 800,
        width: '83%',
        cursor: 'pointer',
        ...Styles.textOverflowEllipsis
    } as CSSProperties,
    chevronDownIcon: {
        width: 14,
        height: 14,
        position: 'absolute',
        top: 13,
        right: 13
    } as CSSProperties,
    desktopMenu: {
        position: 'relative'
    } as CSSProperties,
    profilePicIcon: {
        width: 45,
        height: 45,
        borderRadius: 100
    } as CSSProperties,
    profilePicContainer: {
        borderRadius: 100,
        borderWidth: 2,
        boxShadow: '0px 0px 5px rgba(170,170,170)',
        borderStyle: 'solid',
        borderColor: Colors.white,
        marginRight: 10
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 2
    } as CSSProperties,
    rightSide: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    } as CSSProperties,
    rightSideSmaller: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
    } as CSSProperties,
    premiumTag: {
        fontSize: 13,
        color: Colors.darkPurple,
        fontWeight: 600,
        marginTop: -4,
        cursor: 'pointer'
    }
};
