import { Constants } from 'src/utils/constants';
import {
    BotDocument,
    FrontendTrigger,
    IndicatorEnum,
    IntegrationDocument,
    PerformanceInterval
} from 'trellis-types';

import { BotActionsIndex } from './action';

export type BotReducerType = {
    myBots: BotDocument[];
    myRankedBots: BotDocument[];
    bot: BotDocument | null;
    integrations: IntegrationDocument[];
    buyTriggers: FrontendTrigger[];
    sellTriggers: FrontendTrigger[];
    name: string;
    ticker: string;
    indicator: IndicatorEnum;
    buyQuantity: number | '';
    sellQuantity: number | '';
    buyQuantityMaximized: boolean;
    sellQuantityMaximized: boolean;
    initialBuyingPower: number;
    cooldownMins: number;
    realMoneyEnabled: boolean;
    leaderboardBots: BotDocument[];
    isMarketForcefullyClosed: boolean;
    performanceInterval: PerformanceInterval;
    forceBuyOnNextRun: boolean;
    isPrivate: boolean;
};

const initialState: BotReducerType = {
    myBots: [],
    myRankedBots: [],
    bot: null,
    integrations: [],
    buyTriggers: [],
    sellTriggers: [],
    name: '',
    ticker: '',
    indicator: Constants.defaultIndicatorKey,
    buyQuantity: '',
    sellQuantity: '',
    buyQuantityMaximized: true,
    sellQuantityMaximized: true,
    initialBuyingPower: 0,
    cooldownMins: 0,
    realMoneyEnabled: false,
    leaderboardBots: [],
    isMarketForcefullyClosed: false,
    performanceInterval: PerformanceInterval._1Day,
    forceBuyOnNextRun: false,
    isPrivate: false
};

export const botReducer = (
    state: BotReducerType = initialState,
    action: BotActionsIndex
): BotReducerType => {
    switch (action.type) {
        case 'SET_MY_BOTS':
            return { ...state, myBots: action.myBots };
        case 'SET_MY_RANKED_BOTS':
            return { ...state, myRankedBots: action.myRankedBots };
        case 'SET_BOT':
            return { ...state, bot: action.bot };
        case 'SET_INTEGRATIONS':
            return { ...state, integrations: action.integrations };
        case 'SET_BUY_TRIGGERS':
            return { ...state, buyTriggers: action.buyTriggers };
        case 'SET_SELL_TRIGGERS':
            return { ...state, sellTriggers: action.sellTriggers };
        case 'SET_TICKER':
            return { ...state, ticker: action.ticker };
        case 'SET_BOT_NAME':
            return { ...state, name: action.name };
        case 'SET_CURRENT_INDICATOR':
            return { ...state, indicator: action.indicator };
        case 'SET_BUY_QUANTITY':
            return { ...state, buyQuantity: action.buyQuantity };
        case 'SET_SELL_QUANTITY':
            return { ...state, sellQuantity: action.sellQuantity };
        case 'SET_BUY_QUANTITY_MAXIMIZED':
            return {
                ...state,
                buyQuantityMaximized: action.buyQuantityMaximized
            };
        case 'SET_SELL_QUANTITY_MAXIMIZED':
            return {
                ...state,
                sellQuantityMaximized: action.sellQuantityMaximized
            };
        case 'SET_INITIAL_BUYING_POWER':
            return {
                ...state,
                initialBuyingPower: action.initialBuyingPower
            };
        case 'SET_COOLDOWN_MINS':
            return {
                ...state,
                cooldownMins: action.cooldownMins
            };
        case 'SET_REAL_MONEY_ENABLED':
            return {
                ...state,
                realMoneyEnabled: action.realMoneyEnabled
            };
        case 'SET_LEADERBOARD_BOTS':
            return {
                ...state,
                leaderboardBots: action.leaderboardBots
            };
        case 'SET_IS_MARKET_FORCEFULLY_CLOSED':
            return {
                ...state,
                isMarketForcefullyClosed: action.isMarketForcefullyClosed
            };
        case 'SET_PERFORMANCE_INTERVAL':
            return {
                ...state,
                performanceInterval: action.performanceInterval
            };
        case 'SET_FORCE_BUY_ON_NEXT_RUN':
            return {
                ...state,
                forceBuyOnNextRun: action.forceBuyOnNextRun
            };
        case 'SET_IS_PRIVATE':
            return {
                ...state,
                isPrivate: action.isPrivate
            };
        case 'CLEAR_CURRENT_BOT':
            return {
                ...initialState,
                myBots: state.myBots,
                myRankedBots: state.myRankedBots,
                leaderboardBots: state.leaderboardBots,
                isMarketForcefullyClosed: state.isMarketForcefullyClosed
            };
        default:
            return state;
    }
};
