import React, { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { setIsMobileSidebarOpen } from 'src/redux/actionIndex';
import { Colors } from 'src/utils/colors';
import MenuIcon from 'src/assets/menuIcon.png';

export const ShowMobileMenuButton = () => {
    const dispatch = useDispatch();
    const handleOpenMobileMenuPressed = () =>
        dispatch(setIsMobileSidebarOpen(true));
    return (
        <button style={styles.button} onClick={handleOpenMobileMenuPressed}>
            <img src={MenuIcon} style={styles.menuIcon} alt="MenuIcon" />
        </button>
    );
};

const styles = {
    button: {
        fontSize: 15,
        color: Colors.tundora,
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        cursor: 'pointer',
        fontWeight: 800,
        position: 'absolute',
        top: 15,
        left: 5
    } as CSSProperties,
    menuIcon: {
        height: 33,
        width: 33,
        tintColor: Colors.white,
        filter: 'invert(30%)'
    }
};
