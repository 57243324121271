import React, { CSSProperties, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'src/api';
import { fetchCurrentBot } from 'src/redux/actionIndex';
import { getBot, getPerformanceInterval } from 'src/redux/bot/selector';
import { BotValueChart } from 'src/screens/botWorkspace/screens/Metrics/components/BotValueChart';
import { GraphToggle } from 'src/screens/botWorkspace/screens/Metrics/components/GraphToggle';
import { Colors } from 'src/utils/colors';
import {
    getBotPerformanceTextColor,
    getErrorMessage,
    isUsingRealMoney,
    numberToNumberWithCommas,
    useScreenReloadSegment
} from 'src/utils/helpers';
import { SegmentEvents } from 'src/utils/segmentClient';
import Swal from 'sweetalert2';
import { useIsVisitorUsingAccessCode } from '../../lib';
import { RefreshBtn } from 'src/sharedComponents/RefreshBtn';
import { PastDecisions } from './components/PastDecisions';
import StockTickerChart from './components/StockTickerChart';

export const MetricsScreen = () => {
    useScreenReloadSegment(SegmentEvents.ViewedMetrics);
    const [isShowingTickerGraph, setIsShowingTickerGraph] =
        useState<boolean>(false);
    const bot = useSelector(getBot);
    const performanceInterval = useSelector(getPerformanceInterval);
    const dispatch = useDispatch();
    const currentlyOwnedSharesNum = bot
        ? isUsingRealMoney(bot)
            ? bot.realMoneyCurrentQuantityOwned
            : bot.currentQuantityOwned
        : 0;
    const buyingPower = numberToNumberWithCommas(bot?.currentBotValue || 0);
    const { isVisitorUsingAccessCode } = useIsVisitorUsingAccessCode();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleFetchBot = async () => {
        try {
            setIsLoading(true);
            await fetchCurrentBot({
                dispatch,
                botId: bot?._id,
                performanceInterval
            });
        } catch (e) {
            Swal.fire({
                title: 'Oops',
                text: getErrorMessage(e),
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSellAllAlert = () =>
        Swal.fire({
            title: 'Please Confirm',
            text: `Are you sure you want to sell all ${currentlyOwnedSharesNum} shares that you own?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: `Sell All ${currentlyOwnedSharesNum} Shares`,
            cancelButtonText: 'Nevermind'
        })
            .then(async res => {
                if (res.isConfirmed) {
                    await Api.bots.sellAllShares(bot?._id);
                    Swal.fire({
                        title: 'Success',
                        text: `We submitted an order to sell all ${currentlyOwnedSharesNum} of your shares.`,
                        icon: 'success'
                    });
                    await fetchCurrentBot({
                        dispatch,
                        botId: bot?._id,
                        performanceInterval
                    });
                }
            })
            .catch(e =>
                Swal.fire({
                    title: 'Sorry',
                    text: getErrorMessage(e),
                    icon: 'error'
                })
            );

    return (
        <div style={styles.container}>
            {bot?.currentBotValue ? (
                <div style={styles.topInfoContainer}>
                    <div style={styles.performanceContainer}>
                        <label style={styles.buyingPowerLbl}>
                            ${buyingPower}
                        </label>
                        <RefreshBtn
                            btnStyle={styles.refreshBtn}
                            onPress={handleFetchBot}
                            isLoading={isLoading}
                        />
                    </div>
                    <label
                        style={{
                            color: getBotPerformanceTextColor(
                                bot.graphPerformance
                            )
                        }}
                    >
                        {bot.graphPerformance}
                    </label>
                    <div style={styles.currentlyHoldingContainer}>
                        <label style={styles.currentlyHoldingLbl}>
                            Currently holding {currentlyOwnedSharesNum}{' '}
                            {currentlyOwnedSharesNum === 1 ? 'share' : 'shares'}
                        </label>
                        {currentlyOwnedSharesNum &&
                        !isVisitorUsingAccessCode ? (
                            <button
                                style={styles.sellAllBtn}
                                onClick={handleSellAllAlert}
                            >
                                Sell All
                            </button>
                        ) : null}
                    </div>
                    <GraphToggle
                        isShowingTickerGraph={isShowingTickerGraph}
                        setIsShowingTickerGraph={setIsShowingTickerGraph}
                    />
                </div>
            ) : null}
            {isShowingTickerGraph ? (
                <div style={styles.tradingViewGraphContainer}>
                    <StockTickerChart />
                </div>
            ) : (
                <BotValueChart />
            )}
            <PastDecisions />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 60,
        width: '100%'
    } as CSSProperties,
    title: {
        fontSize: 26,
        fontWeight: 600,
        marginBottom: 15
    },
    performanceText: {
        fontSize: 18
    },
    additionalDetailsText: {
        color: Colors.darkGray1,
        fontSize: 15,
        marginTop: 10
    },
    viewFormulaBtn: {
        outline: 'none',
        border: 'none',
        color: Colors.purple,
        cursor: 'pointer',
        backgroundColor: Colors.transparent
    },
    buyingPowerLbl: {
        fontSize: 28,
        fontWeight: 700
    },
    currentlyHoldingLbl: {
        fontSize: 13,
        color: Colors.purple
    },
    currentlyHoldingContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 4
    } as CSSProperties,
    sellAllBtn: {
        fontSize: 13,
        color: Colors.white,
        fontWeight: 600,
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: Colors.purple,
        borderRadius: 10,
        marginLeft: 7,
        paddingLeft: 10,
        paddingRight: 10
    },
    refreshBtn: {
        marginLeft: 14,
        position: 'relative',
        top: 2
    },
    tradingViewGraphContainer: {
        marginTop: 30,
        height: 400,
        width: '100%'
    },
    topInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    } as CSSProperties,
    performanceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    } as CSSProperties
};
