import './index.css';

import * as Sentry from '@sentry/react';
import React, { CSSProperties, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import MoneyBubbles from 'src/assets/moneyBubbles.png';
import { getAuthStatus } from 'src/redux/auth/selector';
import {
    fetchIsMarketForcefullyClosed,
    fetchMyBots
} from 'src/redux/bot/action';
import { getMyBots } from 'src/redux/bot/selector';
import { getUser } from 'src/redux/user/selector';
import { RefreshBtn } from 'src/sharedComponents/RefreshBtn';
import { Colors } from 'src/utils/colors';
import { useScreenReloadSegment } from 'src/utils/helpers';
import { SegmentEvents } from 'src/utils/segmentClient';
import { BotDocument } from 'trellis-types';

import { BotRow } from './components/BotRow';
import { CreateBotButton } from './components/CreateBotButton';
import { LiveTradingAccount } from './components/LiveTradingAccount';
import Swal from 'sweetalert2';
import { Api } from 'src/api';

export const MyBots = () => {
    useScreenReloadSegment(SegmentEvents.ViewedMyBots);
    const dispatch = useDispatch();
    const myBots = useSelector(getMyBots);
    const authStatus = useSelector(getAuthStatus);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const styles = responsiveStyles(window.innerHeight);

    useEffect(() => {
        if (authStatus === 'SIGNED_IN') {
            handleFetchMyBots();
        }
    }, [authStatus]);

    const handleFetchMyBots = async () => {
        try {
            setIsLoading(true);
            await fetchIsMarketForcefullyClosed(dispatch);
            await fetchMyBots(dispatch);
        } catch (e) {
            Sentry.captureException(e);
        } finally {
            setIsLoading(false);
        }
    };

    const MyBotsTable = () =>
        myBots.length ? (
            <div
                style={{
                    ...styles.botsTableContainer,
                    ...(!myBots.length && { minHeight: 100 })
                }}
            >
                <div style={styles.botTableInnerContainer}>
                    {myBots.map((bot: BotDocument, i) => (
                        <BotRow
                            bot={bot}
                            isLastInList={i === myBots.length - 1}
                            key={bot._id}
                        />
                    ))}
                </div>
            </div>
        ) : (
            <BookAppointment />
        );

    const BookAppointment = () => (
        <a
            style={styles.bookAppointmentContainer}
            href="https://calendly.com/trytrellis/trelliscall"
            target="_blank"
            rel="noopener noreferrer"
            className="book-appt-text"
        >
            <img src={MoneyBubbles} alt="" style={styles.moneyBubblesIcon} />
            <label style={styles.bookAppointmentText}>
                Click here to schedule a call with us and we'll help you
                translate your existing strategy into a Trellis bot.
            </label>
        </a>
    );

    return (
        <div style={styles.container}>
            <div style={styles.innerContainer}>
                <LiveTradingAccount />
                {/* My Bots */}
                <div style={styles.sectionContainer}>
                    <div style={styles.headerContainer}>
                        <div style={styles.leftSideHeader}>
                            {!isLoading || myBots.length ? (
                                <label style={styles.title}>
                                    {myBots.length
                                        ? 'My Bots'
                                        : 'You have 0 trading bots.'}
                                </label>
                            ) : null}
                            {myBots.length && !isMobile ? (
                                <RefreshBtn
                                    btnStyle={styles.refreshBtn}
                                    onPress={handleFetchMyBots}
                                    isLoading={isLoading}
                                />
                            ) : null}
                        </div>
                        <CreateBotButton disabled={isLoading} />
                    </div>
                    {isLoading && !myBots.length ? (
                        <label style={styles.loading}>Loading...</label>
                    ) : (
                        <MyBotsTable />
                    )}
                </div>
            </div>
        </div>
    );
};

const responsiveStyles = (_windowHeight: number) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowX: 'hidden',
        flex: 1,
        paddingBottom: 140,
        backgroundSize: '100% 100%',
        minHeight: window.innerHeight
    } as CSSProperties,
    innerContainer: {
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    botsTableContainer: {
        marginTop: 20,
        boxShadow: '0px 0px 25px rgba(0,0,0,0.1)',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.white
    },
    sectionContainer: {
        ...(isMobile
            ? {
                  paddingLeft: 40,
                  paddingRight: 40
              }
            : {
                  paddingLeft: 80,
                  paddingRight: 80
              })
    },
    indentedHeader: {
        ...(isMobile
            ? {
                  paddingLeft: 40,
                  paddingRight: 40
              }
            : {
                  paddingLeft: 80,
                  paddingRight: 80
              }),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    } as CSSProperties,
    title: {
        fontSize: 32,
        fontWeight: 800,
        fontFamily: 'Manrope',
        ...(isMobile && { marginTop: 20 }),
        color: Colors.tundora,
        zIndex: 10
    },
    headerContainer: {
        display: 'flex',
        marginTop: 40,
        ...(isMobile
            ? {
                  flexDirection: 'column',
                  alignItems: 'center'
              }
            : {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
              })
    } as CSSProperties,
    loading: {
        marginTop: 25,
        fontSize: 28,
        fontWeight: 600,
        color: Colors.lightGray5,
        zIndex: 10
    },
    botTableInnerContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    } as CSSProperties,
    noBotsMessage: {
        fontSize: 20,
        color: Colors.tundora,
        margin: 40,
        textAlign: 'center'
    } as CSSProperties,
    clickHereBtn: {
        outline: 'none',
        border: 'none',
        color: Colors.purple,
        backgroundColor: Colors.transparent,
        textDecoration: 'none',
        fontWeight: 600
    },
    botInfoText: {
        marginTop: 4,
        marginBottom: 10,
        color: Colors.darkGray1,
        fontSize: 16,
        zIndex: 10
    },
    leftSideHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 18
    } as CSSProperties,
    topBotsTable: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        padding: 40,
        marginBottom: -40,
        ...(!isMobile && {
            paddingLeft: 80
        })
    } as CSSProperties,
    topBotsInnerDiv: {
        display: 'flex',
        flexDirection: 'row'
    } as CSSProperties,
    satelliteIcon: {
        width: 125,
        height: 159,
        position: 'absolute',
        top: 80,
        right: 200,
        zIndex: 0
    } as CSSProperties,
    earthIcon: {
        width: 175,
        height: 175,
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 0,
        borderBottomLeftRadius: 1000
    } as CSSProperties,
    moneyBubblesIcon: {
        width: 70,
        height: 70,
        position: 'absolute',
        top: -25,
        left: -25
    } as CSSProperties,
    bookAppointmentContainer: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 12,
        marginTop: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 0px 40px rgba(150,150,150,0.2)',
        position: 'relative'
    } as CSSProperties,
    bookAppointmentText: {
        fontSize: 17,
        marginLeft: 11,
        color: Colors.steel,
        cursor: 'pointer'
    },
    refreshBtn: {
        marginLeft: 14,
        position: 'relative',
        top: 2
    }
});
