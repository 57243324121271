import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { getBot, getBotId } from 'src/redux/bot/selector';
import { useIsVisitorUsingAccessCode } from 'src/screens/botWorkspace/lib';
import { Colors } from 'src/utils/colors';
import { DecisionRow } from './DecisionRow';

export const PastDecisions = () => {
    const bot = useSelector(getBot);
    const botId = useSelector(getBotId);
    const { accessCode } = useIsVisitorUsingAccessCode();
    const accessCodeUrl = accessCode ? `?accessCode=${accessCode}` : '';

    return bot ? (
        <div style={styles.container}>
            <label style={styles.title}>Recent Decisions</label>
            <label style={styles.description}>
                {bot.decisionHistory?.length ? (
                    `When your bot makes a decision, it will be inactive for ${bot.cooldownMins} minutes before it can make another decision.`
                ) : (
                    <label>
                        Your bot has made no decisions.{' '}
                        <label>
                            It will automatically make a decision whenever your{' '}
                            <a
                                href={`/bot-workspace/${botId}/buy-triggers${accessCodeUrl}`}
                                style={styles.triggersLink}
                            >
                                triggers
                            </a>{' '}
                            go off.
                        </label>
                    </label>
                )}
            </label>
            <div style={styles.tableContainer}>
                {bot.decisionHistory?.length
                    ? bot.decisionHistory?.map(decision => (
                          <DecisionRow decision={decision} />
                      ))
                    : null}
            </div>
        </div>
    ) : null;
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        paddingTop: 60
    } as CSSProperties,
    title: {
        fontSize: 26,
        fontWeight: 600
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 30,
        paddingBottom: 50
    } as CSSProperties,
    noDecisionsLbl: {
        fontSize: 20,
        color: Colors.tundora,
        textAlign: 'center'
    } as CSSProperties,
    triggersLink: {
        color: Colors.purple,
        fontWeight: 600
    },
    description: {
        color: Colors.darkGray1,
        marginTop: 5
    }
};
