import * as Sentry from '@sentry/react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'src/firebaseConfig';
import { useIsWindowSkinny } from 'src/utils/windowHooks';

import ValidationError from 'src/sharedComponents/ValidationError';
import { responsiveStyles } from './styles';
import { signInValidationSchema } from './validator';
import Swal from 'sweetalert2';
import { SegmentEvents, analytics } from 'src/utils/segmentClient';

const INVALID_CREDENTIALS_MSG = 'Your email and/or password is incorrect.';

const INITIAL_FORM_VALUES = {
    email: '',
    password: ''
};

export const SignInForm = () => {
    const isWindowSkinny = useIsWindowSkinny();
    const styles = responsiveStyles(isWindowSkinny);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSignIn = async (formVals: any) => {
        try {
            setErrorMessage('');
            setIsLoading(true);
            const { email, password } = formVals;
            await firebase.auth().signInWithEmailAndPassword(email, password);
            analytics.track(SegmentEvents.LoggedIn, {
                email
            });
        } catch (e) {
            Sentry.captureException(e);
            setErrorMessage(
                e.code === 'auth/user-not-found'
                    ? INVALID_CREDENTIALS_MSG
                    : e.message
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = (email: string) => {
        try {
            if (email) {
                firebase.auth().sendPasswordResetEmail(email);
            } else {
                throw new Error(
                    'Please enter your email into the email field.'
                );
            }
            Swal.fire({
                title: 'Sent Reset Link',
                text: `Click the link we sent in an email to ${email} to reset your password.`,
                icon: 'success'
            });
        } catch (e) {
            Sentry.captureException(e);
            setErrorMessage(e.message);
        }
    };

    return (
        <div style={styles.container}>
            <label style={styles.signInTitle}>Sign in to Trellis</label>
            <Link to="/sign-up" style={styles.signUpInsteadButton}>
                Don't have an account? Click here to sign up.
            </Link>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={INITIAL_FORM_VALUES}
                validationSchema={signInValidationSchema}
                onSubmit={handleSignIn}
            >
                {({ errors, values }) => (
                    <Form>
                        {/* Email input */}
                        <div style={styles.inputSection}>
                            <label style={styles.inputDesc}>Email</label>
                            <Field
                                style={styles.inputTextfield}
                                name="email"
                                placeholder="johndoe@email.com"
                                type="email"
                            />
                            <ValidationError message={errors.email} />
                        </div>
                        {/* Password input */}
                        <div style={styles.inputSection}>
                            <label style={styles.inputDesc}>Password</label>
                            <Field
                                style={styles.inputTextfield}
                                name="password"
                                placeholder="something secret"
                                type="password"
                            />
                            <ValidationError message={errors.password} />
                        </div>
                        <button
                            style={styles.forgotPassword}
                            type="button"
                            onClick={() => handleForgotPassword(values.email)}
                        >
                            Forgot password?
                        </button>
                        <div style={styles.submitSection}>
                            {/* Display error msg if necessary */}
                            {errorMessage && (
                                <label style={styles.errorMessage}>
                                    {errorMessage}
                                </label>
                            )}
                            {/* Sign in button */}
                            <button
                                style={{
                                    ...styles.signInButton,
                                    ...(isLoading && { opacity: 0.3 })
                                }}
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Signing In...' : 'Sign In'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
