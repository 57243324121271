import React, { useEffect, CSSProperties } from 'react';

import { useScreenReloadSegment } from 'src/utils/helpers';
import { SegmentEvents, analytics } from 'src/utils/segmentClient';
import { SetupForm } from './components/SetupForm';

export const SetupBot = () => {
    useScreenReloadSegment(SegmentEvents.ViewedSetupBot);

    return (
        <div style={styles.container}>
            <SetupForm />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};
