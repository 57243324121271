import * as yup from 'yup';

const MIN_NAME_LENGTH = 2;
const MIN_PASSWORD_LENGTH = 7;

export const signUpValidationSchema = yup.object({
    firstName: yup
        .string()
        .required('Please enter your first name.')
        .min(
            MIN_NAME_LENGTH,
            `Your first name must be at least ${MIN_NAME_LENGTH} characters.`
        ),
    lastName: yup
        .string()
        .required('Please enter your last name.')
        .min(
            MIN_NAME_LENGTH,
            `Your last name must be at least ${MIN_NAME_LENGTH} characters.`
        ),
    email: yup
        .string()
        .required('Please enter your email.')
        .email('Please enter a valid email.'),
    password: yup
        .string()
        .required('Please enter a strong password.')
        .min(
            MIN_PASSWORD_LENGTH,
            `Your password must be at least ${MIN_PASSWORD_LENGTH} characters.`
        ),
    confirmedPassword: yup
        .string()
        .required('Please confirm your password.')
        .test(
            'Matches password.',
            `Your password and confirmed password don't match.`,
            function (confirmedPassword: any) {
                return this.parent.password === confirmedPassword;
            }
        )
});
