import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { Api } from 'src/api';
import { getUser } from 'src/redux/user/selector';
import { Colors } from 'src/utils/colors';
import Swal from 'sweetalert2';
import { PlanEnum } from 'trellis-types';

type Props = {
    title: string;
    notes: string[];
    price: string;
    plan: PlanEnum;
    buttonTitle?: string;
};

export const Block = ({ title, notes, price, plan, buttonTitle }: Props) => {
    const user = useSelector(getUser);
    const currentPlan = user?.currentPlan;
    const hasThisPlan = currentPlan === plan;

    const goToCheckout = async () => {
        try {
            // temporarily disables premium purchases
            Swal.fire({
                title: 'Sorry',
                text: 'Premium is not available at this time.',
                icon: 'error'
            });
            return;

            
            const res = await Api.users.getCheckoutPageUrl(plan);
            const { checkoutSessionUrl } = res.data;
            window.location.href = checkoutSessionUrl;
        } catch (e) {
            Swal.fire({
                title: 'Sorry',
                text: 'There was an issue. Please try again or contact support.',
                icon: 'error'
            });
        }
    };

    return (
        <div style={styles.container}>
            <label style={styles.title}>{title}</label>
            {hasThisPlan && currentPlan !== PlanEnum.Free && (
                <label style={styles.cancelSubscription}>
                    Contact support to cancel.
                </label>
            )}
            <label style={styles.price}>{price}</label>
            {notes.map(note => (
                <label style={styles.note}>• {note}</label>
            ))}
            {plan !== PlanEnum.Free && (
                <button
                    style={{
                        ...styles.getBtn,
                        ...(hasThisPlan && {
                            backgroundColor: Colors.darkGray1,
                            cursor: 'default'
                        })
                    }}
                    onClick={goToCheckout}
                    disabled={hasThisPlan}
                >
                    {buttonTitle}
                </button>
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: 22,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: 480,
        width: 290,
        backgroundColor: Colors.lightGray2,
        boxShadow: '0px 10px 20px 10px rgba(0,0,0,0.2)',
        position: 'relative',
        marginLeft: 40,
        borderRadius: 12,
        overflow: 'hidden'
    } as CSSProperties,
    title: {
        fontSize: 26,
        fontWeight: 600,
        marginBottom: 5
    },
    note: {
        fontSize: 16,
        color: Colors.darkGray1
    },
    getBtn: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 50,
        backgroundColor: Colors.orange,
        color: Colors.white,
        outline: 'none',
        border: 'none',
        width: '100%',
        cursor: 'pointer',
        fontSize: 18,
        fontFamily: 'Manrope',
        fontWeight: 600
    } as CSSProperties,
    price: {
        marginBottom: 20,
        fontWeight: 600,
        color: Colors.purple,
        fontSize: 17
    },
    cancelSubscription: {
        color: Colors.darkPurple,
        fontSize: 13,
        marginBottom: 4
    }
};
