import { useEffect } from 'react';
import { flow, includes, last, lowerCase, startCase } from 'lodash/fp';
import {
    BotDocument,
    CompetitionDocument,
    DecisionDocument,
    DecisionStatusEnum,
    Ticker
} from 'trellis-types';
import { Colors } from './colors';
import { analytics } from 'src/utils/segmentClient';
import _ from 'lodash';
import { AxiosError } from 'axios';

export const getKeyFromValueInMap = (map: Object, valueToFind: any) => {
    for (const [key, value] of Object.entries(map)) {
        if (value === valueToFind) return key;
    }
    return undefined;
};

export const shortenString = (str: string, length: number) => {
    if (str.length <= length) {
        return str;
    }
    return str.substring(0, length) + '...';
};

// ie. if pathName is /path1/path2/path3 output will be path3
export const getLastPath = (pathName: string) => last(pathName.split('/'));

// ie. if pathName is /path1/path2/path3 output will be /path3
export const getLastPathWithSlash = (pathName: string) =>
    '/' + getLastPath(pathName);

// ie. if str is HELLO output will be Hello
export const sentenceCase = (str: string) => flow(lowerCase, startCase)(str);

// ie. if ticker is SDFADSFSDF output will be false
export const isValidTicker = (ticker: string | Ticker) =>
    includes(ticker, Ticker);

// true if the bot is a valid participant in the competition, false otherwise
export const isValidCompetitionBot = (
    bot: BotDocument,
    competition: CompetitionDocument | null
) =>
    competition
        ? new Date(_.get(bot, 'createdAt')) >
          new Date(_.get(competition, 'createdAt'))
        : true;

// true if the bot is currently trading with real money, false otherwise
export const isUsingRealMoney = (bot: BotDocument) =>
    Boolean(bot.realMoneyEnabled && bot.alpacaIntegrationComplete);

// ie. if str is "apple", titleCase of str will be "Apple"
export const titleCase = (str: string) =>
    str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());

// red if negative, green if positive, gray if no change
export const getBotPerformanceTextColor = (performanceText: string = '') => {
    const firstChar = performanceText.charAt(0);
    if (firstChar === '-') {
        return Colors.red;
    } else if (
        firstChar === '+' &&
        Number.parseFloat(
            performanceText.substring(1, performanceText.length - 1)
        ) !== 0 &&
        performanceText.substring(2, 7) != '0.00 '
    ) {
        return Colors.green;
    }
    return Colors.lightGray5;
};

export const useScreenReloadSegment = (segmentEvent: string) => {
    useEffect(() => {
        analytics.track(segmentEvent);
    }, []);
};

// example: 50000 -> 50,000
export const numberToNumberWithCommas = (val: number): string =>
    val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const interpretDecisionStatus = (
    bot: BotDocument,
    decision: DecisionDocument
) => {
    switch (decision.decisionStatus) {
        case DecisionStatusEnum.Open:
            return 'OPEN';
        case DecisionStatusEnum.Expired:
            return 'EXPIRED';
        case DecisionStatusEnum.Canceled:
            return 'CANCELED';
        case DecisionStatusEnum.Filled:
            return 'FILLED';
        default:
            return isUsingRealMoney(bot) ? 'SENT' : 'FILLED';
    }
};

export const getErrorMessage = (e: AxiosError) =>
    _.get(e, 'response.data', e.message);
