import React, { CSSProperties, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setIsMobileSidebarOpen } from 'src/redux/actionIndex';
import { getLastPathWithSlash } from 'src/utils/helpers';

import { Colors } from '../../../utils/colors';
import { ActiveScreenEnum } from '../lib';

type Props = {
    screenIdentifier?: ActiveScreenEnum;
    href?: string;
    title: string;
    icon: string;
};

const UNSELECTED_BACKGROUND_COLOR = Colors.transparent;
const SELECTED_BACKGROUND_COLOR = Colors.catskillWhite;
const HOVERING_BACKGROUND_COLOR = Colors.lightGray2;

export const SidebarRow = ({ title, screenIdentifier, href, icon }: Props) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const path = getLastPathWithSlash(location.pathname);
    const isSelected = screenIdentifier && path === screenIdentifier;
    const backgroundColor = useMemo(() => {
        if (isSelected) {
            return SELECTED_BACKGROUND_COLOR;
        } else if (isHovering) {
            return HOVERING_BACKGROUND_COLOR;
        }
        return UNSELECTED_BACKGROUND_COLOR;
    }, [isSelected, isHovering]);

    const handleSetActiveScreen = () => {
        dispatch(setIsMobileSidebarOpen(false));
        if (screenIdentifier) {
            history.push(screenIdentifier);
        } else if (href) {
            window.open(href);
        }
    };

    return (
        <button
            onClick={handleSetActiveScreen}
            style={{
                ...styles.container,
                backgroundColor,
                ...(isSelected && { cursor: 'auto' })
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <img src={icon} alt="" style={styles.icon} />
            <label
                style={{
                    ...styles.title,
                    ...(isSelected && { cursor: 'auto' })
                }}
            >
                {title}
            </label>
        </button>
    );
};

const styles = {
    container: {
        outline: 'none',
        border: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 44,
        cursor: 'pointer',
        paddingLeft: 15,
        paddingRight: 15
    } as CSSProperties,
    title: {
        fontSize: 15,
        fontWeight: 600,
        cursor: 'pointer',
        fontFamily: 'Manrope',
        color: Colors.tundora
    } as CSSProperties,
    icon: {
        width: 25,
        height: 25,
        marginRight: 10
    }
};
