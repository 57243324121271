import { PerformanceInterval, TriggerMatrixMap } from 'trellis-types';

import axios from '../utils/axios';

type CreateBotBody = {
    ticker: string;
    buyQuantity: number | '';
    sellQuantity: number | '';
    buyQuantityMaximized: boolean;
    sellQuantityMaximized: boolean;
    triggerMatrixMap: TriggerMatrixMap;
    initialBuyingPower: number;
    realMoneyEnabled: boolean;
    forceBuyOnNextRun: boolean;
    isPrivate: boolean;
};

type UpdateBotBody = {
    ticker: string;
    isActive: boolean;
    buyQuantity: number | '';
    sellQuantity: number | '';
    buyQuantityMaximized: boolean;
    sellQuantityMaximized: boolean;
    triggerMatrixMap: TriggerMatrixMap;
    realMoneyEnabled: boolean;
    forceBuyOnNextRun: boolean;
};

const Bots = {
    createBot: async (body: CreateBotBody) => axios.post('/bots/', body),
    updateBot: async (botId: string, body: Partial<UpdateBotBody>) =>
        axios.put(`/bots/${botId}`, body),
    getBotById: async (
        botId: string,
        accessKey?: string,
        performanceHistoryInterval?: PerformanceInterval
    ) =>
        axios.get(`/bots/${botId}`, {
            params: {
                includeTriggers: true,
                includeDecisionHistory: true,
                includePerformance: true,
                includePerformanceHistory: true,
                performanceHistoryInterval,
                accessKey
            }
        }),
    createShareLink: async (botId: string) => axios.post(`/bots/${botId}/link`),
    getIntegrations: async (botId: string) =>
        axios.get(`/bots/${botId}/integrations`),
    getMyBots: async (includePerformances: boolean) =>
        axios.get(`/bots/me`, { params: { includePerformances } }),
    deleteBot: async (botId: string) => axios.delete(`/bots/${botId}`),
    updateAlpacaCompletionStatus: async (botId: string) =>
        axios.post(`/bots/${botId}/alpaca-texting`),
    getLeaderboardBots: async () =>
        axios.get(`/bots/top-performances`, {
            params: {
                numBots: 50
            }
        }),
    getMyRankedBots: async (userId: string) =>
        axios.get(`/bots/user/rankings`, {
            params: {
                userId
            }
        }),
    sellAllShares: async (botId: string) =>
        axios.post(`/bots/${botId}/sell-all`)
};

export default Bots;
