import { createSelector } from 'reselect';
import { getCompetitionReducer } from '../selectorIndex';

export const getIsGlobalCompetitionActive = createSelector(
    [getCompetitionReducer],
    competitionReducer => competitionReducer.isGlobalCompetitionActive
);
export const getCurrentCompetition = createSelector(
    [getCompetitionReducer],
    competitionReducer => competitionReducer.currentCompetition
);
