import axios from 'src/utils/axios';

const Alpaca = {
    getAuthLink: () => axios.get('/alpaca/auth-link'),
    sendUserToken: (code: string) =>
        axios.post('/alpaca/user-token', {
            code
        }),
    isEnoughAlpacaBuyingPower: (initialBuyingPower: number) => axios.get('/alpaca/buying-power-check', {
        params: {
            initialBuyingPower
        }
    })
};

export default Alpaca;
