import React, { CSSProperties } from 'react';
import { Colors } from 'src/utils/colors';
import MoneyBag from 'src/assets/moneyBag.png';
import { useSelector } from 'react-redux';
import { getUser } from 'src/redux/user/selector';
import { useIsWindowSkinny } from 'src/utils/windowHooks';
import { openTradeWithRealMoneyAlert } from '../lib';

export const LiveTradingAccount = () => {
    const isWindowSkinny = useIsWindowSkinny();
    const user = useSelector(getUser);

    if (!user || user.encryptedAlpacaToken || isWindowSkinny) {
        return null;
    }

    const handleClick = () => openTradeWithRealMoneyAlert('DASHBOARD');

    return (
        <button style={styles.container} onClick={handleClick}>
            <img src={MoneyBag} style={styles.icon} alt="" />
            <label style={styles.label}>
                Click here to open a live trading account for free.
            </label>
        </button>
    );
};

const styles = {
    container: {
        height: 40,
        width: '100%',
        backgroundColor: Colors.green,
        boxShadow: '0px 2px 7px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 'none',
        border: 'none',
        cursor: 'pointer'
    } as CSSProperties,
    label: {
        color: Colors.white,
        fontWeight: 500,
        cursor: 'pointer'
    },
    icon: {
        width: 25,
        height: 25,
        marginRight: 7,
        cursor: 'pointer'
    }
};
