// Use https://chir.ag/projects/name-that-color/#6195ED for naming colors
// when 2+ colors are similar

export const Colors = {
    veryDarkPurple: '#25054d',
    darkerPurple: '#331757',
    darkPurple: '#7730c7',
    purple: '#903DEF',
    lightPurple: '#ba7dff',
    superLightPurple: '#faf2ff',
    white: '#FFFFFF',
    lightRed: '#ff969c',
    red: '#e81313',
    superLightRed: '#ffe6e7',
    black: '#000000',
    green: '#0ac225',
    lightGreen: '#61ed76',
    prettyGreen: '#2ECC71',
    superLightGreen: '#e6ffe9',
    blue: '#487FD9',
    warningYellow: '#dbac00',
    lightGraphRed: '#ff91a0',
    lightGraphGreen: '#a6ffb3',
    goldenYellow: '#ffdd00',
    orange: '#f2aa00',

    // background shades
    blackSqueeze: '#F6F9FC',
    alto: '#DFDFDF',
    catskillWhite: '#EEF1F6',
    darkBlackBlueHint: '#1E1F26',
    steelBlueHint: '#2e2f3b',
    steel: '#3f404a',
    tundora: '#404040',
    lightGray1: '#fcfcfc',
    lightGray2: '#f5f5f5',
    lightGray3: '#ededed',
    lightGray4: '#dbdbdb',
    lightGray5: '#adadad',
    darkGray1: '#696969',
    paper: 'rgb(249,249,249)',

    transparent: 'rgba(0,0,0,0)'
};
