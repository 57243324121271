import React, { CSSProperties, useState } from 'react';
import { Colors } from 'src/utils/colors';
import { StarterPack } from '../lib';

type Props = {
    pack: StarterPack;
    openModalForPack: (pack: StarterPack) => void;
};

const CELL_WIDTH = 340;
const CELL_HEIGHT = 170;

export const PackCell = ({ pack, openModalForPack }: Props) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    return (
        <button
            style={{
                ...styles.container,
                ...(isHovering && styles.isHoveringContainer)
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={() => openModalForPack(pack)}
        >
            <div style={styles.innerContainer}>
                <label style={styles.title}>{pack.name}</label>
                {isHovering && (
                    <label style={styles.learnMoreLbl}>
                        <span style={{ textDecorationLine: 'underline' }}>
                            Click to learn more
                        </span>
                    </label>
                )}
            </div>
        </button>
    );
};

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: CELL_WIDTH,
        height: CELL_HEIGHT,
        overflow: 'hidden',
        borderRadius: 15,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.2)',
        transition: 'all 0.2s ease-out',
        cursor: 'pointer',
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.steelBlueHint,
        color: Colors.white
    } as CSSProperties,
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        margin: 15
    } as CSSProperties,
    title: {
        fontSize: 25,
        fontWeight: 700,
        textAlign: 'center',
        cursor: 'pointer'
    } as CSSProperties,
    isHoveringContainer: {
        transform: 'scale(1.05)',
        color: Colors.white,
        backgroundImage: 'linear-gradient(53deg, #7e1cff, #4f1fff)'
    },
    learnMoreLbl: {
        color: Colors.white,
        fontWeight: 600,
        cursor: 'pointer',
        marginTop: 8
    }
};
