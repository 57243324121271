import { prop } from 'lodash/fp';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { NEW_BOT_EDITOR_PATH } from 'src/App';
import { setBuyTriggers, setSellTriggers } from 'src/redux/bot/action';
import {
    getBot,
    getIndicator,
    getTicker,
    useTriggerBlocks
} from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { Constants } from 'src/utils/constants';
import { getLastPath, useScreenReloadSegment } from 'src/utils/helpers';
import { SegmentEvents } from 'src/utils/segmentClient';
import { ConjunctionType, FrontendTrigger } from 'trellis-types';
import { uuid } from 'uuidv4';
import { useIsVisitorUsingAccessCode } from '../../lib';

import { DecisionBlock } from './components/DecisionBlock';
import { SaveBotButton } from './components/SaveBotButton';
import { TriggerBackButton } from './components/TriggerBackButton';
import { TriggerBlock } from './components/TriggerBlock';
import { useTriggerCleaner } from './lib';

export const TriggersScreen = () => {
    useScreenReloadSegment(SegmentEvents.ViewedTriggers);
    useTriggerCleaner();

    const location = useLocation();
    const path = getLastPath(location.pathname);
    const dispatch = useDispatch();
    const triggerBlocks = useTriggerBlocks();
    const currentIndicator = useSelector(getIndicator);
    const ticker = useSelector(getTicker);
    const bot = useSelector(getBot);
    const params = useParams();
    const botId = prop('_id', bot) || prop('id', params);
    const { isVisitorUsingAccessCode } = useIsVisitorUsingAccessCode();
    const titleCaseDecisionType = path === 'buy-triggers' ? 'Buy' : 'Sell';

    const renderTriggerBackButton = () => {
        if (botId === NEW_BOT_EDITOR_PATH && path === 'sell-triggers') {
            return <TriggerBackButton />;
        }
        return null;
    };
    const createTriggerBlock = (conjunction: ConjunctionType | null) => {
        const newTriggerBlock: FrontendTrigger = {
            blockId: uuid(),
            indicator: currentIndicator,
            operand: '',
            operator: Constants.defaultOperatorKey,
            conjunction
        };
        const newTriggerBlocks = [...triggerBlocks, newTriggerBlock];
        path === 'buy-triggers'
            ? dispatch(setBuyTriggers(newTriggerBlocks))
            : dispatch(setSellTriggers(newTriggerBlocks));
    };

    const updateTriggerBlock = (
        blockId: string,
        updatedTriggerBlock: FrontendTrigger | null
    ) => {
        let newTriggerBlocks: FrontendTrigger[] = [];
        if (updatedTriggerBlock) {
            newTriggerBlocks = triggerBlocks.map(block =>
                block.blockId === blockId ? updatedTriggerBlock : block
            );
        } else {
            newTriggerBlocks = triggerBlocks.filter(
                block => block.blockId !== blockId
            );
        }

        path === 'buy-triggers'
            ? dispatch(setBuyTriggers(newTriggerBlocks))
            : dispatch(setSellTriggers(newTriggerBlocks));
    };

    const renderTriggerBlocks = () => (
        <div style={styles.triggerBlocksContainer}>
            {triggerBlocks.map((triggerBlock, index) => (
                <>
                    {index && triggerBlock.conjunction === 'OR' ? (
                        <OrTag />
                    ) : null}
                    <TriggerBlock
                        triggerBlock={triggerBlock}
                        update={updateTriggerBlock}
                    />
                </>
            ))}
        </div>
    );

    const renderAddAndOrBlocks = () => (
        <div style={styles.andOrBlocksContainer}>
            <button
                onClick={() => createTriggerBlock('AND')}
                style={styles.andOrBlock}
            >
                AND
            </button>
            <button
                onClick={() => createTriggerBlock('OR')}
                style={{ ...styles.andOrBlock, marginRight: 5 }}
            >
                OR
            </button>
        </div>
    );

    const renderAddButton = () =>
        triggerBlocks.length ? (
            renderAddAndOrBlocks()
        ) : (
            <button
                style={styles.addBlockBtn}
                onClick={() => createTriggerBlock(null)}
            >
                Add Block
            </button>
        );
    const renderAddBlockSection = () =>
        isVisitorUsingAccessCode ? null : (
            <div style={styles.addBlockSection}>
                <NewTriggerHeader />
                {triggerBlocks.length < Constants.MAX_TRIGGER_LIMIT ? (
                    renderAddButton()
                ) : (
                    <div style={styles.maximumTriggersContainer}>
                        <label style={styles.maximumTriggersText}>
                            Max Triggers Reached
                        </label>
                    </div>
                )}
            </div>
        );

    return (
        <div style={styles.container}>
            <label style={styles.title}>
                Set {titleCaseDecisionType} Triggers
            </label>
            <label style={styles.botInfoText}>
                These will cause your bot to{' '}
                {titleCaseDecisionType.toLowerCase()} shares of {ticker}.
            </label>
            <div style={styles.innerContainer}>
                {renderTriggerBlocks()}
                {renderAddBlockSection()}
                {triggerBlocks.length ? <DecisionBlock /> : null}
                <div style={styles.innerContainer}>
                    <SaveBotButton botId={botId} />
                    {renderTriggerBackButton()}
                </div>
            </div>
        </div>
    );
};

const NewTriggerHeader = () => (
    <div style={styles.newTriggerHeaderContainer}>
        <label style={styles.newTriggerHeaderText}>Add New Trigger</label>
    </div>
);

const OrTag = () => (
    <div style={styles.orTagContainer}>
        <div style={styles.orTagStripe}>
            <label style={styles.orLabel}>OR</label>
        </div>
    </div>
);

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 60,
        height: '100%',
        overflowY: 'auto',
        paddingBottom: 50
    } as CSSProperties,
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 10
    } as CSSProperties,
    title: {
        fontSize: 26,
        fontWeight: 600
    },
    botName: {
        fontSize: 16,
        fontWeight: 700,
        color: Colors.darkGray1
    },
    integrationsBtn: {
        width: 170,
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        color: Colors.purple,
        textAlign: 'left',
        cursor: 'pointer',
        marginTop: 10,
        fontWeight: 700,
        marginLeft: -5,
        fontFamily: 'Manrope'
    } as CSSProperties,
    triggerBlocksContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 25
    } as CSSProperties,
    addBlockSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 15,
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: Colors.lightGray1,
        borderColor: Colors.lightGray4,
        borderWidth: 0,
        borderStyle: 'solid',
        borderRadius: 10,
        overflow: 'hidden',
        boxShadow: '0px 0px 12px rgba(0,0,0,0.2)'
    } as CSSProperties,
    andOrBlock: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.purple,
        color: Colors.white,
        borderRadius: 10,
        height: 30,
        fontFamily: 'Manrope',
        width: 60,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: 5
    } as CSSProperties,
    andOrBlocksContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        alignItems: 'center'
    } as CSSProperties,
    nextBlockIndicatorDesc: {
        fontFamily: 'Manrope',
        marginBottom: 5
    },
    addBlockBtn: {
        marginTop: 10,
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.purple,
        color: Colors.white,
        borderRadius: 10,
        height: 30,
        fontFamily: 'Manrope',
        fontWeight: 600,
        width: 100,
        cursor: 'pointer'
    },
    orTagContainer: {
        height: 70,
        backgroundColor: Colors.transparent,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 180,
        position: 'relative',
        width: 40
    } as CSSProperties,
    orLabel: {
        padding: 3,
        position: 'absolute',
        left: 0,
        right: 0,
        height: 30,
        textAlign: 'center',
        backgroundColor: Colors.darkGray1,
        fontFamily: 'Manrope',
        fontSize: 16,
        fontWeight: 600,
        color: Colors.white,
        top: 21,
        borderRadius: 8
    } as CSSProperties,
    orTagStripe: {
        height: '100%',
        width: 5,
        backgroundColor: Colors.lightGray4
    } as CSSProperties,
    newTriggerHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 30,
        backgroundColor: Colors.tundora,
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20
    } as CSSProperties,
    newTriggerHeaderText: {
        color: Colors.white,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Manrope'
    },
    innerTriggerSelectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 30,
        marginLeft: 30
    } as CSSProperties,
    botInfoText: {
        marginTop: 4,
        marginBottom: 10,
        color: Colors.darkGray1,
        fontSize: 16
    },
    actionLabelText: {
        color: Colors.darkGray1,
        fontSize: 30,
        fontWeight: 600
    },
    maximumTriggersText: {
        outline: 'none',
        border: 'none',
        color: Colors.darkGray1,
        height: 30,
        width: 60,
        fontWeight: 600
    },
    maximumTriggersContainer: {
        padding: 10,
        paddingTop: 20
    }
};
