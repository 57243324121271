import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTicker } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { indicatorMap, operatorMap } from 'src/utils/constants';
import { getKeyFromValueInMap } from 'src/utils/helpers';
import { FrontendTrigger, IndicatorEnum, OperatorType } from 'trellis-types';
import { IndicatorPicker } from './IndicatorPicker';
import { TriggerModal } from './TriggerModal';
import { getRawValue } from '../lib';
import { SegmentEvents, analytics } from 'src/utils/segmentClient';
import TrashIcon from 'src/assets/trash.png';
import QuestionIcon from 'src/assets/questionMarkCircle.png';
import MultiplyIcon from 'src/assets/multiplyBy2.png';
import AddendIcon from 'src/assets/plusMinusIcon.png';
import { useIsVisitorUsingAccessCode } from 'src/screens/botWorkspace/lib';
import { Tooltip } from 'antd';

type UpdateFunction = (
    blockId: string,
    updatedTriggerBlock: FrontendTrigger | null
) => any;

type Props = {
    update: UpdateFunction;
    triggerBlock: FrontendTrigger;
};

export const TriggerBlock = ({ update, triggerBlock }: Props) => {
    const indicatorMapKeys = Object.keys(indicatorMap);
    const currentTicker = useSelector(getTicker);
    const {
        blockId,
        conjunction,
        operator,
        operand,
        indicator,
        multiplier,
        addend
    } = triggerBlock;
    const [includeMultiplier, setIncludeMultiplier] = useState<boolean>(
        typeof multiplier === 'number'
    );
    const [includeAddend, setIncludeAddend] = useState<boolean>(
        typeof addend === 'number'
    );
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { isVisitorUsingAccessCode } = useIsVisitorUsingAccessCode();

    useEffect(() => {
        setIncludeMultiplier(typeof multiplier === 'number');
        setIncludeAddend(typeof addend === 'number');
    }, [blockId]);

    const handleDeleteTriggerBlock = () => update(blockId, null);

    const handleMultiplierChange = (e: any) => {
        const numberVersionOfMultiplier = Number(e.target.value);
        const triggerObject: FrontendTrigger = {
            ...triggerBlock,
            multiplier: numberVersionOfMultiplier
        };
        update(blockId, triggerObject);
    };

    const handleAddendChange = (e: any) => {
        const numberVersionOfAddend = Number(e.target.value);
        const triggerObject: FrontendTrigger = {
            ...triggerBlock,
            addend: numberVersionOfAddend
        };
        update(blockId, triggerObject);
    };

    const handleOperatorPickerChange = (e: any) => {
        const triggerObject: FrontendTrigger = {
            ...triggerBlock,
            operator: getKeyFromValueInMap(
                operatorMap,
                e.target.value
            ) as OperatorType
        };
        update(blockId, triggerObject);
    };

    const handleComparisonIndicatorPickerChange = (e: any) => {
        const numberVersion = getRawValue(e.target.value);
        const triggerObject: FrontendTrigger = {
            ...triggerBlock,
            operand: indicatorMap[
                getKeyFromValueInMap(
                    indicatorMap,
                    e.target.value
                ) as IndicatorEnum
            ]
                ? (getKeyFromValueInMap(
                      indicatorMap,
                      e.target.value
                  ) as IndicatorEnum)
                : numberVersion
        };
        update(blockId, triggerObject);
    };

    const handleTriggerModalOpen = () => {
        analytics.track(SegmentEvents.OpenedTriggerExplanation, {
            indicator,
            operator,
            operand,
            conjunction
        });
        setIsModalVisible(true);
    };

    const renderComparisonOperatorPicker = () => (
        <select
            value={operatorMap[operator]}
            onChange={handleOperatorPickerChange}
            style={styles.comparisonOperatorPicker}
            disabled={isVisitorUsingAccessCode}
        >
            {Object.keys(operatorMap).map(key => (
                <option key={key}>{operatorMap[key as OperatorType]}</option>
            ))}
        </select>
    );

    const renderMultiplierInput = () =>
        includeMultiplier ? (
            <input
                type="number"
                placeholder="Enter number"
                onChange={handleMultiplierChange}
                value={multiplier}
                style={styles.multiplierInput}
                disabled={isVisitorUsingAccessCode}
            />
        ) : null;

    const renderAddedInput = () =>
        includeAddend ? (
            <input
                type="number"
                placeholder="Enter number"
                onChange={handleAddendChange}
                value={addend}
                style={styles.addendInput}
                disabled={isVisitorUsingAccessCode}
            />
        ) : null;

    const renderComparisonIndicatorPicker = () => (
        <div style={styles.comparisonIndicatorPickerContainer}>
            <input
                type="text"
                placeholder="Enter number or pick indicator"
                list="comparisonIndicators"
                onChange={handleComparisonIndicatorPickerChange}
                value={indicatorMap[operand as IndicatorEnum] || operand}
                style={styles.comparisonIndicatorPicker}
                disabled={isVisitorUsingAccessCode}
            />
            <datalist id="comparisonIndicators">
                {indicatorMapKeys.map(key => (
                    <option key={key}>
                        {indicatorMap[key as IndicatorEnum]}
                    </option>
                ))}
            </datalist>
        </div>
    );

    const handleToggleMultiplier = () => {
        const nextState = !includeMultiplier;
        setIncludeMultiplier(nextState);
        if (!nextState) {
            const triggerObject: FrontendTrigger = {
                ...triggerBlock,
                multiplier: undefined
            };
            update(blockId, triggerObject);
        }
    };

    const handleToggleAddend = () => {
        const nextState = !includeAddend;
        setIncludeAddend(nextState);
        if (!nextState) {
            const triggerObject: FrontendTrigger = {
                ...triggerBlock,
                addend: undefined
            };
            update(blockId, triggerObject);
        }
    };

    const handleTriggerModalClosed = () => setIsModalVisible(false);

    return (
        <div style={styles.outerContainer}>
            {!isVisitorUsingAccessCode && (
                <Tooltip title="Remove Trigger">
                    <button
                        onClick={handleDeleteTriggerBlock}
                        style={styles.removeBtn}
                    >
                        <img src={TrashIcon} style={styles.trashIcon} alt="" />
                    </button>
                </Tooltip>
            )}
            {!isVisitorUsingAccessCode && (
                <Tooltip
                    title={
                        includeMultiplier
                            ? 'Remove Multiplier'
                            : 'Add Multiplier'
                    }
                >
                    <button
                        style={styles.multiplyBtn}
                        onClick={handleToggleMultiplier}
                    >
                        <img
                            src={MultiplyIcon}
                            style={styles.multiplyIcon}
                            alt=""
                        />
                    </button>
                </Tooltip>
            )}
            {!isVisitorUsingAccessCode && (
                <Tooltip title={includeAddend ? 'Remove Addend' : 'Add Addend'}>
                    <button
                        style={styles.addendBtn}
                        onClick={handleToggleAddend}
                    >
                        <img
                            src={AddendIcon}
                            style={styles.addendIcon}
                            alt=""
                        />
                    </button>
                </Tooltip>
            )}
            <Tooltip title="What's This Trigger?">
                <button
                    style={styles.triggerInfoButton}
                    onClick={handleTriggerModalOpen}
                >
                    <img
                        src={QuestionIcon}
                        style={styles.questionIcon}
                        alt=""
                    />
                </button>
            </Tooltip>
            <div style={styles.container}>
                <label style={styles.innerContainer}>
                    {conjunction === 'AND' ? 'AND' : 'If'} the{' '}
                    <IndicatorPicker
                        triggerBlock={triggerBlock}
                        update={update}
                    />{' '}
                    of {currentTicker}
                    {includeMultiplier ? ' multiplied by ' : ''}
                    {renderMultiplierInput()}
                    {includeAddend
                        ? `${includeMultiplier ? ', ' : ' '}plus `
                        : ''}
                    {renderAddedInput()}
                    {renderComparisonOperatorPicker()}
                    {renderComparisonIndicatorPicker()}
                </label>
                <TriggerModal
                    onClose={handleTriggerModalClosed}
                    isVisible={isModalVisible}
                    triggerBlock={triggerBlock}
                />
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        backgroundColor: Colors.lightGray1,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        borderColor: Colors.lightGray4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        width: '100%'
    } as CSSProperties,
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        lineHeight: 3
    } as CSSProperties,
    removeBtn: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        fontFamily: 'Manrope',
        marginRight: 5,
        fontWeight: 500,
        fontSize: 15,
        color: Colors.darkGray1,
        cursor: 'pointer',
        textDecoration: 'underline',
        marginBottom: 4
    } as CSSProperties,
    comparisonIndicatorPickerContainer: {
        marginLeft: 5
    } as CSSProperties,
    comparisonIndicatorPicker: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.lightGray3,
        padding: 5,
        borderRadius: 10,
        width: 200,
        fontFamily: 'Manrope',
        fontSize: 13,
        height: 32
    } as CSSProperties,
    comparisonOperatorPicker: {
        marginLeft: 5,
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.lightGray3,
        padding: 5,
        borderRadius: 10,
        fontFamily: 'Manrope',
        fontSize: 13,
        height: 32
    } as CSSProperties,
    multiplierInput: {
        marginLeft: 5,
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.lightGray3,
        padding: 5,
        borderRadius: 10,
        fontFamily: 'Manrope',
        fontSize: 13,
        height: 32,
        width: 115
    },
    addendInput: {
        marginLeft: 5,
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.lightGray3,
        padding: 5,
        borderRadius: 10,
        fontFamily: 'Manrope',
        fontSize: 13,
        height: 32,
        width: 115
    },
    triggerInfoButton: {
        shadow: 'none',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: Colors.transparent,
        marginRight: 10,
        position: 'relative',
        bottom: 1
    } as CSSProperties,
    outerContainer: {
        display: 'flex',
        flexDirection: 'row'
    } as CSSProperties,
    trashIcon: {
        width: 20,
        height: 20
    },
    questionIcon: {
        width: 23,
        height: 23
    },
    multiplyIcon: {
        width: 23,
        height: 23
    },
    addendIcon: {
        width: 23,
        height: 23
    },
    multiplyBtn: {
        shadow: 'none',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: Colors.transparent,
        marginRight: 5,
        position: 'relative',
        bottom: 1
    } as CSSProperties,
    addendBtn: {
        shadow: 'none',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: Colors.transparent,
        marginRight: 5,
        position: 'relative',
        bottom: 1
    } as CSSProperties
};
