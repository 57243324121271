import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import WarningIcon from 'src/assets/warningIcon.png';
import { getCurrentCompetition } from 'src/redux/competition/selector';
import { Colors } from 'src/utils/colors';
import { isValidCompetitionBot, isValidTicker } from 'src/utils/helpers';
import { useIsWindowSkinny } from 'src/utils/windowHooks';
import { BotDocument, CompetitionDocument } from 'trellis-types';

interface Props {
    bot: BotDocument;
}

export const WarningToolTip = ({ bot }: Props) => {
    const isWindowSkinny = useIsWindowSkinny();
    const currentCompetition = useSelector(getCurrentCompetition);
    const warningMessage = _getWarning(bot, currentCompetition);

    return warningMessage && !isWindowSkinny ? (
        <div style={styles.container}>
            <img
                data-tip
                data-for={`warning-icon-${bot._id}`}
                src={WarningIcon}
                style={styles.warningIcon}
                alt=""
            />
            <ReactTooltip
                id={`warning-icon-${bot._id}`}
                effect="solid"
                className="react-tooltip-overrides"
                backgroundColor={Colors.darkerPurple}
            >
                <div style={{ maxWidth: 310 }}>{warningMessage}</div>
            </ReactTooltip>
        </div>
    ) : null;
};

const _getWarning = (
    bot: BotDocument,
    currentCompetition: CompetitionDocument | null
): JSX.Element | null => {
    if (!isValidTicker(bot.ticker)) {
        return (
            <label>
                We no longer support{' '}
                <span style={styles.emphasisText}>{bot.ticker}</span> on
                Trellis. You'll still be able to view this bot's history, but it
                won't make any new decisions.
            </label>
        );
    }
    if (!isValidCompetitionBot(bot, currentCompetition)) {
        return (
            <label>
                This bot will not be entered into the{' '}
                <span style={styles.emphasisText}>
                    {currentCompetition?.code}
                </span>{' '}
                competition because it was created after the competition
                started.
            </label>
        );
    }
    return null;
};

const styles = {
    container: {
        marginRight: 15
    } as CSSProperties,
    warningIcon: {
        width: 20
    } as CSSProperties,
    emphasisText: {
        color: Colors.lightPurple,
        fontWeight: 900
    }
};
