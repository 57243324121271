import { DashboardActionsIndex } from './action';

export type DashboardReducerType = {
    isMobileMenuOpen: boolean;
};

const initialState: DashboardReducerType = {
    isMobileMenuOpen: false
};

export const dashboardReducer = (
    state: DashboardReducerType = initialState,
    action: DashboardActionsIndex
): DashboardReducerType => {
    switch (action.type) {
        case 'SET_IS_MOBILE_MENU_OPEN':
            return {
                ...state,
                isMobileMenuOpen: action.isMobileMenuOpen
            };
        default:
            return state;
    }
};
