import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPerformanceInterval } from 'src/redux/actionIndex';
import { getPerformanceInterval } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { PerformanceInterval } from 'trellis-types';

export const IntervalToggle = () => {
    const dispatch = useDispatch();
    const performanceInterval = useSelector(getPerformanceInterval);

    const IntervalButton = ({
        interval
    }: {
        interval: PerformanceInterval;
    }) => (
        <button
            style={{
                ...styles.buttonContainer,
                ...(performanceInterval === interval && styles.selectedBtn)
            }}
            onClick={() => dispatch(setPerformanceInterval(interval))}
        >
            {interval}
        </button>
    );

    return (
        <div style={styles.container}>
            <IntervalButton interval={PerformanceInterval._1Day} />
            <IntervalButton interval={PerformanceInterval._1Week} />
            <IntervalButton interval={PerformanceInterval._1Month} />
            <IntervalButton interval={PerformanceInterval.Max} />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        padding: 2,
        backgroundColor: Colors.white,
        borderRadius: 10,
        boxShadow: '0px 0px 6px rgba(0,0,0,0.2)'
    },
    buttonContainer: {
        margin: 2,
        width: 40,
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        color: Colors.purple,
        cursor: 'pointer',
        borderRadius: 10,
        fontWeight: 600
    },
    selectedBtn: {
        backgroundColor: Colors.purple,
        color: Colors.superLightPurple,
        cursor: 'default'
    }
};
