import * as Sentry from '@sentry/react';
import _ from 'lodash';
import React, { CSSProperties, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'src/api';
import { fetchIntegrations } from 'src/redux/bot/action';
import { getBot } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { getErrorMessage } from 'src/utils/helpers';
import { analytics, SegmentEvents } from 'src/utils/segmentClient';
import Swal from 'sweetalert2';
import { IntegrationDocument, IntegrationEnum } from 'trellis-types';

import {
    DumbIntegrationBody,
    FakeDumbIntegrationBody,
    fakeIntegrationMap,
    integrationMap,
    NonRealIntegrationKeysEnum
} from '../lib';
import { IntegrationModal } from './IntegrationModal';

type Props = {
    existingIntegration?: IntegrationDocument;
    realIntegrationKey?: IntegrationEnum;
    fakeIntegrationKey?: NonRealIntegrationKeysEnum;
    isFirstInList: boolean;
};

export const IntegrationRow = ({
    existingIntegration,
    realIntegrationKey,
    fakeIntegrationKey,
    isFirstInList
}: Props) => {
    const dispatch = useDispatch();
    const isApproved = existingIntegration?.isApproved;
    const integrationSkeleton: DumbIntegrationBody | FakeDumbIntegrationBody =
        integrationMap[realIntegrationKey as any] ||
        fakeIntegrationMap[fakeIntegrationKey as any];
    const bot = useSelector(getBot);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const connectionBtnDetails = useMemo(() => {
        if ((existingIntegration && isApproved) || fakeIntegrationKey) {
            // disconnect style
            return {
                style: {
                    color: Colors.red,
                    backgroundColor: Colors.transparent
                } as CSSProperties,
                text: 'Disconnect'
            };
        }

        if (existingIntegration) {
            // needs approval style
            return {
                style: {
                    color: Colors.white,
                    backgroundColor: Colors.warningYellow
                },
                text: 'Needs Approval'
            };
        }

        // connect style
        return {
            style: {},
            text: 'Connect'
        };
    }, [!!existingIntegration, fakeIntegrationKey, isApproved]);

    const handleActionBtnPressed = async () => {
        if (fakeIntegrationKey) {
            const fakeIntegration =
                fakeIntegrationMap[NonRealIntegrationKeysEnum.Alpaca];
            Swal.fire({
                title: 'Sorry',
                text: fakeIntegration.cannotRemoveMsg,
                icon: 'error'
            });
        } else if (existingIntegration && isApproved) {
            Swal.fire({
                title: 'Please Confirm',
                text: `Are you sure you want to disconnect ${integrationSkeleton.displayName} from your bot?`,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Disconnect',
                icon: 'warning'
            }).then(async res => {
                if (res.isConfirmed) {
                    try {
                        setIsLoading(true);
                        await Api.integrations.integration.deleteIntegration(
                            existingIntegration._id
                        );
                        analytics.track(SegmentEvents.DeletedIntegration, {
                            type: existingIntegration.type
                        });
                        await fetchIntegrations(dispatch, bot?._id);
                    } catch (e) {
                        Sentry.captureException(e);
                        Swal.fire({
                            title: 'Something Went Wrong',
                            text: getErrorMessage(e),
                            icon: 'error'
                        });
                    } finally {
                        setIsLoading(false);
                    }
                }
            });
        } else if (existingIntegration) {
            // this integration needs approval
            try {
                await Api.integrations.integration.updateIntegration(
                    existingIntegration._id,
                    { isApproved: true }
                );
                await fetchIntegrations(dispatch, bot?._id);
                Swal.fire({
                    title: 'Approved',
                    text: "You've successfully approved this integration. Now this integration is active.",
                    icon: 'success'
                });
            } catch (e) {
                Swal.fire({
                    title: 'Something Went Wrong',
                    text: getErrorMessage(e),
                    icon: 'error'
                });
            }
        } else {
            // add new integration
            setIsModalVisible(true);
        }
    };

    const handleIntegrationModalClosed = () => {
        fetchIntegrations(dispatch, bot?._id);
        setIsModalVisible(false);
    };

    return (
        <div
            style={{
                ...styles.container,
                ...(!isFirstInList && {
                    borderTopWidth: 1,
                    borderTopStyle: 'solid',
                    borderTopColor: Colors.lightGray3
                })
            }}
        >
            <div style={styles.leftSide}>
                <img
                    src={integrationSkeleton.imageSource}
                    style={styles.logo}
                    alt=""
                />
                <label style={styles.name}>
                    {integrationSkeleton.displayName}
                </label>
            </div>
            <div style={styles.rightSide}>
                <button
                    disabled={isLoading}
                    style={{
                        ...styles.actionBtn,
                        ...connectionBtnDetails.style
                    }}
                    onClick={handleActionBtnPressed}
                >
                    {connectionBtnDetails.text}
                </button>
            </div>
            {realIntegrationKey ? (
                <IntegrationModal
                    onClose={handleIntegrationModalClosed}
                    isVisible={isModalVisible}
                    integrationKey={realIntegrationKey}
                />
            ) : null}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: 68,
        alignItems: 'center',
        padding: '20px 30px 20px 30px'
    } as CSSProperties,
    logo: {
        width: 30,
        marginRight: 20
    } as CSSProperties,
    name: {
        fontSize: 22,
        fontWeight: 600
    } as CSSProperties,
    leftSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    } as CSSProperties,
    rightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    } as CSSProperties,
    actionBtn: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.purple,
        color: Colors.white,
        fontFamily: 'Manrope',
        cursor: 'pointer',
        fontWeight: 600,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 12,
        textAlign: 'center'
    } as CSSProperties
};
