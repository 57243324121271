import * as Sentry from '@sentry/react';
import _ from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Api } from 'src/api';
import { NEW_BOT_EDITOR_PATH } from 'src/App';
import { fetchCurrentBot } from 'src/redux/bot/action';
import {
    getBot,
    getBotIsPrivate,
    getBotName,
    getBotWillForceBuyOnNextRun,
    getBuyQuantityMaximized,
    getBuyTriggers,
    getCooldownMins,
    getInitialBuyingPower,
    getOfficialBuyQuantity,
    getOfficialSellQuantity,
    getPerformanceInterval,
    getRealMoneyEnabled,
    getSellQuantityMaximized,
    getSellTriggers,
    getTicker,
    useBotCurrentDecisionType
} from 'src/redux/bot/selector';
import { useIsVisitorUsingAccessCode } from 'src/screens/botWorkspace/lib';
import { Colors } from 'src/utils/colors';
import { getErrorMessage } from 'src/utils/helpers';
import { analytics, SegmentEvents } from 'src/utils/segmentClient';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { DecisionEnum } from 'trellis-types';

import { processTriggerBlocks, validateTriggerBlocks } from '../lib';

type Props = {
    botId?: string;
};

export const SaveBotButton = ({ botId }: Props) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState<string>('');

    const bot = useSelector(getBot);
    const history = useHistory();
    const botName = useSelector(getBotName);
    const ticker = useSelector(getTicker);
    const performanceInterval = useSelector(getPerformanceInterval);
    const officialBuyQuantity = useSelector(getOfficialBuyQuantity);
    const officialSellQuantity = useSelector(getOfficialSellQuantity);
    const buyQuantityMaximized = useSelector(getBuyQuantityMaximized);
    const sellQuantityMaximized = useSelector(getSellQuantityMaximized);
    const buyTriggers = useSelector(getBuyTriggers);
    const sellTriggers = useSelector(getSellTriggers);
    const initialBuyingPower = useSelector(getInitialBuyingPower);
    const cooldownMins = useSelector(getCooldownMins);
    const realMoneyEnabled = useSelector(getRealMoneyEnabled);
    const forceBuyOnNextRun = useSelector(getBotWillForceBuyOnNextRun);
    const isPrivate = useSelector(getBotIsPrivate);
    const actionType = useBotCurrentDecisionType();
    const { isVisitorUsingAccessCode } = useIsVisitorUsingAccessCode();

    useEffect(() => {
        if (isLoading) {
            setButtonText('Please Wait...');
        } else if (!bot && actionType === DecisionEnum.Buy) {
            buyTriggers.length > 0
                ? setButtonText('Continue')
                : setButtonText('Skip');
        } else if (bot) {
            setButtonText('Redeploy Bot');
        } else {
            setButtonText('Deploy Bot');
        }
    }, [isLoading, bot?._id, buyTriggers.length, actionType]);

    const showSuccessAlert = () =>
        Swal.fire({
            title: 'Deployed Bot',
            text: 'Your bot has been saved and deployed.',
            icon: 'success'
        });

    const handleSaveBot = async () => {
        try {
            setIsLoading(true);
            if (!bot && actionType === DecisionEnum.Buy) {
                validateTriggerBlocks(buyTriggers);
            } else {
                validateTriggerBlocks(buyTriggers.concat(sellTriggers));
            }
            const triggerBlockMatrix = processTriggerBlocks(
                buyTriggers,
                sellTriggers
            );
            const botBody = {
                ticker,
                name: botName,
                initialBuyingPower,
                buyQuantity: officialBuyQuantity,
                sellQuantity: officialSellQuantity,
                buyQuantityMaximized,
                sellQuantityMaximized,
                triggerMatrixMap: triggerBlockMatrix,
                realMoneyEnabled: realMoneyEnabled,
                cooldownMins,
                forceBuyOnNextRun,
                isPrivate
            };
            if (botId && botId !== NEW_BOT_EDITOR_PATH) {
                // update existing bot
                await Api.bots.updateBot(botId, {
                    ...botBody,
                    // @ts-ignore
                    initialBuyingPower: undefined,
                    isPrivate: undefined
                });

                // refetch the current bot so it recognizes that there are 0
                // unsaved trigger changes
                await fetchCurrentBot({
                    dispatch,
                    botId,
                    performanceInterval
                });
                showSuccessAlert();
            } else if (botId === NEW_BOT_EDITOR_PATH) {
                // create new bot
                if (actionType === 'SELL') {
                    let alertRes: SweetAlertResult<any> | undefined;
                    if (botBody.realMoneyEnabled) {
                        alertRes = await Swal.fire({
                            title: 'Please Decide',
                            text: 'Do you want your bot to automatically place trades for you or should your bot ask you for permission via text messages anytime it wants to place a trade (in this case you must text back to approve each trade)?',
                            confirmButtonText: 'Trade Automatically',
                            denyButtonText: 'Ask Permission Everytime',
                            showDenyButton: true,
                            allowOutsideClick: false
                        });
                    }
                    const executesRealMoneyTradesAutomatically =
                        alertRes?.isConfirmed;
                    botBody['executesRealMoneyTradesAutomatically'] =
                        executesRealMoneyTradesAutomatically;
                    const botRes = await Api.bots.createBot(botBody);
                    const { bot } = botRes.data;
                    analytics.track(SegmentEvents.CreatedBot, {
                        ticker: botBody.ticker,
                        name: botBody.name,
                        initial_buying_power: botBody.initialBuyingPower,
                        buy_quantity: botBody.buyQuantity,
                        sell_quantity: botBody.sellQuantity,
                        buy_trigger_count: buyTriggers.length,
                        sell_trigger_count: sellTriggers.length,
                        real_money_enabled: botBody.realMoneyEnabled,
                        trades_automatically:
                            executesRealMoneyTradesAutomatically,
                        force_buy_on_next_run: forceBuyOnNextRun
                    });
                    await fetchCurrentBot({
                        dispatch,
                        botId: bot._id,
                        performanceInterval
                    });
                    if (
                        bot &&
                        (!realMoneyEnabled ||
                            executesRealMoneyTradesAutomatically)
                    ) {
                        history.push(
                            `/bot-workspace/${bot._id}/integrations?wasNewBot=true`
                        );
                        showSuccessAlert();
                    }
                } else if (actionType === DecisionEnum.Buy) {
                    history.push(
                        `/bot-workspace/${NEW_BOT_EDITOR_PATH}/sell-triggers`
                    );
                }
            }
        } catch (e) {
            Sentry.captureException(e);
            Swal.fire({
                title: 'Sorry',
                text: getErrorMessage(e),
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    return isVisitorUsingAccessCode ? null : (
        <button
            onClick={handleSaveBot}
            disabled={isLoading}
            style={{
                ...styles.container,
                ...(isLoading && { opacity: 0.3 })
            }}
        >
            {buttonText}
        </button>
    );
};

const styles = {
    container: {
        width: 260,
        maxWidth: 600,
        marginTop: 20,
        border: 'none',
        outline: 'none',
        backgroundColor: Colors.purple,
        borderRadius: 13,
        height: 40,
        color: Colors.white,
        fontFamily: 'Manrope',
        fontSize: 18,
        fontWeight: 700,
        cursor: 'pointer',
        boxShadow: '0px 0px 14px rgba(0,0,0,0.2)'
    } as CSSProperties
};
