import * as Sentry from '@sentry/react';
import React, { CSSProperties, useState, useEffect } from 'react';
import { LeaderboardRow } from './components/LeaderboardRow';
import { Colors } from 'src/utils/colors';
import { getLeaderboardBots, getMyRankedBots } from 'src/redux/bot/selector';
import { getAuthStatus } from 'src/redux/auth/selector';
import { fetchLeaderboardBots, fetchMyRankedBots } from 'src/redux/bot/action';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { TopBotCell } from 'src/screens/myBots/components/TopBotCell';
import { getUser } from 'src/redux/user/selector';

export const Leaderboard = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const styles = responsiveStyles(window.innerHeight);
    const myRankedBots = useSelector(getMyRankedBots);
    const leaderboardBots = useSelector(getLeaderboardBots);
    const authStatus = useSelector(getAuthStatus);

    useEffect(() => {
        if (user && authStatus === 'SIGNED_IN') {
            handleFetchMyRankedBots();
            handleFetchLeaderboardBots();
        }
    }, [authStatus, user]);

    const handleFetchLeaderboardBots = async () => {
        try {
            setIsLoading(true);
            await fetchLeaderboardBots(dispatch);
        } catch (e) {
            Sentry.captureException(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFetchMyRankedBots = async () => {
        try {
            setIsLoading(true);
            await fetchMyRankedBots(dispatch, user?._id);
        } catch (e) {
            Sentry.captureException(e);
        } finally {
            setIsLoading(false);
        }
    };

    const LeaderboardTable = () => (
        <div
            style={{
                ...styles.botsTableContainer,
                ...(!leaderboardBots.length && { minHeight: 100 })
            }}
        >
            <div style={styles.botTableInnerContainer}>
                {leaderboardBots.map((bot, i) => (
                    <LeaderboardRow
                        bot={bot}
                        isLastInList={i === leaderboardBots.length - 1}
                        key={bot._id}
                        rank={i + 1}
                    />
                ))}
            </div>
        </div>
    );

    const MyRankedBotsTable = () => (
        <div style={styles.topBotsTable} className="hide-scrollbar">
            <div style={styles.topBotsInnerDiv}>
                {myRankedBots.map(bot => (
                    <TopBotCell
                        bot={bot}
                        ranking={_.get(bot, 'rank', -1)}
                        key={bot._id}
                    />
                ))}
            </div>
        </div>
    );

    return (
        <div style={styles.container}>
            {myRankedBots.length ? (
                <div
                    style={{
                        ...styles.headerContainer,
                        ...styles.indentedHeader
                    }}
                >
                    <label style={styles.title}>My Ranked Bots</label>
                </div>
            ) : null}
            <MyRankedBotsTable />
            <div style={styles.sectionContainer}>
                <div style={styles.headerContainer}>
                    <div style={styles.leftSideHeader}>
                        <label style={styles.title}>Top 50 Bots</label>
                    </div>
                </div>
                {isLoading ? (
                    <label style={styles.loading}>Loading...</label>
                ) : (
                    <LeaderboardTable />
                )}
            </div>
        </div>
    );
};

const responsiveStyles = (_windowHeight: number) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowX: 'hidden',
        flex: 1,
        paddingBottom: 140,
        minHeight: window.innerHeight
    } as CSSProperties,
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20
    } as CSSProperties,
    sectionContainer: {
        ...(isMobile
            ? {
                  paddingLeft: 40,
                  paddingRight: 40
              }
            : {
                  paddingLeft: 80,
                  paddingRight: 80
              })
    },
    headerContainer: {
        display: 'flex',
        marginTop: 40,
        ...(isMobile
            ? {
                  flexDirection: 'column',
                  alignItems: 'center'
              }
            : {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
              })
    } as CSSProperties,
    indentedHeader: {
        ...(isMobile
            ? {
                  paddingLeft: 40,
                  paddingRight: 40
              }
            : {
                  paddingLeft: 80,
                  paddingRight: 80
              }),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    } as CSSProperties,
    leftSideHeader: {
        display: 'flex',
        flexDirection: 'column',
        ...(isMobile && { alignItems: 'center' })
    } as CSSProperties,
    botsTableContainer: {
        marginTop: 20,
        boxShadow: '0px 0px 25px rgba(0,0,0,0.1)',
        backgroundColor: Colors.white,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontSize: 32,
        fontWeight: 800,
        fontFamily: 'Manrope',
        ...(isMobile && { marginTop: 20, textAlign: 'center', width: '100%' }),
        color: Colors.tundora,
        zIndex: 10
    } as CSSProperties,
    loading: {
        marginTop: 25,
        fontSize: 28,
        fontWeight: 600,
        color: Colors.lightGray5,
        zIndex: 10
    },
    botTableInnerContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    } as CSSProperties,
    topBotsTable: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        padding: 40,
        marginBottom: -40,
        ...(!isMobile && {
            paddingLeft: 80
        })
    } as CSSProperties,
    topBotsInnerDiv: {
        display: 'flex',
        flexDirection: 'row'
    } as CSSProperties
});
