import React, { CSSProperties } from 'react';
import Modal from 'react-modal';
import { Colors } from 'src/utils/colors';
import { Styles } from 'src/utils/styles';
import { StarterPack } from '../lib';
import YoutubeEmbedVideo from 'youtube-embed-video';

type Props = {
    pack: StarterPack | null;
    isModalVisible: boolean;
    closeModal: () => void;
    utilizePack: () => void;
};

export const PackModal = ({
    isModalVisible,
    closeModal,
    pack,
    utilizePack
}: Props) =>
    pack ? (
        <Modal
            isOpen={isModalVisible}
            onRequestClose={closeModal}
            style={Styles.modal}
            ariaHideApp={false}
            shouldCloseOnOverlayClick
        >
            <div>
                <div style={Styles.modalHeader}>
                    <button onClick={closeModal} style={Styles.modalCloseBtn}>
                        Close
                    </button>
                    <div style={Styles.modalInnerContainer}>
                        <label style={{ ...Styles.modalTitle, fontSize: 18 }}>
                            {pack.name}
                        </label>
                    </div>
                    <button onClick={utilizePack} style={styles.useThisPackBtn}>
                        Use Template
                    </button>
                </div>
                <div style={styles.outerContentContainer}>
                    <div style={styles.contentContainer}>
                        <label style={Styles.modalSubheaderText}>
                            Explanation
                        </label>
                        <label style={Styles.modalDescriptionText}>
                            {pack.longDescription}
                        </label>
                    </div>
                    <div style={styles.youtubeContainer}>
                        <YoutubeEmbedVideo
                            videoId={pack.youtubeVideoId}
                            suggestions={false}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    ) : null;

const styles = {
    outerContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    } as CSSProperties,
    contentContainer: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingBottom: 60,
        textAlign: 'left',
        marginTop: 100,
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    youtubeContainer: {
        paddingBottom: 50
    },
    useThisPackBtn: {
        position: 'absolute',
        top: 22,
        right: 20,
        border: 'none',
        outline: 'none',
        backgroundColor: Colors.purple,
        fontFamily: 'Manrope',
        fontWeight: 600,
        color: Colors.white,
        borderRadius: 15,
        cursor: 'pointer',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10
    } as CSSProperties
};
