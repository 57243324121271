import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authStateEmailVerificationCheck } from 'src/screens/onboarding/VerifyEmail/lib';

import firebase from '../firebaseConfig';
import { setAuthStatus } from '../redux/auth/action';
import { fetchUser, setUser } from '../redux/user/action';
import { analytics } from '../utils/segmentClient';
import { getLastPathWithSlash } from './helpers';
import qs from 'qs';

// routes where we choose to redirect the user to the home page if the user auth
// state changes from them being signed out to being signed in.
const LOGGED_OUT_ROUTES = ['/sign-in', '/sign-up', '/my-bots', '/'];

// routes where logged out users can view it and will not be redirected anywhere
const UNKNOWN_USER_ROUTES = ['/sign-in', '/sign-up', '/alpaca-description'];

export const SetupApp = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const path = getLastPathWithSlash(history.location.pathname);
    const { accessCode } = qs.parse(history.location.search.slice(1));

    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            fetchUser(dispatch);
            dispatch(setAuthStatus('SIGNED_IN'));
            analytics.identify(user.uid, { email: user.email });
            if (user.emailVerified && LOGGED_OUT_ROUTES.includes(path)) {
                history.push('/my-bots');
            } else if (!user.emailVerified) {
                authStateEmailVerificationCheck(user);
                history.push('/verify-email');
            }
            return;
        } else if (!accessCode && !UNKNOWN_USER_ROUTES.includes(path)) {
            dispatch(setUser(null));
            dispatch(setAuthStatus('NOT_SIGNED_IN'));
            history.push('/sign-up');
        } else {
            dispatch(setUser(null));
            dispatch(setAuthStatus('NOT_SIGNED_IN'));
        }
    });
    return null;
};
