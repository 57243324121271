import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getBotName,
    getInitialBuyingPower,
    getTicker
} from 'src/redux/bot/selector';

// takes the user back to step 1 of the setup flow
// if they tried to skip something that was required

type ScreenType = 'SETUP_FORM' | 'PACKS';
const RESET_TO_PATH = '/setup-bot/stocks';

export const useResetSetupFlow = (screenType: ScreenType) => {
    const history = useHistory();
    const botName = useSelector(getBotName);
    const botInitialBuyingPower = useSelector(getInitialBuyingPower);
    const ticker = useSelector(getTicker);

    useEffect(() => {
        if (screenType === 'SETUP_FORM') {
            if (!ticker) {
                history.push(RESET_TO_PATH);
            }
        } else if (screenType === 'PACKS') {
            if (
                !ticker ||
                !botName ||
                (typeof botInitialBuyingPower === 'string' &&
                    !botInitialBuyingPower)
            ) {
                history.push(RESET_TO_PATH);
            }
        }
    }, [ticker, botName, botInitialBuyingPower]);
};
