import { prop } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { createSelector } from 'reselect';
import { getLastPath } from 'src/utils/helpers';
import { BotDocument, DecisionEnum, FrontendTrigger } from 'trellis-types';

import { getBotReducer } from '../selectorIndex';

export const getMyBots = createSelector(
    [getBotReducer],
    botReducer => botReducer.myBots
);

export const getMyRankedBots = createSelector(
    [getBotReducer],
    botReducer => botReducer.myRankedBots
);

export const getBot = createSelector(
    [getBotReducer],
    botReducer => botReducer.bot
);

export const getBotId = createSelector([getBot], prop('_id'));

export const getActiveIntegrations = createSelector(
    [getBotReducer],
    botReducer => botReducer.integrations || []
);

export const getBuyTriggers = createSelector(
    [getBotReducer],
    botReducer => botReducer.buyTriggers
);

export const getSellTriggers = createSelector(
    [getBotReducer],
    botReducer => botReducer.sellTriggers
);

export const getTicker = createSelector(
    [getBotReducer],
    botReducer => botReducer.ticker
);

export const getBotName = createSelector(
    [getBotReducer],
    botReducer => botReducer.name
);

export const getIndicator = createSelector(
    [getBotReducer],
    botReducer => botReducer.indicator
);

export const getBuyQuantity = createSelector(
    [getBotReducer],
    botReducer => botReducer.buyQuantity
);

export const getSellQuantity = createSelector(
    [getBotReducer],
    botReducer => botReducer.sellQuantity
);

export const getBuyQuantityMaximized = createSelector(
    [getBotReducer],
    botReducer => botReducer.buyQuantityMaximized
);

export const getSellQuantityMaximized = createSelector(
    [getBotReducer],
    botReducer => botReducer.sellQuantityMaximized
);

export const getInitialBuyingPower = createSelector(
    [getBotReducer],
    botReducer => botReducer.initialBuyingPower
);

export const getCooldownMins = createSelector(
    [getBotReducer],
    botReducer => botReducer.cooldownMins
);

export const getRealMoneyEnabled = createSelector(
    [getBotReducer],
    botReducer => botReducer.realMoneyEnabled
);

export const getLeaderboardBots = createSelector(
    [getBotReducer],
    botReducer => botReducer.leaderboardBots
);

export const getMarketIsForcefullyClosed = createSelector(
    [getBotReducer],
    botReducer => botReducer.isMarketForcefullyClosed
);

export const getPerformanceInterval = createSelector(
    [getBotReducer],
    botReducer => botReducer.performanceInterval
);

export const getBotWillForceBuyOnNextRun = createSelector(
    [getBotReducer],
    botReducer => botReducer.forceBuyOnNextRun
);

export const getBotIsPrivate = createSelector(
    [getBotReducer],
    botReducer => botReducer.isPrivate
);

export const useQuantity = () => {
    const decisionType = useBotCurrentDecisionType();
    const buyQuantity = useSelector(getBuyQuantity);
    const sellQuantity = useSelector(getSellQuantity);
    return decisionType === DecisionEnum.Buy ? buyQuantity : sellQuantity;
};

export const useQuantityMaximized = () => {
    const decisionType = useBotCurrentDecisionType();
    const buyQuantityMaximized = useSelector(getBuyQuantityMaximized);
    const sellQuantityMaximized = useSelector(getSellQuantityMaximized);
    return decisionType === DecisionEnum.Buy
        ? buyQuantityMaximized
        : sellQuantityMaximized;
};

export const useTriggerBlocks = () => {
    const decisionType = useBotCurrentDecisionType();
    const buyTriggers = useSelector(getBuyTriggers);
    const sellTriggers = useSelector(getSellTriggers);
    return decisionType === DecisionEnum.Buy ? buyTriggers : sellTriggers;
};

export const getIsRealMoneyModalVisible = createSelector([getBot], bot =>
    Boolean(bot?.realMoneyEnabled && !bot.alpacaIntegrationComplete)
);

// returns an api-compatible buy quantity
export const getOfficialBuyQuantity = createSelector(
    [getBuyTriggers, getBuyQuantityMaximized, getBuyQuantity],
    (buyTriggers, buyQuantityMaximized, buyQuantity) => {
        if (buyTriggers.length) {
            return buyQuantityMaximized ? 0 : buyQuantity;
        }
        return 0;
    }
);

// returns an api-compatible sell quantity
export const getOfficialSellQuantity = createSelector(
    [getSellTriggers, getSellQuantityMaximized, getSellQuantity],
    (sellTriggers, sellQuantityMaximized, sellQuantity) => {
        if (sellTriggers.length) {
            return sellQuantityMaximized ? 0 : sellQuantity;
        }
        return 0;
    }
);

export const useBotCurrentDecisionType = (): DecisionEnum => {
    const location = useLocation();
    const path = getLastPath(location.pathname);
    return path === 'buy-triggers' ? DecisionEnum.Buy : DecisionEnum.Sell;
};

// THIS CURRENTLY ALWAYS RETURNS FALSE BECAUSE THERE IS A BUG
// the bug can be reproduced by uncommenting everything below,
// then go to create a bot with > 1 buy trigger and/or > 1 sell trigger.
// when you adjust the operator or adjust some picker value on one of the triggers
// you'll see it also adjusts the value on the other triggers to match that.
// i think this might have something to do with below here we're modifying a
// reference to the triggers instead of a copy of the triggers.
export const getBotHasUnsavedChanges = createSelector(
    [
        getBot,
        getBuyTriggers,
        getSellTriggers,
        getBuyQuantity,
        getSellQuantity,
        getBuyQuantityMaximized,
        getSellQuantityMaximized
    ],
    (
        bot: BotDocument | null,
        buyTriggers: FrontendTrigger[],
        sellTriggers: FrontendTrigger[],
        buyQuantity,
        sellQuantity,
        buyQuantityMaximized,
        sellQuantityMaximized
    ) => {
        return false;
        // const {
        //     frontendBuyTriggers: oldBuyTriggers,
        //     frontendSellTriggers: oldSellTriggers
        // } = convertBackendTriggersToFrontendTriggers(
        //     bot?.triggers || [],
        //     bot?.triggerGroups || []
        // );

        // oldBuyTriggers.forEach(t => {
        //     // @ts-ignore
        //     delete t.blockId;
        // });

        // buyTriggers.forEach(t => {
        //     // @ts-ignore
        //     delete t.blockId;
        // });

        // oldSellTriggers.forEach(t => {
        //     // @ts-ignore
        //     delete t.blockId;
        // });

        // sellTriggers.forEach(t => {
        //     // @ts-ignore
        //     delete t.blockId;
        // });

        // return (
        //     bot?._id &&
        //     (JSON.stringify(oldBuyTriggers) !== JSON.stringify(buyTriggers) ||
        //         JSON.stringify(oldSellTriggers) !==
        //             JSON.stringify(sellTriggers) ||
        //         buyQuantity !== bot?.buyQuantity ||
        //         sellQuantity !== bot.sellQuantity ||
        //         buyQuantityMaximized !== bot.buyQuantityMaximized ||
        //         sellQuantityMaximized !== bot.sellQuantityMaximized)
        // );
    }
);
