import React, { CSSProperties, useEffect } from 'react';

import { Colors } from '../../../utils/colors';
import { useIsWindowSkinny } from '../../../utils/windowHooks';
import { LargeGraphic } from './components/LargeGraphic';
import { SignInForm } from './components/SignInForm';

export const SignIn = () => {
    const isWindowSkinny = useIsWindowSkinny();
    return (
        <div
            style={
                isWindowSkinny
                    ? styles.containerMobile
                    : styles.containerDesktop
            }
        >
            {isWindowSkinny ? null : <LargeGraphic />}
            <SignInForm />
        </div>
    );
};

const styles = {
    containerMobile: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    } as CSSProperties,
    containerDesktop: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: Colors.white
    } as CSSProperties
};
