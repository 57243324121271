import * as Sentry from '@sentry/react';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Api } from 'src/api';
import firebase from 'src/firebaseConfig';
import { useIsWindowSkinny } from 'src/utils/windowHooks';

import ValidationError from 'src/sharedComponents/ValidationError';
import { responsiveStyles } from './styles';
import { signUpValidationSchema } from './validator';
import { SegmentEvents, analytics } from 'src/utils/segmentClient';
import { Constants } from 'src/utils/constants';
import { getErrorMessage } from 'src/utils/helpers';

const INITIAL_FORM_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmedPassword: ''
};

export const SignUpForm = () => {
    const isWindowSkinny = useIsWindowSkinny();
    const styles = responsiveStyles(isWindowSkinny);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSignUp = async (formVals: any) => {
        try {
            setErrorMessage('');
            setIsLoading(true);
            const { firstName, lastName, email, password } = formVals;
            await Api.users.createUser({
                firstName,
                lastName,
                email,
                password
            });
            analytics.track(SegmentEvents.SignedUp);
            await firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (e) {
            Sentry.captureException(e);
            setErrorMessage(getErrorMessage(e));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={styles.container}>
            <label style={styles.signInTitle}>Sign up for Trellis</label>
            <Link to="/sign-in" style={styles.signInInsteadButton}>
                Already have an account? Click here to sign in.
            </Link>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={INITIAL_FORM_VALUES}
                validationSchema={signUpValidationSchema}
                onSubmit={handleSignUp}
            >
                {({ errors }) => (
                    <Form>
                        <div
                            style={
                                isWindowSkinny
                                    ? styles.nameSectionMobile
                                    : styles.nameSectionDesktop
                            }
                        >
                            {/* First name input */}
                            <div style={styles.inputSection}>
                                <label style={styles.inputDesc}>
                                    First Name
                                </label>
                                <Field
                                    style={styles.inputTextfield}
                                    name="firstName"
                                    placeholder="John"
                                />
                                <ValidationError message={errors.firstName} />
                            </div>
                            {/* Last name input */}
                            <div
                                style={{
                                    ...styles.inputSection,
                                    ...(!isWindowSkinny && {
                                        marginLeft: 15
                                    })
                                }}
                            >
                                <label style={styles.inputDesc}>
                                    Last Name
                                </label>
                                <Field
                                    style={styles.inputTextfield}
                                    name="lastName"
                                    placeholder="Doe"
                                />
                                <ValidationError message={errors.lastName} />
                            </div>
                        </div>
                        {/* Email input */}
                        <div style={styles.inputSection}>
                            <label style={styles.inputDesc}>Email</label>
                            <Field
                                style={styles.inputTextfield}
                                name="email"
                                placeholder="johndoe@email.com"
                                type="email"
                            />
                            <ValidationError message={errors.email} />
                        </div>
                        {/* Password input */}
                        <div style={styles.inputSection}>
                            <label style={styles.inputDesc}>Password</label>
                            <Field
                                style={styles.inputTextfield}
                                name="password"
                                placeholder="Top secret"
                                type="password"
                            />
                            <ValidationError message={errors.password} />
                        </div>
                        {/* Confirm password input */}
                        <div style={styles.inputSection}>
                            <label style={styles.inputDesc}>
                                Confirm Password
                            </label>
                            <Field
                                style={styles.inputTextfield}
                                name="confirmedPassword"
                                placeholder="Same secret as above"
                                type="password"
                            />
                            <ValidationError
                                message={errors.confirmedPassword}
                            />
                        </div>
                        <div style={styles.submitSection}>
                            {/* Submission Error Message */}
                            {errorMessage && (
                                <label style={styles.errorMessage}>
                                    {errorMessage}
                                </label>
                            )}
                            {/* Sign up button */}
                            <button
                                style={{
                                    ...styles.signUpButton,
                                    ...(isLoading && { opacity: 0.3 })
                                }}
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Signing Up...' : 'Sign Up'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            <label style={styles.termsAndConditionsText}>
                By signing up, you agree to our{' '}
                <span>
                    <a
                        href={Constants.TERMS_AND_CONDITIONS_LINK}
                        style={{
                            ...styles.termsAndConditionsText,
                            textDecoration: 'underline'
                        }}
                    >
                        terms and conditions
                    </a>
                </span>
            </label>
        </div>
    );
};
