import { useWindowHeight } from '@react-hook/window-size';
import * as Sentry from '@sentry/react';
import { Tooltip } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Api } from 'src/api';
import { getAuthStatus } from 'src/redux/auth/selector';
import {
    fetchMyBots,
    setBotName,
    setCooldownMins,
    setInitialBuyingPower,
    setRealMoneyEnabled,
    setIsBotPrivate
} from 'src/redux/bot/action';
import {
    getBotIsPrivate,
    getBotName,
    getCooldownMins,
    getInitialBuyingPower,
    getMyBots,
    getRealMoneyEnabled,
    getTicker
} from 'src/redux/bot/selector';
import { getUser } from 'src/redux/user/selector';
import { openTradeWithRealMoneyAlert } from 'src/screens/myBots/lib';
import { useResetSetupFlow } from 'src/screens/newBotSetup/lib';
import ValidationError from 'src/sharedComponents/ValidationError';
import { Colors } from 'src/utils/colors';
import { Constants } from 'src/utils/constants';
import Swal from 'sweetalert2';
import { NON_REAL_MONEY_TICKERS, PlanEnum, Ticker } from 'trellis-types';

import { responsiveStyles } from './styles';
import { setupBotValidationSchema } from './validator';

export const SetupForm = () => {
    useResetSetupFlow('SETUP_FORM');
    const windowHeight = useWindowHeight();
    const styles = responsiveStyles(windowHeight);

    const history = useHistory();
    const dispatch = useDispatch();

    const botName = useSelector(getBotName);
    const initialBuyingPower = useSelector(getInitialBuyingPower);
    const cooldownMins = useSelector(getCooldownMins);
    const realMoneyEnabled = useSelector(getRealMoneyEnabled);
    const isPrivate = useSelector(getBotIsPrivate);
    const ticker = useSelector(getTicker);
    const myBots = useSelector(getMyBots);

    const user = useSelector(getUser);
    const authStatus = useSelector(getAuthStatus);

    const INITIAL_FORM_VALUES = {
        botName,
        initialBuyingPower,
        cooldownMins,
        realMoneyEnabled,
        isPrivate
    };

    useEffect(() => {
        if (authStatus === 'SIGNED_IN') {
            fetchMyBots(dispatch);
        }
    }, [authStatus]);

    // this hook exists to prevent a user from entering the packs screen
    // unless they have already set a botName
    useEffect(() => {
        if (botName) {
            history.push('/setup-bot/packs');
        }
    }, [botName]);

    const handleSubmit = async (formVals: any) => {
        const {
            realMoneyEnabled,
            initialBuyingPower,
            botName,
            cooldownMins,
            isPrivate
        } = formVals;

        if (
            myBots.find(bot => bot.name.toLowerCase() === botName.toLowerCase())
        ) {
            return Swal.fire({
                title: 'Sorry',
                text: 'You cannot have more than one bot with the same name.',
                icon: 'error'
            });
        }

        if (
            realMoneyEnabled &&
            NON_REAL_MONEY_TICKERS.includes(ticker as Ticker)
        ) {
            return Swal.fire({
                title: 'Sorry',
                text: `We currently do not support real-money trading for ${ticker}.`,
                icon: 'error'
            });
        }

        // we assume they already have an account so we default to false
        let willSetupAlpaca: boolean = false;

        if (realMoneyEnabled && !user?.encryptedAlpacaToken) {
            willSetupAlpaca = await openTradeWithRealMoneyAlert('BOT_SETUP');
        }

        if (realMoneyEnabled && user?.encryptedAlpacaToken) {
            // if real money is enabled and they've set up their alpaca account,
            // check to see if they have sufficient real money buying power for this bot.
            try {
                const { hasEnoughAlpacaBuyingPower, buyingPowerRemaining } = (
                    await Api.alpaca.isEnoughAlpacaBuyingPower(
                        initialBuyingPower
                    )
                ).data;

                if (!hasEnoughAlpacaBuyingPower) {
                    return Swal.fire({
                        title: 'Sorry',
                        text: `You don't have enough money in Alpaca to allocate to this bot. ${
                            Number(buyingPowerRemaining.toFixed(2))
                                ? `The maximum amount you can allocate to this bot is $${buyingPowerRemaining.toFixed(
                                      2
                                  )}.`
                                : 'Please add more money to your Alpaca account to resolve this.'
                        }`,
                        icon: 'error'
                    });
                }
            } catch (e) {
                Sentry.captureException(e);
                return Swal.fire({
                    title: 'Sorry',
                    text: `Something went wrong.`,
                    icon: 'error'
                });
            }
        }

        // if they continued with alpaca setup, they'll eventually get redirected to dashboard
        // and we'll lose the redux data anyway, so only continue with bot creation if they
        // don't setup alpaca.
        if (
            realMoneyEnabled &&
            !user?.encryptedAlpacaToken &&
            !willSetupAlpaca
        ) {
            Swal.fire({
                title: 'Using Fake Money',
                text: "We'll use fake money for this bot.",
                icon: 'info'
            });
            // they decided to not setup alpaca, revert to using fake money
            dispatch(setInitialBuyingPower(initialBuyingPower));
            dispatch(setCooldownMins(cooldownMins));
            dispatch(setRealMoneyEnabled(false));
            dispatch(setBotName(botName));
            dispatch(setIsBotPrivate(isPrivate));
        } else if (!willSetupAlpaca) {
            // they already have an alpaca account or they want to use fake money
            // so proceed normally
            dispatch(setInitialBuyingPower(initialBuyingPower));
            dispatch(setCooldownMins(cooldownMins));
            dispatch(setRealMoneyEnabled(realMoneyEnabled));
            dispatch(setBotName(botName));
            dispatch(setIsBotPrivate(isPrivate));
        }
    };

    return (
        <div style={styles.outerContainer}>
            <label style={styles.largerTitle}>Now, customize your bot.</label>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={INITIAL_FORM_VALUES}
                onSubmit={handleSubmit}
                validationSchema={setupBotValidationSchema}
            >
                {({ errors, values }) => (
                    <Form style={styles.formSection}>
                        <div style={styles.inputSection}>
                            <label style={styles.inputDesc}>
                                Name your bot
                            </label>
                            <Field
                                name="botName"
                                placeholder="Moneymaker"
                                style={styles.inputTextfield}
                            />
                            <ValidationError message={errors.botName} />
                        </div>
                        <label style={styles.inputDesc}>
                            Give your bot money to trade with ($)
                        </label>
                        <div style={styles.inputSection}>
                            <Field
                                name="initialBuyingPower"
                                placeholder="1000"
                                style={styles.inputTextfield}
                                type="number"
                            />
                            <ValidationError
                                message={errors.initialBuyingPower}
                            />
                        </div>
                        <Tooltip title="Your bot will not run for at least X minutes after it makes a trade. For example, if this value is 10,080 minutes, your bot is limited to making 1 trade every 7 days.">
                            <label style={styles.inputDesc}>
                                Minimum time between executed trades (minutes)
                            </label>
                        </Tooltip>
                        <div style={styles.inputSection}>
                            <Field
                                name="cooldownMins"
                                placeholder="0"
                                style={styles.inputTextfield}
                                type="number"
                            />
                            <ValidationError message={errors.cooldownMins} />
                        </div>
                        <div style={styles.checkBoxContainer}>
                            <div>
                                <Field
                                    type="checkbox"
                                    name="realMoneyEnabled"
                                    style={styles.checkBox}
                                />
                                <label style={styles.inputDesc}>
                                    Trade with real money
                                </label>
                            </div>
                            {values.realMoneyEnabled ? (
                                <label style={styles.termsAndConditionLabel}>
                                    By proceeding, you agree to our{' '}
                                    <a
                                        href={
                                            Constants.TERMS_AND_CONDITIONS_LINK
                                        }
                                        style={styles.termsAndConditionsLink}
                                    >
                                        terms and conditions.
                                    </a>
                                </label>
                            ) : (
                                <label style={styles.defaultBehaviorText}>
                                    Default behavior is to trade with fake
                                    money.
                                </label>
                            )}
                        </div>
                        <div style={styles.checkBoxContainer}>
                            <div>
                                <Field
                                    type="checkbox"
                                    name="isPrivate"
                                    style={styles.checkBox}
                                    disabled={
                                        user?.currentPlan === PlanEnum.Free
                                    }
                                />
                                <label style={styles.inputDesc}>
                                    Bot strategy can only be seen by you
                                    {user?.currentPlan === PlanEnum.Free
                                        ? ' (premium feature)'
                                        : ''}
                                </label>
                            </div>
                        </div>
                        <button style={styles.nextStepButton} type="submit">
                            {values.realMoneyEnabled &&
                            !user?.encryptedAlpacaToken
                                ? 'Connect to Brokerage'
                                : 'Continue Setup'}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
