import React, { CSSProperties, useState } from 'react';
import Modal from 'react-modal';
import { Colors } from 'src/utils/colors';
import { Styles } from 'src/utils/styles';
import { RealMoneyTwilioModal } from './components/RealMoneyTwilioModal';
import { RealMoneyExplanationModal } from './components/RealMoneyExplanationModal';
import { SegmentEvents } from 'src/utils/segmentClient';
import Swal from 'sweetalert2';
import { Api } from 'src/api';
import { useSelector } from 'react-redux';
import { getUser } from 'src/redux/user/selector';

type Props = {
    isVisible: boolean;
};

type ScreenType = 'EXPLANATION' | 'TWILIO';

export const RealMoneyModal = ({ isVisible }: Props) => {
    const user = useSelector(getUser);
    const [
        isTermsOfServiceAccepted,
        setIsTermsOfServiceAccepted
    ] = useState<boolean>(!!user?.acceptedTermsAndConditions);
    const [currentScreen, setCurrentScreen] = useState<ScreenType>(
        'EXPLANATION'
    );

    const renderBody = () => {
        switch (currentScreen) {
            case 'EXPLANATION':
                return (
                    <RealMoneyExplanationModal
                        isTermsOfServiceChecked={isTermsOfServiceAccepted}
                        onTermsOfServiceChange={setIsTermsOfServiceAccepted}
                    />
                );
            case 'TWILIO':
                return <RealMoneyTwilioModal />;
        }
    };

    const handleGoToAlpacaSetup = () => {
        if (isTermsOfServiceAccepted) {
            setCurrentScreen('TWILIO');
            Api.users.updateUser({ acceptedTermsAndConditions: true });
        } else {
            Swal.fire({
                title: 'Please Accept Terms of Service Before Starting',
                icon: 'info'
            });
        }
    };

    return (
        <Modal
            isOpen={isVisible}
            style={Styles.modal}
            ariaHideApp={false}
            shouldCloseOnOverlayClick
        >
            <div style={Styles.modalHeader}>
                {currentScreen === 'TWILIO' && (
                    <button
                        style={styles.backBtn}
                        onClick={() => setCurrentScreen('EXPLANATION')}
                    >
                        Back to Explanation
                    </button>
                )}
                <div style={Styles.modalInnerContainer}>
                    <label style={Styles.modalTitle}>
                        Trade Real Money With Your Bot
                    </label>
                </div>
                {currentScreen === 'EXPLANATION' && (
                    <button
                        style={styles.nextBtn}
                        onClick={handleGoToAlpacaSetup}
                    >
                        Start Setup
                    </button>
                )}
            </div>
            <div style={styles.innerBodyContainer}>{renderBody()}</div>
        </Modal>
    );
};

const styles = {
    innerBodyContainer: {
        marginTop: 100
    } as CSSProperties,
    nextBtn: {
        position: 'absolute',
        right: 10,
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.purple,
        color: Colors.white,
        fontFamily: 'Manrope',
        marginRight: 5,
        marginLeft: 5,
        minWidth: 110,
        borderRadius: 10,
        fontWeight: 600,
        cursor: 'pointer',
        padding: 12
    } as CSSProperties,
    backBtn: {
        outline: 'none',
        border: 'none',
        fontFamily: 'Manrope',
        position: 'absolute',
        fontWeight: 600,
        left: 10,
        backgroundColor: 'transparent',
        marginRight: 5,
        marginLeft: 5,
        cursor: 'pointer'
    } as CSSProperties
};
