import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Area,
    AreaChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { fetchCurrentBot } from 'src/redux/actionIndex';
import { getBot, getPerformanceInterval } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { getErrorMessage } from 'src/utils/helpers';
import Swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment-timezone';

import { IntervalToggle } from './IntervalToggle';
import { useIsVisitorUsingAccessCode } from 'src/screens/botWorkspace/lib';

export const BotValueChart = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const bot = useSelector(getBot);
    const performanceInterval = useSelector(getPerformanceInterval);
    const { accessCode } = useIsVisitorUsingAccessCode();
    const graphData = useMemo(
        () =>
            bot?.graphData?.map(data => ({
                ...data,
                date: moment(data.date).format('MM/DD h:mm A')
            })),
        [performanceInterval, isLoading, bot?._id]
    );

    const handleFetchBot = async () => {
        try {
            if (bot?._id) {
                setIsLoading(true);
                await fetchCurrentBot({
                    dispatch,
                    botId: bot._id,
                    performanceInterval,
                    accessCode: accessCode as string
                });
            }
        } catch (e) {
            Swal.fire({
                title: 'Oops',
                text: getErrorMessage(e),
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleFetchBot();
    }, [performanceInterval]);

    return bot?._id && graphData?.length ? (
        <div style={styles.container}>
            <IntervalToggle />
            <div style={styles.innerContainer}>
                {isLoading && <label style={styles.loading}>Loading...</label>}
                <ResponsiveContainer width="90%" height={350}>
                    <AreaChart data={graphData} margin={{ top: 50 }}>
                        <XAxis
                            dataKey="date"
                            style={{ fontWeight: 600 }}
                            dy={10}
                            color={Colors.black}
                            tick={true}
                        />
                        <YAxis
                            dataKey="dollars"
                            style={{ fontWeight: 600, padding: 10 }}
                            domain={[
                                (_.minBy(graphData as any, 'dollars') as any)
                                    ?.dollars -
                                    0.05 * (bot.currentBotValue || 0),
                                (_.maxBy(graphData as any, 'dollars') as any)
                                    ?.dollars +
                                    0.05 * (bot.currentBotValue || 0)
                            ]}
                            dx={-10}
                            hide
                        />
                        <Tooltip />
                        <Area
                            type="linear"
                            dataKey="dollars"
                            stroke={Colors.purple}
                            strokeWidth={3}
                            fill="transparent"
                            isAnimationActive={false}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    ) : null;
};

const styles = {
    container: {
        width: '100%',
        marginRight: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 20
    } as CSSProperties,
    loading: {
        fontSize: 24,
        fontWeight: 600,
        color: Colors.darkGray1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        backgroundColor: 'rgb(255,255,255,0.96)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    } as CSSProperties,
    innerContainer: {
        position: 'relative',
        width: '100%',
        height: 350,
        marginTop: 6
    } as CSSProperties
};
