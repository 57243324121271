import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTicker } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { NON_1D_TICKERS, Ticker } from 'trellis-types';

//https://stackoverflow.com/questions/61561795/tradingvew-chart-in-react-code-is-not-working
//https://stackoverflow.com/questions/42847126/script-load-in-react

const new_script = src => {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function (e) {
            resolve(e);
        });
        script.addEventListener('error', function (e) {
            reject(e);
        });
        document.body.appendChild(script);
    });
};
// Promise Interface can ensure load the script only once.
let my_script = new_script('https://s3.tradingview.com/tv.js');

export default function StockTickerChart() {
    const ticker = useSelector(getTicker);
    useEffect(() => {
        my_script.then(() => {
            const script = document.createElement('script');
            script.innerHTML = `new TradingView.MediumWidget(
                    {
                    "symbols": [
                      [
                        "${ticker}|${
                NON_1D_TICKERS.includes(ticker as Ticker) ? '3M' : '1D'
            }"
                      ]
                    ],
                    "chartOnly": false,
                    "width": 1000,
                    "height": 400,
                    "locale": "uk",
                    "colorTheme": "light",
                    "gridLineColor": "rgba(240, 243, 250, 0)",
                    "trendLineColor": "${Colors.purple}",
                    "fontColor": "#787B86",
                    "underLineColor": "rgba(217, 210, 233, 0.3)",
                    "underLineBottomColor": "rgba(180, 167, 214, 0.3)",
                    "isTransparent": false,
                    "autosize": false,
                    "container_id": "tradingview_86083"
                  }
                    );`;
            document.body.appendChild(script);
        });
    }, []);

    return (
        <div className="tradingview-widget-container">
            <div id="tradingview_86083"></div>
        </div>
    );
}
