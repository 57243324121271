import { Api } from 'src/api';
import { SegmentEvents } from 'src/utils/segmentClient';
import Swal from 'sweetalert2';

type ScreenType = 'DASHBOARD' | 'BOT_SETUP';

export const openTradeWithRealMoneyAlert = (
    screenType: ScreenType
): Promise<boolean> =>
    new Promise(resolve =>
        Swal.fire({
            title: 'Connect to Brokerage',
            text: `Create an Alpaca brokerage account in order to trade with real money. Once it's created, click "Continue" below to give Trellis access to your account. We'll never submit a trade without your permission.`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Nevermind',
            icon: 'info'
        }).then(async res => {
            if (res.isConfirmed) {
                analytics.track(SegmentEvents.ClickedTradeWithRealMoney, {
                    screen_type: screenType
                });
                const res = await Api.alpaca.getAuthLink();
                const { link } = res.data;
                window.location.href = link;
                resolve(true);
            } else {
                resolve(false);
            }
        })
    );
