import React, { CSSProperties, useState } from 'react';
import { Colors } from 'src/utils/colors';
import { Stock } from 'trellis-types';

type OnClickFunction = () => any;
type Props = {
    stock: Stock;
    onClick: OnClickFunction;
    isEven: boolean;
    isLast: boolean;
};
export const StockInfo = ({ stock, onClick, isEven, isLast }: Props) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const { ticker, price } = stock;
    return (
        <a
            onClick={onClick}
            style={{
                ...styles.container,
                ...(isEven && { backgroundColor: Colors.lightGray1 }),
                ...(isHovering && { backgroundColor: Colors.lightGray3 }),
                ...(isLast && {
                    borderBottomLeftRadius: 17,
                    borderBottomRightRadius: 17
                })
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <label style={styles.clickableLabel}>{ticker}</label>
            <label style={styles.clickableLabel}>
                ${Number(price).toFixed(2)}
            </label>
        </a>
    );
};
const styles = {
    container: {
        display: 'flex',
        border: 'none',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        backgroundColor: Colors.lightGray2,
        color: '#FFFFFF',
        cursor: 'pointer',
        fontSize: '20px',
        height: 40
    } as CSSProperties,
    clickableLabel: {
        cursor: 'pointer',
        color: Colors.tundora
    }
};
