import React, { useState } from 'react';
import { Colors } from 'src/utils/colors';

// for when no starter packs are selected,
// user can continue to next screen
type Props = {
    onPress: () => void;
};
export const ContinueButton = ({ onPress }: Props) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    return (
        <button
            onClick={onPress}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{
                ...styles.container,
                ...(isHovering && {
                    backgroundColor: Colors.purple,
                    transform: 'scale(1.05)'
                })
            }}
        >
            No, start from scratch
        </button>
    );
};

const styles = {
    container: {
        backgroundColor: Colors.purple,
        color: Colors.white,
        outline: 'none',
        border: 'none',
        fontFamily: 'Manrope',
        paddingTop: 13,
        paddingBottom: 13,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 15,
        fontSize: 18,
        fontWeight: 600,
        cursor: 'pointer',
        transition: 'all 0.2s ease-out',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.15)'
    }
};
