import React, { CSSProperties } from 'react';
import Modal from 'react-modal';
import { Colors } from 'src/utils/colors';
import { Styles } from 'src/utils/styles';
import { IntegrationEnum } from 'trellis-types';

import { integrationMap } from '../lib';

type Props = {
    isVisible: boolean;
    integrationKey: IntegrationEnum;
    onClose: () => void;
};

export const IntegrationModal = ({
    isVisible,
    integrationKey,
    onClose
}: Props) => {
    const IntegrationBody = integrationMap[integrationKey];
    return (
        <Modal
            isOpen={isVisible}
            onRequestClose={onClose}
            style={Styles.modal}
            ariaHideApp={false}
            shouldCloseOnOverlayClick
        >
            <div style={Styles.modalHeader}>
                <button onClick={onClose} style={Styles.modalCloseBtn}>
                    Close
                </button>
                <div style={Styles.modalInnerContainer}>
                    <label style={Styles.modalTitle}>
                        Connect {IntegrationBody.displayName}
                    </label>
                </div>
            </div>
            <div style={styles.innerBodyContainer}>
                <IntegrationBody.renderModal onClose={onClose} />
            </div>
        </Modal>
    );
};

const styles = {
    header: {
        height: 80,
        backgroundColor: Colors.white,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        boxShadow: '0px 3px 20px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    } as CSSProperties,
    innerBodyContainer: {
        marginTop: 100
    }
};
