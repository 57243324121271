import 'react-phone-number-input/style.css';

import * as Sentry from '@sentry/react';
import _ from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'src/api';
import { getBot, getPerformanceInterval } from 'src/redux/bot/selector';
import { fetchCurrentBot } from 'src/redux/bot/action';
import { fetchUser } from 'src/redux/user/action';
import { getUser } from 'src/redux/user/selector';
import { Colors } from 'src/utils/colors';
import { analytics, SegmentEvents } from 'src/utils/segmentClient';
import { Styles } from 'src/utils/styles';
import Swal from 'sweetalert2';
import { IntegrationEnum } from 'trellis-types';
import { useHistory } from 'react-router';
import { RenderModalProps } from '../../lib';

type ScreenType = 'EXISTING_NUMBER' | 'SEND_CODE' | 'VERIFY';

export const TwilioModal = ({
    onClose,
    isRealMoneyIntegration
}: RenderModalProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const bot = useSelector(getBot);
    const performanceInterval = useSelector(getPerformanceInterval);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [currentScreen, setCurrentScreen] = useState<ScreenType>(
        user?.phoneNumber ? 'EXISTING_NUMBER' : 'SEND_CODE'
    );

    const addIntegration = async () =>
        isRealMoneyIntegration
            ? handleSuccess()
            : await Api.integrations.twilio.subscribe(bot?._id);

    useEffect(() => {
        if (currentScreen === 'EXISTING_NUMBER') {
            setPhoneNumber('');
        }
    }, [currentScreen]);

    const DEFAULT_SUBMIT_VERIFICATION_CODE_ERROR_MSG = `Please enter the code we sent to ${phoneNumber}.`;
    const DEFAULT_SEND_CODE_ERROR_MSG = `Please enter a valid phone number.`;
    const DEFAULT_ADD_INTEGRATION_WITH_EXISTING_NUMBER_ERROR_MSG = `There was an issue adding the integration to ${user?.phoneNumber}`;

    const showSuccessPopup = () =>
        isRealMoneyIntegration
            ? Swal.fire({
                  title: 'Deployed Bot',
                  text: `You have successfully saved and deployed your bot. We will now text ${
                      phoneNumber || user?.phoneNumber
                  } whenever your bot wants to make a trade. You can text back to execute trades from your phone. We will never submit a trade unless you approve it.`,
                  icon: 'success'
              })
            : Swal.fire({
                  title: 'Added Integration',
                  text: `We will now text ${bot?.name} bot alerts to ${
                      phoneNumber || user?.phoneNumber
                  }.`,
                  icon: 'success'
              });

    const handleSuccess = async () => {
        await Api.bots.updateAlpacaCompletionStatus(bot?._id);
        await fetchCurrentBot({
            dispatch,
            botId: bot?._id,
            performanceInterval
        });
    };

    const handleSubmitVerificationCode = async () => {
        try {
            setIsLoading(true);
            await Api.users.submitVerificationCode(phoneNumber, code);
            await addIntegration();
            analytics.track(SegmentEvents.AddedIntegration, {
                type: IntegrationEnum.Twilio,
                is_real_money_integration: isRealMoneyIntegration
            });
            showSuccessPopup();
            fetchUser(dispatch);
            setCurrentScreen('EXISTING_NUMBER');
            if (isRealMoneyIntegration) {
                history.push(
                    `/bot-workspace/${bot?._id}/integrations?wasNewBot=true`
                );
            }
            onClose();
        } catch (e) {
            Sentry.captureException(e);
            Swal.fire(
                _.get(
                    e,
                    'response.data',
                    DEFAULT_SUBMIT_VERIFICATION_CODE_ERROR_MSG
                )
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendVerificationCode = async () => {
        try {
            setIsLoading(true);
            await Api.users.sendPhoneVerificationCode(phoneNumber);
            setCurrentScreen('VERIFY');
        } catch (e) {
            Sentry.captureException(e);
            Swal.fire(_.get(e, 'response.data', DEFAULT_SEND_CODE_ERROR_MSG));
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddIntegrationWithExistingNumber = async () => {
        try {
            setIsLoading(true);
            await addIntegration();
            analytics.track(SegmentEvents.AddedIntegration, {
                type: IntegrationEnum.Twilio,
                is_real_money_integration: isRealMoneyIntegration
            });
            showSuccessPopup();
            fetchUser(dispatch);
            setCurrentScreen('EXISTING_NUMBER');
            if (isRealMoneyIntegration) {
                history.push(`/bot-workspace/${bot?._id}/integrations`);
            }
            onClose();
        } catch (e) {
            Sentry.captureException(e);
            console.log(e);

            Swal.fire(
                _.get(
                    e,
                    'response.data',
                    DEFAULT_ADD_INTEGRATION_WITH_EXISTING_NUMBER_ERROR_MSG
                )
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoBackToEnterPhone = () => {
        setPhoneNumber('');
        setCurrentScreen('SEND_CODE');
    };

    const renderBody = () => {
        switch (currentScreen) {
            case 'SEND_CODE':
                return (
                    <div style={styles.sectionContainer}>
                        <label style={styles.headerText}>
                            Please enter your phone number
                        </label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            country="US"
                            defaultCountry="US"
                            countries={['US', 'CH']}
                            international={false}
                            addInternationalOption={false}
                        />
                        <button
                            style={styles.nextBtn}
                            onClick={handleSendVerificationCode}
                            disabled={isLoading}
                        >
                            Send Verification Code
                        </button>
                    </div>
                );
            case 'VERIFY':
                return (
                    <div style={styles.sectionContainer}>
                        <label style={styles.headerText}>
                            Please enter the code we sent to {phoneNumber}
                        </label>
                        <input
                            value={code}
                            onChange={e => setCode(e.target.value)}
                            placeholder="Code"
                        />
                        <button
                            onClick={handleGoBackToEnterPhone}
                            style={styles.sendToDifferentNumberBtn}
                        >
                            Send code to different phone number?
                        </button>
                        <button
                            style={styles.nextBtn}
                            onClick={handleSubmitVerificationCode}
                            disabled={isLoading}
                        >
                            Finish Adding SMS Integration
                        </button>
                    </div>
                );
            case 'EXISTING_NUMBER':
                return (
                    <div style={styles.sectionContainer}>
                        <button
                            style={styles.nextBtn}
                            onClick={handleAddIntegrationWithExistingNumber}
                            disabled={isLoading}
                        >
                            Use {user?.phoneNumber}
                        </button>
                        <button
                            onClick={handleGoBackToEnterPhone}
                            style={styles.sendToDifferentNumberBtn}
                        >
                            Use different phone number?
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div style={styles.container}>
            <label style={Styles.modalSubheaderText}>Overview</label>
            <label style={Styles.modalDescriptionText}>
                Our SMS integration allows you to subscribe to bot updates
                through text messages. To set this up, decide which phone number
                will receive text updates.
            </label>
            {renderBody()}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    } as CSSProperties,
    nextBtn: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.purple,
        color: Colors.white,
        fontFamily: 'Manrope',
        padding: 12,
        minWidth: 130,
        borderRadius: 10,
        marginTop: 20,
        fontWeight: 600,
        cursor: 'pointer'
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20
    } as CSSProperties,
    headerText: {
        fontFamily: 'Manrope',
        fontSize: 16,
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: 20
    } as CSSProperties,
    sendToDifferentNumberBtn: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        fontSize: 15,
        color: Colors.purple,
        cursor: 'pointer',
        marginTop: 15
    }
};
