import React, { CSSProperties, useMemo } from 'react';
import { Colors } from 'src/utils/colors';
import { getBotPerformanceTextColor, shortenString } from 'src/utils/helpers';
import { Styles } from 'src/utils/styles';
import { BotDocument } from 'trellis-types';
import GoldBackground from 'src/assets/gold-background.png';
import SilverBackground from 'src/assets/silver-background.png';
import BronzeBackground from 'src/assets/bronze-background.png';
import UnrankedBackground from 'src/assets/unranked-background.png';
import { SegmentEvents } from 'src/utils/segmentClient';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

type Props = {
    bot: BotDocument;
    ranking: number;
};

export const TopBotCell = ({ bot, ranking }: Props) => {
    const history = useHistory();
    const performanceTextColor = getBotPerformanceTextColor(bot.performance);

    const handlePressedCell = () => {
        analytics.track(SegmentEvents.ClickedBotRow, {
            ticker: bot.ticker,
            name: bot.name,
            initial_buying_power: bot.initialBuyingPower,
            buy_quantity: bot.buyQuantity,
            sell_quantity: bot.sellQuantity,
            performance: bot.performance,
            is_ranked_cell: true
        });

        isMobile
            ? Swal.fire({
                  title: 'Sorry',
                  text: 'You can only edit a bot on a computer or tablet (for now).'
              })
            : history.push(`/bot-workspace/${bot._id}/metrics`);
    };

    return (
        <button style={styles.container} onClick={handlePressedCell}>
            <div style={styles.rankingContainer}>
                <label style={styles.rankingText}>{ranking}</label>
            </div>
            <div style={styles.innerContainer}>
                <label style={styles.botName}>
                    {shortenString(bot.name, 10)}
                </label>
                <label style={styles.botTicker}>{bot.ticker}</label>
                <label
                    style={{
                        color: performanceTextColor,
                        ...styles.performanceText
                    }}
                >
                    {bot.performance}
                </label>
            </div>
        </button>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 20px rgba(0,0,0,0.1)',
        marginRight: 30,
        borderRadius: 15,
        width: 200,
        height: 140,
        position: 'relative',
        backgroundColor: Colors.white,
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: 8,
        outline: 'none',
        border: 'none',
        cursor: 'pointer'
    } as CSSProperties,
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 30,
        marginLeft: 20,
        marginBottom: 20,
        marginRight: 20,
        cursor: 'pointer',
        overflow: 'hidden'
    } as CSSProperties,
    botName: {
        fontSize: 20,
        fontWeight: 800,
        ...Styles.textOverflowEllipsis,
        color: Colors.tundora,
        cursor: 'pointer'
    },
    botTicker: {
        fontSize: 16,
        color: Colors.lightGray5,
        cursor: 'pointer',
        ...Styles.textOverflowEllipsis
    },
    performanceText: {
        fontSize: 26,
        fontWeight: 800,
        cursor: 'pointer',
        ...Styles.textOverflowEllipsis
    },
    rankingText: {
        color: Colors.white,
        fontSize: 20,
        fontWeight: 800,
        cursor: 'pointer'
    },
    rankingContainer: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: Colors.purple,
        position: 'absolute',
        top: -20,
        right: -20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 0px 25px rgba(0,0,0,0.1)',
        borderRadius: 12,
        cursor: 'pointer'
    } as CSSProperties
};
