import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { ReduxReducers } from './redux/reducerIndex';
import { BotWorkspace } from './screens/botWorkspace';
import { Dashboard } from './screens/dashboard';
import { ActiveScreenEnum } from './screens/dashboard/lib';
import { SignIn } from './screens/onboarding/SignIn';
import { SignUp } from './screens/onboarding/SignUp';
import { VerifyEmail } from './screens/onboarding/VerifyEmail';
import { SetupBot } from './screens/newBotSetup/setupBot';
import { StockSelection } from './screens/newBotSetup/stocks';
import { SetupApp } from './utils/SetupApp';
import { StarterPacks } from './screens/newBotSetup/starterPacks';
import { AlpacaAuth } from './screens/alpacaAuth';
import { AlpacaInstructions } from './screens/alpacaInstructions';

// path: /editor/new
export const NEW_BOT_EDITOR_PATH = 'new';

const REDUX_LOGS_ENABLED = false;

function App() {
    const logger = createLogger({ diff: false });
    let store = createStore(ReduxReducers, applyMiddleware(thunk));
    if (REDUX_LOGS_ENABLED && !process.env.IS_PROD) {
        store = createStore(ReduxReducers, applyMiddleware(thunk, logger));
    }

    return (
        <Provider store={store}>
            <Router>
                <SetupApp />
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    {Object.values(ActiveScreenEnum).map(screen => (
                        <Route path={screen} component={Dashboard} />
                    ))}
                    <Route
                        path="/setup-bot/stocks"
                        component={StockSelection}
                    />
                    <Route path="/setup-bot/form" component={SetupBot} />
                    <Route path="/setup-bot/packs" component={StarterPacks} />
                    <Route path="/bot-workspace/:id" component={BotWorkspace} />
                    <Route path="/sign-in" component={SignIn} />
                    <Route path="/sign-up" component={SignUp} />
                    <Route path="/verify-email" component={VerifyEmail} />
                    <Route path="/alpaca-auth" component={AlpacaAuth} />
                    <Route
                        path="/alpaca-description"
                        component={AlpacaInstructions}
                    />
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
