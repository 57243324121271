import { CSSProperties } from 'react';
import { Colors } from 'src/utils/colors';

export const responsiveStyles = (windowHeight: number) => ({
    formSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: 600,
        backgroundColor: Colors.white,
        paddingTop: 20,
        paddingLeft: 28,
        paddingRight: 28,
        paddingBottom: 28,
        borderRadius: 20,
        boxShadow: '0px 0px 20px rgba(0,0,0,0.1)'
    } as CSSProperties,
    inputSection: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 10,
        width: '100%'
    } as CSSProperties,
    inputDesc: {
        marginTop: 20,
        marginBottom: 10,
        fontSize: 16,
        fontWeight: 600,
        color: Colors.tundora
    },
    inputTextfield: {
        fontSize: 16,
        outline: 'none',
        borderStyle: 'solid',
        borderColor: Colors.alto,
        borderWidth: 1,
        borderRadius: 3,
        height: 32,
        width: '100%',
        paddingLeft: 10
    },
    nextStepButton: {
        outline: 'none',
        border: 'none',
        width: '100%',
        height: 38,
        marginTop: 17,
        color: Colors.white,
        backgroundColor: Colors.purple,
        fontWeight: 600,
        fontSize: 16,
        cursor: 'pointer',
        borderRadius: 12,
        paddingLeft: 15,
        paddingRight: 15
    },
    submitSection: {
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    title: {
        fontSize: 32,
        fontWeight: 600,
        textAlign: 'center'
    } as CSSProperties,
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 45,
        alignItems: 'center',
        flex: 1,
        minHeight: windowHeight,
        backgroundColor: Colors.paper
    } as CSSProperties,
    checkBoxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: 14
    } as CSSProperties,
    checkBox: {
        marginTop: 20,
        marginBottom: 10,
        marginRight: 10,
        cursor: 'pointer'
    },
    realMoneyWarning: {
        color: Colors.warningYellow
    },
    largerTitle: {
        fontSize: 32,
        color: Colors.tundora,
        fontWeight: 600,
        marginBottom: 30
    },
    smallDesc: {
        fontSize: 16,
        color: Colors.tundora
    },
    defaultBehaviorText: {
        color: Colors.darkGray1,
        marginTop: -2
    },
    termsAndConditionsLink: {
        textDecoration: 'underline',
        color: Colors.purple
    },
    termsAndConditionLabel: {
        color: Colors.darkGray1
    }
});
