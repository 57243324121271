import React, { CSSProperties } from 'react';
import { Colors } from 'src/utils/colors';
import { PlanEnum } from 'trellis-types';
import { Block } from './components/Block';
import BackgroundImage from 'src/assets/premiumBackground.png';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { getUser } from 'src/redux/user/selector';
import { ReferUser } from './components/ReferUser';

export const Premium = () => {
    const user = useSelector(getUser);
    const plan = user?.currentPlan;

    return (
        <div style={styles.outerContainer}>
            <label style={styles.title}>
                Become a better trader with premium.
            </label>
            {plan === PlanEnum.Free && <ReferUser />}
            <div style={styles.container}>
                <Block
                    title="Free Plan"
                    notes={[
                        'Make up to 2 bots',
                        'Receive buy/sell alerts',
                        'Trade on 15 minute intervals',
                        'Paper trading',
                        'Live trading',
                        'Share your bot',
                        'Access to mobile app',
                        'Access to support team'
                    ]}
                    price="$0.00/month"
                    plan={PlanEnum.Free}
                />
                <Block
                    title="Premium Plan"
                    notes={[
                        'The free plan plus...',
                        'Make up to 12 bots',
                        'View strategies of other bots',
                        'Make your bots private'
                    ]}
                    price="$8.99/month"
                    buttonTitle="Get Premium"
                    plan={PlanEnum.Tier1}
                />
            </div>
            {/* {plan === PlanEnum.Free && (
                <button
                    style={styles.getPremiumForFree}
                    onClick={() =>
                        Swal.fire({
                            title: 'How to get Premium for free',
                            text: 'There are 2 ways to get premium for free. First, you can share a link to one of your bots on Twitter and tag us @trytrellis. To get a shareable link to your bot, hit the "Share Bot" button in the top right of the bot workspace. Must have at least 25 followers on Twitter to qualify. The other option is to refer your friends using the text box above. If at least 2 of your referrals sign up, then we\'ll give you premium for free.'
                        })
                    }
                >
                    Learn how to get Premium for free
                </button>
            )} */}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    } as CSSProperties,
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        padding: 50
    } as CSSProperties,
    title: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 30,
        textAlign: 'center',
        color: Colors.white
    } as CSSProperties,
    description: {
        fontSize: 28,
        fontWeight: 500,
        marginBottom: 60,
        textAlign: 'center',
        color: Colors.white
    } as CSSProperties,
    getPremiumForFree: {
        backgroundColor: Colors.purple,
        color: Colors.white,
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        borderRadius: 24,
        boxShadow: '0px 12px 10px rgba(0,0,0,0.2)',
        paddingLeft: 22,
        paddingRight: 22,
        paddingTop: 11,
        paddingBottom: 11,
        marginTop: 36,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Manrope'
    }
};
