import segmentPlugin from '@analytics/segment';
import Analytics from 'analytics';

export const analytics = Analytics({
    plugins: [
        segmentPlugin({
            writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY
        })
    ]
});

export const SegmentEvents = {
    SignedUp: 'Signed Up',
    LoggedIn: 'Logged In',
    CreatedBot: 'Created Bot',
    DeletedBot: 'Deleted Bot',
    ToggledBot: 'Toggled Bot',
    AddedIntegration: 'Added Integration',
    DeletedIntegration: 'Deleted Integration',
    OpenedTriggerExplanation: 'Opened Trigger Explanation',
    VerifiedEmail: 'Verified Email',
    JoinedCompetition: 'Joined Competition',
    LeftCompetition: 'Left Competition',
    CompetitionEnded: 'Competition Ended',
    ChangedProfilePicture: 'Changed Profile Picture',

    // Viewed events are for loading specific screens
    ViewedIntegrations: 'Viewed Integrations',
    ViewedMetrics: 'Viewed Metrics',
    ViewedPastDecisions: 'Viewed Past Decisions',
    ViewedTriggers: 'Viewed Triggers',
    ViewedJoinCompetition: 'Viewed Join Competition',
    ViewedMyBots: 'Viewed My Bots',
    ViewedStocks: 'Viewed Stocks',
    ViewedSetupBot: 'Viewed Setup Bot',
    ViewedStarterPacks: 'Viewed Starter Packs',

    // Clicked events are for clicking specific components
    ClickedBotRow: 'Clicked Bot Row',
    ClickedBackToMyBots: 'Clicked Back Button In Workspace',
    ClickedStarterPack: 'Clicked Starter Pack Cell',
    ClickedUseStarterPack: 'Clicked Use Starter Pack',
    ClickedTradeWithRealMoney: 'Clicked Trade With Real Money',
    ClickedSeeTickerChart: 'Clicked See Ticker Chart',
    ClickedSeeBotValueChart: 'Clicked See Bot Value',
    ClickedSeeBotStrategy: 'Clicked See Bot Strategy',
    ClickedReferPerson: 'Clicked Refer Person'
};
