import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import { NEW_BOT_EDITOR_PATH } from 'src/App';
import { Colors } from 'src/utils/colors';

export const TriggerBackButton = () => {
    const history = useHistory();

    const handleTriggerBackButton = () =>
        history.push(`/bot-workspace/${NEW_BOT_EDITOR_PATH}/buy-triggers`);

    return (
        <button
            onClick={handleTriggerBackButton}
            style={styles.backToBuyTriggersBtn}
        >
            {'<'} Back to Buy Triggers
        </button>
    );
};

const styles = {
    backToBuyTriggersBtn: {
        width: 260,
        maxWidth: 600,
        marginTop: 12,
        border: 'none',
        outline: 'none',
        backgroundColor: Colors.transparent,
        borderRadius: 13,
        height: 40,
        color: Colors.darkGray1,
        fontFamily: 'Manrope',
        fontSize: 15,
        fontWeight: 400,
        cursor: 'pointer'
    } as CSSProperties
};
