import { prop } from 'lodash/fp';
import React, { CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { NEW_BOT_EDITOR_PATH } from 'src/App';
import GraphIcon from 'src/assets/graphIcon.png';
import MinusIcon from 'src/assets/minus.png';
import MoneyIcon from 'src/assets/moneyIcon.png';
import PlusIcon from 'src/assets/plus.png';
import PuzzleIcon from 'src/assets/puzzle.png';
import { getAuthStatus } from 'src/redux/auth/selector';
import { clearCurrentBot } from 'src/redux/bot/action';
import {
    getBot,
    getBotHasUnsavedChanges,
    getBotName,
    getRealMoneyEnabled,
    getTicker
} from 'src/redux/bot/selector';
import { getUser } from 'src/redux/user/selector';
import { TimeToolTip } from 'src/screens/myBots/components/Time';
import { Colors } from 'src/utils/colors';
import { getLastPath, isUsingRealMoney } from 'src/utils/helpers';
import { analytics, SegmentEvents } from 'src/utils/segmentClient';
import { Styles } from 'src/utils/styles';
import { useIsWindowSkinny } from 'src/utils/windowHooks';
import Swal from 'sweetalert2';
import { useIsVisitorUsingAccessCode } from '../lib';
import NewAccountIcon from 'src/assets/loginIcon.png';

const ACTIVE_TAB_BACKGROUND_COLOR = Colors.catskillWhite;

type Props = {
    isLoading: boolean;
};

export const WorkspaceSidebar = ({ isLoading }: Props) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const bot = useSelector(getBot);
    const authState = useSelector(getAuthStatus);
    const path = getLastPath(location.pathname);
    const reduxBotName = useSelector(getBotName);
    const reduxTicker = useSelector(getTicker);
    const isRealMoneyEnabled = useSelector(getRealMoneyEnabled);
    const hasUnsavedChanges = useSelector(getBotHasUnsavedChanges);
    const botPath = bot?._id || NEW_BOT_EDITOR_PATH;
    const isWindowSkinny = useIsWindowSkinny();
    const responsiveStyles = styles(isWindowSkinny);
    const user = useSelector(getUser);
    const showUsingRealMoneyLbl = bot
        ? isUsingRealMoney(bot)
        : isRealMoneyEnabled;
    const { isVisitorUsingAccessCode, accessCode } =
        useIsVisitorUsingAccessCode();

    const botNotFound = !bot && !reduxBotName && !isLoading;

    const botName = prop('name', bot) || reduxBotName || 'Loading...';
    const ticker = prop('ticker', bot) || reduxTicker;
    const accessCodeUrl = accessCode ? `?accessCode=${accessCode}` : '';

    const handleOnClickBuyTriggers = () =>
        history.push(`/bot-workspace/${botPath}/buy-triggers${accessCodeUrl}`);

    const handleOnClickSellTriggers = () =>
        history.push(`/bot-workspace/${botPath}/sell-triggers${accessCodeUrl}`);

    const goBackToBotsScreen = () => {
        analytics.track(SegmentEvents.ClickedBackToMyBots, {
            ticker: bot?.ticker,
            name: bot?.name,
            initial_buying_power: bot?.initialBuyingPower,
            buy_quantity: bot?.buyQuantity,
            sell_quantity: bot?.sellQuantity
        });
        history.push('/');
        dispatch(clearCurrentBot());
    };

    const handleOnClickHome = () => {
        if ((hasUnsavedChanges || !bot) && !botNotFound && !isLoading) {
            Swal.fire({
                title: 'Please Confirm',
                text: `${
                    bot
                        ? "You have unsaved changes to your bot's triggers."
                        : 'You have not finished creating your bot.'
                } Are you sure you want to leave?`,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Exit Without Saving',
                cancelButtonText: 'Nevermind',
                icon: 'warning'
            }).then(async res => {
                if (res.isConfirmed) {
                    goBackToBotsScreen();
                }
            });
            return;
        } else {
            goBackToBotsScreen();
        }
    };

    const goToSignUp = () => history.push('/sign-up');

    return (
        <div style={responsiveStyles.container}>
            {user ? (
                <button
                    onClick={handleOnClickHome}
                    style={responsiveStyles.backToHomeBtn}
                >
                    {'<'} Back to My Bots
                </button>
            ) : authState === 'NOT_SIGNED_IN' ? (
                <button
                    onClick={goToSignUp}
                    style={responsiveStyles.createAccountBtn}
                >
                    <img
                        src={NewAccountIcon}
                        style={responsiveStyles.newAccountIcon}
                        alt=""
                    />
                    <label style={responsiveStyles.createAccountLbl}>
                        Create Account
                    </label>
                </button>
            ) : null}
            <div style={responsiveStyles.botNameDiv}>
                <label style={responsiveStyles.botName}>{botName}</label>
                <TimeToolTip bot={bot} ignoreWindowSize />
            </div>
            <label style={responsiveStyles.tickerName}>{ticker}</label>
            {showUsingRealMoneyLbl && (
                <label style={responsiveStyles.usingRealMoneyLbl}>
                    <img
                        src={MoneyIcon}
                        style={responsiveStyles.moneyIcon}
                        alt=""
                    />
                    Connected to Alpaca
                </label>
            )}
            {botNotFound ? null : (
                <div style={responsiveStyles.routesContainer}>
                    <button
                        style={{
                            ...responsiveStyles.routeText,
                            ...(path === 'metrics' && {
                                backgroundColor: ACTIVE_TAB_BACKGROUND_COLOR
                            }),
                            ...(!bot?._id && {
                                opacity: 0.3,
                                cursor: 'default'
                            })
                        }}
                        disabled={!bot}
                        onClick={() =>
                            history.push(
                                `/bot-workspace/${botPath}/metrics${accessCodeUrl}`
                            )
                        }
                        className={
                            path === 'metrics' ? undefined : 'sidebar-row'
                        }
                    >
                        <img
                            src={GraphIcon}
                            style={responsiveStyles.icon}
                            alt=""
                        />
                        Performance
                    </button>
                    {isVisitorUsingAccessCode ? null : (
                        <button
                            style={{
                                ...responsiveStyles.routeText,
                                ...(path === 'integrations' && {
                                    backgroundColor: ACTIVE_TAB_BACKGROUND_COLOR
                                }),
                                ...(!bot?._id && {
                                    opacity: 0.3,
                                    cursor: 'default'
                                })
                            }}
                            disabled={!bot}
                            onClick={() =>
                                history.push(
                                    `/bot-workspace/${botPath}/integrations`
                                )
                            }
                            className={
                                path === 'integrations'
                                    ? undefined
                                    : 'sidebar-row'
                            }
                        >
                            <img
                                src={PuzzleIcon}
                                style={responsiveStyles.icon}
                                alt=""
                            />
                            Integrations
                        </button>
                    )}
                    <button
                        style={{
                            ...responsiveStyles.routeText,
                            ...(path === 'buy-triggers' && {
                                backgroundColor: ACTIVE_TAB_BACKGROUND_COLOR
                            })
                        }}
                        onClick={handleOnClickBuyTriggers}
                        className={
                            path === 'buy-triggers' ? undefined : 'sidebar-row'
                        }
                    >
                        <img
                            src={PlusIcon}
                            style={responsiveStyles.icon}
                            alt=""
                        />
                        Buy Triggers
                    </button>
                    <button
                        style={{
                            ...responsiveStyles.routeText,
                            ...(path === 'sell-triggers' && {
                                backgroundColor: ACTIVE_TAB_BACKGROUND_COLOR
                            })
                        }}
                        onClick={handleOnClickSellTriggers}
                        className={
                            path === 'sell-triggers' ? undefined : 'sidebar-row'
                        }
                    >
                        <img
                            src={MinusIcon}
                            style={responsiveStyles.icon}
                            alt=""
                        />
                        Sell Triggers
                    </button>
                </div>
            )}
        </div>
    );
};

const styles = (_isWindowSkinny: boolean) => ({
    container: {
        width: 270,
        backgroundColor: Colors.blackSqueeze,
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: 0,
        height: window.innerHeight,
        boxShadow: '2px 0px 10px rgba(200,200,200,0.25)'
    } as CSSProperties,
    botNameDiv: {
        fontSize: 24,
        fontWeight: 700,
        marginTop: 75,
        color: Colors.tundora,
        paddingLeft: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    } as CSSProperties,
    botName: {
        ...Styles.textOverflowEllipsis,
        marginRight: 10,
        position: 'relative',
        top: 2
    } as CSSProperties,
    tickerName: {
        fontSize: 15,
        fontWeight: 400,
        color: Colors.darkGray1,
        paddingLeft: 25
    } as CSSProperties,
    routesContainer: {
        paddingTop: 20,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative'
    } as CSSProperties,
    routeText: {
        cursor: 'pointer',
        fontFamily: 'Manrope',
        outline: 'none',
        border: 'none',
        fontWeight: 600,
        fontSize: 15,
        color: Colors.tundora,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 44
    } as CSSProperties,
    backToHomeBtn: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        fontFamily: 'Manrope',
        color: Colors.darkGray1,
        cursor: 'pointer',
        position: 'absolute',
        top: 15,
        left: 10,
        fontWeight: 400,
        fontSize: 14,
        borderRadius: 12,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 4,
        paddingBottom: 4
    } as CSSProperties,
    requestsButton: {
        padding: 10,
        position: 'absolute',
        bottom: 0,
        left: 15
    } as CSSProperties,
    usingRealMoneyLbl: {
        marginTop: 10,
        color: Colors.darkGray1,
        marginLeft: 25
    } as CSSProperties,
    moneyIcon: {
        width: 18,
        height: 18,
        marginRight: 5
    },
    icon: {
        width: 25,
        height: 25,
        marginRight: 10,
        marginLeft: 15
    } as CSSProperties,
    createAccountBtn: {
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        paddingTop: 4,
        paddingBottom: 4,
        height: 40,
        backgroundColor: Colors.purple,
        borderRadius: 12,
        marginTop: 15,
        marginLeft: 15,
        marginRight: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 3px 7px rgba(0,0,0,0.14)'
    } as CSSProperties,
    newAccountIcon: {
        width: 20,
        height: 20,
        cursor: 'pointer'
    },
    createAccountLbl: {
        fontWeight: 500,
        fontSize: 16,
        color: Colors.white,
        marginLeft: 7,
        cursor: 'pointer'
    }
});
