import React, { CSSProperties } from 'react';
import { Colors } from 'src/utils/colors';
import RefreshIcon from 'src/assets/refresh.png';

type Props = {
    btnStyle?: any;
    onPress: () => Promise<void>;
    isLoading: boolean;
    hideText?: boolean;
};

export const RefreshBtn = ({
    btnStyle,
    onPress,
    isLoading,
    hideText
}: Props) => (
    <button
        style={{
            ...styles.refreshBtn,
            ...btnStyle,
            ...(isLoading && { opacity: 0.4 })
        }}
        onClick={onPress}
        disabled={isLoading}
    >
        <img src={RefreshIcon} style={styles.refreshIcon} alt="" />
        <label style={styles.refreshBtnLbl}>
            {!hideText && (isLoading ? 'Refreshing...' : 'Refresh')}
        </label>
    </button>
);

const styles = {
    refreshBtn: {
        outline: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        border: 'none',
        color: Colors.darkGray1,
        cursor: 'pointer',
        backgroundColor: Colors.transparent
    } as CSSProperties,
    refreshBtnLbl: {
        textAlign: 'left',
        width: '100%',
        cursor: 'pointer',
        fontSize: 13
    } as CSSProperties,
    refreshIcon: {
        width: 17,
        height: 17,
        marginRight: 6
    }
};
