import axios from '../utils/axios';

const Competitions = {
    joinCompetition: async (code: string) =>
        axios.post('/competitions/join', { code }),
    leaveCompetition: async () => axios.post('/competitions/leave'),
    getIsCompetitionExpired: async (code: string) =>
        axios.get('/competitions/is-expired', {
            params: {
                code
            }
        }),
    getCurrentCompetition: async () => axios.get('/competitions/current')
};

export default Competitions;
