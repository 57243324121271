import { UserActionsIndex } from './action';
import { UserDocument } from 'trellis-types';

export type UserReducerType = {
    user: UserDocument | null;
};

const initialState: UserReducerType = {
    user: null
};

export const userReducer = (
    state: UserReducerType = initialState,
    action: UserActionsIndex
): UserReducerType => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, user: action.user };
        default:
            return state;
    }
};
