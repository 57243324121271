import AirtableModule from 'src/api/airtable';
import AlpacaModule from 'src/api/alpaca';
import BotsModule from 'src/api/bots';
import CompetitionsModule from 'src/api/competitions';
import IntegrationModule from 'src/api/integrations';
import EmailModule from 'src/api/integrations/email';
import SlackModule from 'src/api/integrations/slack';
import TwilioModule from 'src/api/integrations/twilio';
import StocksModule from 'src/api/stocks';
import UsersModule from 'src/api/users';

export const Api = {
    airtable: AirtableModule,
    bots: BotsModule,
    integrations: {
        integration: IntegrationModule,
        slack: SlackModule,
        twilio: TwilioModule,
        email: EmailModule
    },
    users: UsersModule,
    stocks: StocksModule,
    competitions: CompetitionsModule,
    alpaca: AlpacaModule
};
