import {
    IndicatorEnum,
    OperatorType,
    IndicatorInformation
} from 'trellis-types';

// the values of these key/value pairs are the text that the user will see
// TODO: REMOVE TS-IGNORE
// @ts-ignore
export const indicatorMap: { [key in IndicatorEnum]: string } = {
    PRICE: 'Price',
    MA_200: 'Simple Moving Avg 200 Day',
    MA_50: 'Simple Moving Avg 50 Day',
    RSI: 'RSI',
    VWAP: 'VWAP',
    MACD: 'MACD (12, 26)',
    MACD_SIGNAL: 'MACD 9 Day Signal',
    PRICE_PERCENTAGE_CHANGE_1_DAY: 'Price % Change (Daily)',
    EMA_20: 'Exponential Moving Avg 20 Day',
    EMA_9: 'Exponential Moving Avg 9 Day',
    EMA_55: 'Exponential Moving Avg 55 Day',
    EMA_89: 'Exponential Moving Avg 89 Day',
    AVERAGE_PRICE: "Bot's Average Purchase Price",
    UPPER_BOLLINGER_BAND: 'Upper Bollinger Band (20 Day Period)',
    LOWER_BOLLINGER_BAND: 'Lower Bollinger Band (20 Day Period)'
};

export const operatorMap: { [key in OperatorType]: string } = {
    CROSSES_ABOVE: 'Crosses Above',
    CROSSES_BELOW: 'Crosses Below',
    IS_ABOVE: 'Is Above',
    IS_BELOW: 'Is Below'
};

// TODO: REMOVE TS-IGNORE
// @ts-ignore
export const IndicatorExplanation: {
    [key in IndicatorEnum]: IndicatorInformation;
} = {
    PRICE: { title: 'Price', explanation: 'The price.' },
    RSI: {
        title: 'Relative Strength Index (RSI)',
        explanation:
            'A measurement of how quickly traders are bidding the price of the security up or down, measured on a scale of 0-100. An RSI of 70 generally means that the stock is overbought while an RSI of 30 means that the stock is oversold.'
    },
    MA_50: {
        title: '50 Day Simple Moving Average',
        explanation: 'The average price over the last 50 days.'
    },
    MA_200: {
        title: '200 Day Simple Moving Average',
        explanation: 'The average price over the last 200 days.'
    },
    VWAP: {
        title: 'Volume Weighted Average Price (VWAP)',
        explanation:
            'An indicator that weights the average price by multiplying price by the number of shares traded and dividing by the total number of shares traded.'
    },
    MACD: {
        title: 'Moving Average Convergence Divergence (MACD)',
        explanation:
            'An indicator that shows the difference between the previous 12 bars of the faster moving average and the previous 26 bars of the slower moving average.'
    },
    MACD_SIGNAL: {
        title: 'Moving Average Convergence Divergence (MACD) Signal Line',
        explanation:
            'An indicator that shows the 9 day exponential moving average of the MACD. This is typically used alongside MACD (12, 26). Whenever the MACD crosses above the signal line it indicates bullish momentum, while crossing below indicates bearish momentum.'
    },
    PRICE_PERCENTAGE_CHANGE_1_DAY: {
        title: '1 Day Price Percentage Change',
        explanation: 'The percentage change in price over today.'
    },
    EMA_20: {
        title: 'Exponential Moving Avg 20 Day',
        explanation:
            'The average price over the last 20 days, but recent values are weighted more heavily.'
    },
    EMA_9: {
        title: 'Exponential Moving Avg 9 Day',
        explanation:
            'The average price over the last 9 days, but recent values are weighted more heavily.'
    },
    EMA_55: {
        title: 'Exponential Moving Avg 55 Day',
        explanation:
            'The average price over the last 55 days, but recent values are weighted more heavily.'
    },
    EMA_89: {
        title: 'Exponential Moving Avg 89 Day',
        explanation:
            'The average price over the last 89 days, but recent values are weighted more heavily.'
    },
    AVERAGE_PRICE: {
        title: "Bot's Average Purchase Price",
        explanation:
            'The average purchase price of the bot is the average price of the shares the bot currently owns. For example, if the bot bought 1 share at $10 and 1 share at $20, the average purchase price would be $15. If your bot owns 0 shares, this value will be 0.'
    },
    UPPER_BOLLINGER_BAND: {
        title: 'Upper Bollinger Band (20 Day Period)',
        explanation:
            'When the share price goes above the upper bollinger band, it is an indication that the asset may be overbought.'
    },
    LOWER_BOLLINGER_BAND: {
        title: 'Lower Bollinger Band (20 Day Period)',
        explanation:
            'When the share price goes below the lower bollinger band, it is an indication that the asset may be oversold.'
    }
};

export const OperatorExplanation: {
    [key in OperatorType]: IndicatorInformation;
} = {
    CROSSES_ABOVE: {
        title: 'Crosses Above',
        explanation:
            'Your trigger will pass whenever the value of the indicator crosses above the value of the operand.'
    },
    CROSSES_BELOW: {
        title: 'Crosses Below',
        explanation:
            'Your trigger will pass whenever the value of the indicator crosses below the value of the operand.'
    },
    IS_ABOVE: {
        title: 'Is Above',
        explanation:
            'Your trigger will pass when the value of your indicator is greater than the value of the operand'
    },
    IS_BELOW: {
        title: 'Is Below',
        explanation:
            'Your trigger will pass when the value of your indicator is less than the value of the operand'
    }
};

export const Constants = {
    // used to set default values in editor
    defaultIndicatorKey: Object.keys(indicatorMap)[0] as IndicatorEnum,
    defaultOperatorKey: Object.keys(operatorMap)[0] as OperatorType,

    DISCORD_LINK: 'https://discord.gg/SHaEEtwjA9',
    REQUESTS_FORM_LINK: 'https://airtable.com/shrAcGFgpX4zJQuvW',

    MAX_TRIGGER_LIMIT: 15, // changing this requires backend change too
    GLOBAL_COMPETITION_CODE: 'april-madness',
    GLOBAL_COMPETITION_DESCRIPTION:
        'Join our April competition where winners get free money to trade with.',

    TERMS_AND_CONDITIONS_LINK:
        'https://docs.google.com/document/d/1mieMTQcEa9l3N_vLH4G7CGAEHfIeNOmCOXbnkOe7RN4/edit?usp=sharing',

    MAX_BOTS_PREMIUM: 12
};
