import _ from 'lodash';
import React, { CSSProperties, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Api } from 'src/api';
import { setUser } from 'src/redux/actionIndex';
import { getAuthStatus } from 'src/redux/auth/selector';
import { getErrorMessage } from 'src/utils/helpers';
import Swal from 'sweetalert2';

export const AlpacaAuth = () => {
    const authStatus = useSelector(getAuthStatus);
    const dispatch = useDispatch();
    const history = useHistory();

    const sendUserAuthToken = async () => {
        try {
            const urlQuery = new URLSearchParams(window.location.search);
            const code = urlQuery.get('code') || '';
            const res = await Api.alpaca.sendUserToken(code);
            const { user } = res.data;
            dispatch(setUser(user));
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'You are now connected to your Alpaca account. Create a bot to start trading with real money.'
            });
            history.push('/my-bots');
        } catch (e) {
            Swal.fire({
                title: 'Oops',
                text: getErrorMessage(e),
                icon: 'error'
            });
        }
    };

    useEffect(() => {
        if (authStatus === 'SIGNED_IN') {
            sendUserAuthToken();
        }
    }, [authStatus]);

    return (
        <div style={styles.container}>
            <label style={styles.text}>
                Please wait one moment while we finish connecting your Alpaca
                account to Trellis.
            </label>
            <label style={styles.text}>
                You will be automatically redirected once we're ready.
            </label>
        </div>
    );
};

const styles = {
    container: {
        marginTop: 50,
        padding: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    } as CSSProperties,
    text: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: 'center'
    } as CSSProperties
};
