import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { clearCurrentBot } from 'src/redux/bot/action';
import { getLastPathWithSlash } from 'src/utils/helpers';

import { MyBots } from '../myBots';
import { Leaderboard } from '../leaderboard';
import { Premium } from '../premium';

export enum ActiveScreenEnum {
    MyBots = '/my-bots',
    Leaderboard = '/leaderboard',
    Premium = '/premium'
}
const DEFAULT_SCREEN: ActiveScreenEnum = ActiveScreenEnum.MyBots;

export const useDashboardScreenManager = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const path = getLastPathWithSlash(location.pathname);

    useEffect(() => {
        dispatch(clearCurrentBot());
    }, []);

    useEffect(() => {
        if (path === '/') {
            history.push(DEFAULT_SCREEN);
        }
    }, [path]);

    // this must be a useCallback to prevent infinite rerender
    const renderActiveScreen = useCallback(() => {
        let MainComponent: any = null;
        switch (path as ActiveScreenEnum) {
            case ActiveScreenEnum.MyBots:
                MainComponent = <MyBots />;
                break;
            case ActiveScreenEnum.Leaderboard:
                MainComponent = <Leaderboard />;
                break;
            case ActiveScreenEnum.Premium:
                MainComponent = <Premium />;
                break;
            default:
                MainComponent = null;
        }

        return MainComponent;
    }, [path]);

    return { renderActiveScreen };
};
