const SET_IS_MOBILE_MENU_OPEN = 'SET_IS_MOBILE_MENU_OPEN';
type SET_IS_MOBILE_MENU_OPEN = typeof SET_IS_MOBILE_MENU_OPEN;

type ISetIsMobileMenuOpen = {
    type: SET_IS_MOBILE_MENU_OPEN;
    isMobileMenuOpen: boolean;
};

export const setIsMobileSidebarOpen = (
    isMobileMenuOpen: boolean
): ISetIsMobileMenuOpen => ({
    type: SET_IS_MOBILE_MENU_OPEN,
    isMobileMenuOpen
});

export type DashboardActionsIndex = ISetIsMobileMenuOpen;
