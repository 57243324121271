import React, { CSSProperties } from 'react';
import ReactTooltip from 'react-tooltip';
import MoneyIcon from 'src/assets/moneyIcon.png';
import { Colors } from 'src/utils/colors';
import { isUsingRealMoney } from 'src/utils/helpers';
import { useIsWindowSkinny } from 'src/utils/windowHooks';
import { BotDocument } from 'trellis-types';

interface Props {
    bot: BotDocument;
}

export const RealMoneyToolTip = ({ bot }: Props) => {
    const isWindowSkinny = useIsWindowSkinny();

    return isUsingRealMoney(bot) && !isWindowSkinny ? (
        <div style={styles.container}>
            <img
                data-tip
                data-for={`money-icon-${bot._id}`}
                src={MoneyIcon}
                style={styles.moneyIcon}
                alt=""
            />
            <ReactTooltip
                id={`money-icon-${bot._id}`}
                effect="solid"
                className="react-tooltip-overrides"
                backgroundColor={Colors.darkerPurple}
            >
                <div style={{ maxWidth: 310 }}>
                    <label>This bot uses real money when trading.</label>
                </div>
            </ReactTooltip>
        </div>
    ) : null;
};

const styles = {
    container: {
        marginRight: 15
    } as CSSProperties,
    moneyIcon: {
        width: 20
    } as CSSProperties,
    emphasisText: {
        color: Colors.lightPurple,
        fontWeight: 900
    }
};
