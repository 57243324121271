import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import CheckmarkIcon from 'src/assets/checkmarkGreen.png';
import qs from 'querystring';
import { Colors } from 'src/utils/colors';

export const JustSavedBot = () => {
    const history = useHistory();
    const { wasNewBot } = qs.parse(history.location.search.slice(1)) || '';

    return wasNewBot ? (
        <div style={styles.container}>
            <img src={CheckmarkIcon} style={styles.checkmarkIcon} />
            <label style={styles.text}>
                Your bot has been saved and deployed. You can now add
                integrations or{' '}
                <a
                    href="/my-bots"
                    style={{
                        color: Colors.prettyGreen,
                        textDecoration: 'underline'
                    }}
                >
                    finish setup early.
                </a>
            </label>
        </div>
    ) : null;
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 16,
        marginBottom: 26,
        backgroundColor: Colors.white,
        boxShadow: '0px 5px 24px rgba(100,100,100,0.17)',
        padding: 14,
        borderRadius: 12
    } as CSSProperties,
    checkmarkIcon: {
        width: 22,
        height: 22
    },
    text: {
        marginLeft: 10,
        fontSize: 16,
        color: Colors.prettyGreen
    }
};
