import 'antd/dist/antd.css';

import * as Sentry from '@sentry/react';
import { Switch, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Api } from 'src/api';
import { fetchMyBots } from 'src/redux/bot/action';
import { analytics, SegmentEvents } from 'src/utils/segmentClient';
import { BotDocument } from 'trellis-types';
import { useIsWindowSkinny } from 'src/utils/windowHooks';
import Swal from 'sweetalert2';
import { getErrorMessage } from 'src/utils/helpers';

type Props = {
    bot: BotDocument;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
};

export const ToggleBotIsActive = ({ bot, isLoading, setIsLoading }: Props) => {
    const dispatch = useDispatch();
    const isWindowSkinny = useIsWindowSkinny();

    const handleToggleBotIsActive = async (isChecked: boolean) => {
        try {
            setIsLoading(true);
            await Api.bots.updateBot(bot._id, {
                isActive: isChecked
            });
            analytics.track(SegmentEvents.ToggledBot, {
                bot_id: bot._id,
                ticker: bot.ticker,
                toggled_active: isChecked
            });
            await fetchMyBots(dispatch);
        } catch (e) {
            Sentry.captureException(e);
            Swal.fire({
                title: 'Oops',
                text: getErrorMessage(e),
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };
    return isWindowSkinny ? null : (
        <Tooltip title={bot.isActive ? 'Click to pause bot' : 'Click to unpause bot'}>
            <Switch
                checked={bot.isActive}
                onChange={handleToggleBotIsActive}
                loading={isLoading}
                style={styles.switch}
                checkedChildren="ON"
                unCheckedChildren="OFF"
            />
        </Tooltip>
    );
};

const styles = {
    switch: {
        marginRight: 15
    }
};
