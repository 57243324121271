import Swal from 'sweetalert2';
import firebase from 'firebase';
import { SegmentEvents, analytics } from 'src/utils/segmentClient';

// must wait this many seconds before sending another verification email.
export const SECONDS_BETWEEN_VERIFICATION = 240;

// if the user refreshes the page and this many seconds have gone by since
// the last verification email was sent, a new one will be sent.
export const SECONDS_BETWEEN_AUTO_RESEND = 3600;

export const getSecondsSinceEmailVerificationWasSent = (
    emailVerificationTimestampStr: string
) => {
    if (!emailVerificationTimestampStr) {
        return 0;
    }
    const timestampInt = parseInt(emailVerificationTimestampStr);
    const now = new Date();
    const secondsDiff = Math.round((now.getTime() - timestampInt) / 1000);
    return secondsDiff;
};

export const getWasEmailVerificationSentRecently = (
    emailVerificationTimestampStr: string
) => {
    const secondsSinceLastVerificationEmail = getSecondsSinceEmailVerificationWasSent(
        emailVerificationTimestampStr
    );
    return secondsSinceLastVerificationEmail < SECONDS_BETWEEN_VERIFICATION;
};

export const getSecondsUntilCanResendVerificationEmail = (
    emailVerificationTimestampStr: string
) =>
    SECONDS_BETWEEN_VERIFICATION -
    getSecondsSinceEmailVerificationWasSent(emailVerificationTimestampStr);

export const authStateEmailVerificationCheck = (user: any) => {
    const emailVerificationTimestampStr = localStorage.getItem(
        'sentEmailVerificationTimestamp'
    );
    const emailSentTo = localStorage.getItem('emailVerificationSentTo');
    if (
        emailSentTo !== user.email ||
        !emailVerificationTimestampStr ||
        getSecondsSinceEmailVerificationWasSent(emailVerificationTimestampStr) >
            SECONDS_BETWEEN_AUTO_RESEND
    ) {
        localStorage.setItem(
            'sentEmailVerificationTimestamp',
            new Date().getTime().toString()
        );
        localStorage.setItem('emailVerificationSentTo', user.email);
        user.sendEmailVerification();
    }
};

export const finishVerification = async () => {
    await firebase.auth().currentUser?.reload();
    const user = firebase.auth().currentUser;
    if (!user?.emailVerified) {
        Swal.fire({
            text: `Please click the link in the email we sent to ${user?.email} and then hit the I'm Ready button.`,
            icon: 'warning'
        });
    } else {
        analytics.track(SegmentEvents.VerifiedEmail);
    }
};
