import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBuyTriggers, setSellTriggers } from 'src/redux/bot/action';
import {
    getBotId,
    getBuyTriggers,
    getSellTriggers
} from 'src/redux/bot/selector';
import { indicatorMap } from 'src/utils/constants';
import {
    ConjunctionType,
    DecisionEnum,
    FrontendTrigger,
    IndicatorEnum,
    TriggerDocument,
    TriggerGroupDocument,
    TriggerMatrixMap
} from 'trellis-types';
import { uuid } from 'uuidv4';

// converts trigger blocks into correct format to be passed to api
export const processTriggerBlocks = (
    buyTriggers: FrontendTrigger[],
    sellTriggers: FrontendTrigger[]
): TriggerMatrixMap => ({
    BUY: convertBlocksToMatrix(buyTriggers),
    SELL: convertBlocksToMatrix(sellTriggers)
});

// converts trigger blocks into matrix format which will be parsed on server
export const convertBlocksToMatrix = (triggerBlocks: FrontendTrigger[]) => {
    let triggerGroups: FrontendTrigger[][] = [];

    //used to store triggerblocks in a group
    let tempArray: FrontendTrigger[] = [];

    triggerBlocks.forEach(triggerBlock => {
        if (!triggerBlock.conjunction || triggerBlock.conjunction === 'AND') {
            // if there is no conjunction or the conjunction is AND, add it to the current trigger group
            tempArray.push(triggerBlock);
        } else {
            // if the conjunction is OR, form a new trigger group
            triggerGroups.push(tempArray);
            tempArray = [triggerBlock];
        }
    });
    if (tempArray.length) {
        triggerGroups.push(tempArray);
    }
    return triggerGroups;
};

// basic frontend validation. backend does much more in-depth validation.
export const validateTriggerBlocks = (triggerBlocks: FrontendTrigger[]) => {
    triggerBlocks.forEach(triggerBlock => {
        if (triggerBlock.operand === '') {
            throw new Error('Please enter an operand.');
        } else if (!triggerBlock.operator) {
            throw new Error('Please enter an operator.');
        }
    });
};

export const convertBackendTriggersToFrontendTriggers = (
    triggers: TriggerDocument[],
    triggerGroups: TriggerGroupDocument[]
) => {
    let reviewedTriggerGroupIds: string[] = [];
    let frontendBuyTriggers: FrontendTrigger[] = [];
    let frontendSellTriggers: FrontendTrigger[] = [];
    triggers.forEach(t => {
        // goes through each trigger, if the trigger's trigger group
        // parent has already been reviewed then we can assume the conjunction is AND.
        // otherwise it is OR.
        const alreadyReviewedTriggerGroup = reviewedTriggerGroupIds.includes(
            t.triggerGroup as string
        );
        if (!alreadyReviewedTriggerGroup) {
            reviewedTriggerGroupIds.push(t.triggerGroup as string);
        }
        const typeOfTrigger = triggerGroups.find(
            tg => tg._id === t.triggerGroup
        )?.type;
        let conjunction: ConjunctionType | null = null;
        if (
            (typeOfTrigger === DecisionEnum.Buy &&
                frontendBuyTriggers.length) ||
            (typeOfTrigger === 'SELL' && frontendSellTriggers.length)
        ) {
            conjunction = alreadyReviewedTriggerGroup ? 'AND' : 'OR';
        }
        const operand =
            (indicatorMap[t.indicator.operand as IndicatorEnum]
                ? t.indicator.operand
                : t.indicator.options.threshold) || 0;
        const indicator = t.indicator.type;
        const operator = t.indicator.operator;
        const multiplier = t.indicator.options?.multiplier;
        const addend = t.indicator.options?.addend;

        if (typeOfTrigger === DecisionEnum.Buy) {
            frontendBuyTriggers.push({
                conjunction,
                operand,
                operator,
                indicator,
                multiplier,
                addend,
                blockId: uuid()
            });
        } else if (typeOfTrigger === 'SELL') {
            frontendSellTriggers.push({
                conjunction,
                operand,
                operator,
                indicator,
                multiplier,
                addend,
                blockId: uuid()
            });
        }
    });
    return { frontendBuyTriggers, frontendSellTriggers };
};

// ensures that trigger blocks stay in correct format when certain edits
// are made such as trigger deletion
export const useTriggerCleaner = () => {
    const dispatch = useDispatch();
    const botId = useSelector(getBotId);
    const buyTriggers = useSelector(getBuyTriggers);
    const sellTriggers = useSelector(getSellTriggers);

    const handleCleanBuyTriggers = () => {
        const newBuyTriggers = buyTriggers.map(trigger => {
            // make sure all buy triggers have correct conjunction
            if (trigger.conjunction && buyTriggers.length === 1) {
                return {
                    ...trigger,
                    conjunction: null
                };
            }
            return trigger;
        });
        dispatch(setBuyTriggers(newBuyTriggers));
    };

    const handleCleanSellTriggers = () => {
        const newSellTriggers = sellTriggers.map(trigger => {
            // make sure all sell triggers have correct conjunction
            if (trigger.conjunction && sellTriggers.length === 1) {
                return {
                    ...trigger,
                    conjunction: null
                };
            }
            return trigger;
        });
        dispatch(setSellTriggers(newSellTriggers));
    };

    useEffect(() => {
        handleCleanBuyTriggers();
    }, [buyTriggers.length, botId]);

    useEffect(() => {
        handleCleanSellTriggers();
    }, [sellTriggers.length, botId]);

    return null;
};

// uses this value if the inputted picker value
// does not exist in the indicatorMap, meaning
// it is a raw, numerical value.
export const getRawValue = (val: string) => {
    if (val === '' || val === '-') {
        return val;
    }
    if (val.toString().includes('.')) {
        return Number.isNaN(Number(val)) || val.indexOf('.') === val.length - 1
            ? val
            : Number(val);
    }
    return Number.isNaN(Number(val)) ? '' : Number(val);
};
