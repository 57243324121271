import React, { CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';
import { Api } from 'src/api';
import { getBotId, getBotName } from 'src/redux/bot/selector';
import { getUser } from 'src/redux/user/selector';
import { Colors } from 'src/utils/colors';
import { getErrorMessage } from 'src/utils/helpers';
import { SegmentEvents } from 'src/utils/segmentClient';
import { Styles } from 'src/utils/styles';
import Swal from 'sweetalert2';
import { IntegrationEnum } from 'trellis-types';
import { RenderModalProps } from '../../lib';

export const EmailModal = ({ onClose }: RenderModalProps) => {
    const botId = useSelector(getBotId);
    const botName = useSelector(getBotName);
    const user = useSelector(getUser);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleConnect = async () => {
        try {
            setIsLoading(true);
            await Api.integrations.email.subscribe(botId);
            analytics.track(SegmentEvents.AddedIntegration, {
                type: IntegrationEnum.Email
            });
            Swal.fire({
                title: 'Success',
                text: `We will now email ${botName} bot alerts to ${user?.email}.`,
                icon: 'success'
            });
            onClose();
        } catch (e) {
            Swal.fire({
                title: 'Sorry',
                text: getErrorMessage(e),
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.contentContainer}>
                <label style={Styles.modalSubheaderText}>Overview</label>
                <br />
                <label style={Styles.modalDescriptionText}>
                    Our Email integration allows you to subscribe to bot updates
                    through emails. Connect to the integration using the button
                    below.
                </label>
                <br />
                <div style={styles.btnContainer}>
                    <button
                        disabled={isLoading}
                        onClick={handleConnect}
                        style={styles.connectBtn}
                    >
                        Connect to {user?.email}
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    } as CSSProperties,
    contentContainer: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingBottom: 60,
        maxWidth: 800,
        textAlign: 'left'
    } as CSSProperties,
    slackScreenCapContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 30
    } as CSSProperties,
    slackScreenCap: {
        borderRadius: 10,
        width: '100%',
        maxWidth: 500
    } as CSSProperties,
    slackBtnWrap: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 20
    } as CSSProperties,
    codeBox: {
        marginTop: 30,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 30,
        background: '#f6f6f6',
        borderRadius: 10
    } as CSSProperties,
    copyBtn: {
        borderRadius: 10,
        padding: '5px 10px 5px 10px',
        cursor: 'pointer',
        border: 'none',
        outline: 'none'
    } as CSSProperties,
    copyBtnText: {
        fontSize: 12,
        fontWeight: 600,
        color: Colors.white,
        cursor: 'pointer'
    } as CSSProperties,
    copyIcon: {
        display: 'inline-block',
        width: 20,
        cursor: 'pointer',
        marginLeft: 5
    } as CSSProperties,
    connectBtn: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.purple,
        color: Colors.white,
        fontFamily: 'Manrope',
        padding: 12,
        minWidth: 130,
        borderRadius: 10,
        fontWeight: 600,
        cursor: 'pointer'
    },
    btnContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: 50
    } as CSSProperties
};
