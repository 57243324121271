import axios from '../../utils/axios';

type UpdateIntegrationFields = {
    isApproved: boolean;
};

const Integrations = {
    deleteIntegration: async (id: string) =>
        axios.delete(`/integrations/${id}`),
    updateIntegration: async (id: string, fields: UpdateIntegrationFields) =>
        axios.put(`/integrations/${id}`, fields)
};

export default Integrations;
