import { Tooltip } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useClipboard from 'react-use-clipboard';
import { Api } from 'src/api';
import CheckmarkIcon from 'src/assets/checkmark.svg';
import LinkIcon from 'src/assets/link.svg';
import { getBot } from 'src/redux/bot/selector';
import { getUser } from 'src/redux/user/selector';
import { Colors } from 'src/utils/colors';
import { getErrorMessage } from 'src/utils/helpers';
import Swal from 'sweetalert2';
import { useIsVisitorUsingAccessCode } from '../lib';

type Props = {
    botId?: string;
};

export const ShareLink = ({ botId }: Props) => {
    const bot = useSelector(getBot);
    const [accessCode, setAccessCode] = useState<string>('');
    const [isCopied, setIsCopied] = useClipboard(
        `https://www.trellistrade.co/bot-workspace/${botId}/metrics?accessCode=${accessCode}`
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isVisitorUsingAccessCode } = useIsVisitorUsingAccessCode();

    useEffect(() => {
        if (accessCode) {
            setIsCopied();
        }
    }, [accessCode]);

    const handleCreateShareLink = async () => {
        try {
            setIsLoading(true);
            const res = await Api.bots.createShareLink(botId || '');
            const { shareUrl } = res.data;
            setAccessCode(shareUrl._id);
        } catch (e) {
            Swal.fire({
                title: 'Oops',
                text: getErrorMessage(e),
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    return bot ? (
        <Tooltip
            title={
                isVisitorUsingAccessCode
                    ? 'Only the owner can edit this bot'
                    : 'Generate a shareable link to give others read-only permission to your bot'
            }
        >
            <button
                style={{
                    ...styles.container,
                    ...(isCopied && { backgroundColor: Colors.green }),
                    ...(isLoading && { opacity: 0.4 }),
                    ...(isVisitorUsingAccessCode &&
                        ({
                            width: 200,
                            cursor: 'auto'
                        } as CSSProperties))
                }}
                disabled={isLoading || isCopied || isVisitorUsingAccessCode}
                className={isVisitorUsingAccessCode ? '' : 'share-bot-btn'}
                onClick={handleCreateShareLink}
            >
                {isVisitorUsingAccessCode ? (
                    <label
                        style={{
                            ...styles.shareLbl,
                            ...(isVisitorUsingAccessCode && { cursor: 'auto' })
                        }}
                    >
                        This bot is read-only
                    </label>
                ) : (
                    <>
                        <img
                            src={isCopied ? CheckmarkIcon : LinkIcon}
                            style={styles.linkIcon}
                            alt=""
                        />
                        <label
                            style={{
                                ...styles.shareLbl,
                                ...(isCopied && { color: Colors.white })
                            }}
                        >
                            {isCopied ? 'Copied!' : 'Share Bot'}
                        </label>{' '}
                    </>
                )}
            </button>
        </Tooltip>
    ) : null;
};

const styles = {
    container: {
        position: 'absolute',
        top: 20,
        right: 30,
        paddingTop: 5,
        paddingBottom: 5,
        width: 120,
        outline: 'none',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgb(230,230,230)',
        boxShadow: '0px 2px 5px rgba(233,233,233,1)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        cursor: 'pointer'
    } as CSSProperties,
    linkIcon: {
        width: 20,
        height: 20,
        cursor: 'pointer'
    },
    shareLbl: {
        color: Colors.tundora,
        fontSize: 14,
        marginLeft: 5,
        cursor: 'pointer',
        fontWeight: 500
    }
};
