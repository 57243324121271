import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useClipboard from 'react-use-clipboard';
import { Api } from 'src/api';
import CopyIcon from 'src/assets/copyIcon.png';
import SlackOauthScreenCap from 'src/assets/slackOauthScreenCap.png';
import SlackScreenCap from 'src/assets/slackScreenCap.png';
import { getBotId, getBotName } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { analytics, SegmentEvents } from 'src/utils/segmentClient';
import { Styles } from 'src/utils/styles';
import { IntegrationEnum } from 'trellis-types';

export const SlackModal = () => {
    const botId = useSelector(getBotId);
    const windowWidth = useWindowWidth();
    const [isCopied, setIsCopied] = useClipboard(`/trellis subscribe ${botId}`);
    const [installUrl, setInstallUrl] = useState<string | null>(null);

    useEffect(() => {
        Api.integrations.slack.getInstallUrl().then(res => {
            const url = res.data.installationUrl;
            setInstallUrl(url);
        });
    }, []);

    if (!installUrl) {
        return null;
    }

    return (
        <div style={styles.container}>
            <div style={styles.contentContainer}>
                <label style={Styles.modalSubheaderText}>Overview</label>
                <br />
                <label style={Styles.modalDescriptionText}>
                    Our Slack integration allows you to subscribe to bot updates
                    in any channel using a single command. To set this up in
                    your Slack workspace, follow the instructions below.
                </label>
                <br />
                <div style={styles.slackScreenCapContainer}>
                    <img
                        src={SlackScreenCap}
                        style={styles.slackScreenCap}
                        alt="slack-screen-cap"
                    />
                </div>
                <br />
                <label style={Styles.modalSubheaderText}>
                    Trellis Slack App Setup
                </label>
                <br />
                <label style={Styles.modalDescriptionText}>
                    First you'll need to add the Trellis App to your Slack
                    workspace by clicking the button below. This will start the
                    process for connecting your Slack workspace to Trellis. If
                    your workspace is already connected to Trellis from a
                    previous install, you can skip these steps!
                </label>
                <br />
                <div style={styles.slackBtnWrap}>
                    <a
                        href={installUrl}
                        onClick={() =>
                            analytics.track(SegmentEvents.AddedIntegration, {
                                type: IntegrationEnum.Slack
                            })
                        }
                        target="_blank"
                    >
                        <img
                            alt="Add to Slack"
                            height="40"
                            width="139"
                            src="https://platform.slack-edge.com/img/add_to_slack.png"
                        />
                    </a>
                </div>
                <br />
                <label style={Styles.modalDescriptionText}>
                    Once you click the above, you will be shown the following
                    OAuth screen. This just gives us permission to post bot
                    updates in your Slack channel. Press allow to install the
                    app to your Slack workspace.
                </label>
                <div style={styles.slackScreenCapContainer}>
                    <img
                        src={SlackOauthScreenCap}
                        style={styles.slackScreenCap}
                        alt="slack-screen-cap"
                    />
                </div>
                <br />
                <label style={Styles.modalDescriptionText}>
                    After granting permissions to Trellis, you should be
                    redirected to your Slack workspace. Trellis should now be
                    present under the "Apps" tab of your Slack sidebar. 🎉
                </label>
                <br />
                <label style={{ ...Styles.modalSubheaderText, marginTop: 30 }}>
                    Connecting your Bot
                </label>
                <br />
                <label style={Styles.modalDescriptionText}>
                    Once you have the Trellis App installed on your Slack
                    workspace, connecting a bot to a channel is easy. Run the
                    Slack command below in whichever channel you want to send
                    updates to.
                </label>
                <div
                    style={{
                        ...styles.codeBox,
                        flexDirection: windowWidth > 800 ? 'row' : 'column'
                    }}
                >
                    <label style={{ fontSize: 16, margin: 0 }}>
                        /trellis subscribe{' '}
                        <span style={{ color: Colors.darkPurple }}>
                            {botId}
                        </span>
                    </label>
                    <button
                        style={{
                            ...styles.copyBtn,
                            background: isCopied
                                ? Colors.darkGray1
                                : Colors.darkPurple,
                            marginTop: windowWidth > 800 ? 0 : 20
                        }}
                        onClick={setIsCopied}
                    >
                        <label style={styles.copyBtnText}>
                            {isCopied ? 'Copied!' : 'Copy'}
                            <img
                                src={CopyIcon}
                                style={styles.copyIcon}
                                alt="copy"
                            />
                        </label>
                    </button>
                </div>
                <br />
                <label style={Styles.modalDescriptionText}>
                    The last step is to{' '}
                    <a
                        href={window.location.href}
                        style={{ textDecoration: 'underline' }}
                    >
                        refresh your page and then approve this integration.
                    </a>
                </label>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    } as CSSProperties,
    contentContainer: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingBottom: 60,
        maxWidth: 800,
        textAlign: 'left'
    } as CSSProperties,
    slackScreenCapContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 30
    } as CSSProperties,
    slackScreenCap: {
        borderRadius: 10,
        width: '100%',
        maxWidth: 500
    } as CSSProperties,
    slackBtnWrap: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 20
    } as CSSProperties,
    codeBox: {
        marginTop: 30,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 30,
        background: '#f6f6f6',
        borderRadius: 10
    } as CSSProperties,
    copyBtn: {
        borderRadius: 10,
        padding: '5px 10px 5px 10px',
        cursor: 'pointer',
        border: 'none',
        outline: 'none'
    } as CSSProperties,
    copyBtnText: {
        fontSize: 12,
        fontWeight: 600,
        color: Colors.white,
        cursor: 'pointer'
    } as CSSProperties,
    copyIcon: {
        display: 'inline-block',
        width: 20,
        cursor: 'pointer',
        marginLeft: 5
    } as CSSProperties
};
