import { CSSProperties } from 'react';
import { Colors } from 'src/utils/colors';

export const responsiveStyles = (isWindowSkinny: boolean) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: Colors.white,
        justifyContent: 'center',
        marginLeft: 65,
        marginRight: 65,
        ...(!isWindowSkinny && { height: '100%' })
    } as CSSProperties,
    signInTitle: {
        fontWeight: 'bold',
        fontSize: 24
    } as CSSProperties,
    inputSection: {
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    inputDesc: {
        marginTop: 20,
        marginBottom: 10,
        fontSize: 16
    },
    inputTextfield: {
        fontSize: 16,
        outline: 'none',
        borderStyle: 'solid',
        borderColor: Colors.alto,
        borderWidth: 1,
        borderRadius: 3,
        height: 32,
        maxWidth: 480,
        paddingLeft: 10
    },
    signUpInsteadButton: {
        textDecoration: 'none',
        color: Colors.purple,
        marginTop: 10
    },
    signInButton: {
        outline: 'none',
        border: 'none',
        width: '18%',
        minWidth: 140,
        height: 38,
        marginTop: 30,
        color: Colors.white,
        backgroundColor: Colors.purple,
        fontWeight: 700,
        fontSize: 16,
        cursor: 'pointer',
        borderRadius: 12,
        fontFamily: 'Manrope'
    },
    errorMessage: {
        marginTop: 10,
        color: Colors.red,
        fontSize: 14,
        fontWeight: 600
    },
    submitSection: {
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    forgotPassword: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.transparent,
        color: Colors.purple,
        fontFamily: 'Manrope',
        marginTop: 10,
        cursor: 'pointer'
    }
});
