import axios from '../utils/axios';

const Airtable = {
    getWaitlistStatus: async (email: string) =>
        axios.get('/airtable/waitlist-status', {
            params: {
                email
            }
        })
};

export default Airtable;
