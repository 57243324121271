import * as yup from 'yup';

// min/max money amounts to give your bot to start with.
// this is purely for formatting purposes for now.
const MIN_BUYING_POWER = 1;
const MAX_BUYING_POWER = 500000;

export const setupBotValidationSchema = yup.object({
    botName: yup
        .string()
        .required('Please enter a bot name.')
        .test(
            'hasNoSpaces',
            'Your bot name cannot have any spaces.',
            val => !(val || '')?.includes(' ')
        ),
    initialBuyingPower: yup
        .number()
        .required('Please enter a number.')
        .min(
            MIN_BUYING_POWER,
            `Please give your bot at least $${MIN_BUYING_POWER} to trade with.`
        )
        .max(
            MAX_BUYING_POWER,
            `Your bot cannot trade with more than $${MAX_BUYING_POWER} (for now).`
        ),
    cooldownMins: yup
        .number()
        .required('Please enter a number.')
        .min(0, 'This must be a number greater than or equal to 0.')
});
