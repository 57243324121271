import SlackIcon from 'src/assets/integrationIcons/slack.png';
import SMSIcon from 'src/assets/integrationIcons/sms.png';
import EmailIcon from 'src/assets/integrationIcons/email.png';
import AlpacaIcon from 'src/assets/alpacaIcon.png';
import { IntegrationDocument, IntegrationEnum } from 'trellis-types';

import { SlackModal } from 'src/screens/botWorkspace/screens/Integrations/components/integrationModals/SlackModal';
import { TwilioModal } from 'src/screens/botWorkspace/screens/Integrations/components/integrationModals/TwilioModal';
import { EmailModal } from './components/integrationModals/EmailModal';

export type RenderModalProps = {
    onClose: () => void;
    isRealMoneyIntegration?: boolean;
};

export type DumbIntegrationBody = {
    imageSource: string;
    displayName: string;
    renderModal: (props: RenderModalProps) => any;
};

export type FakeDumbIntegrationBody = {
    imageSource: string;
    displayName: string;
    cannotRemoveMsg: string;
};

// keys of integrations that aren't actual integrations in our db, but should look like integrations to the user
// cannot remove these integrations individually, can only remove them by deleting the bot.
export enum NonRealIntegrationKeysEnum {
    Alpaca = 'ALPACA'
}

// @ts-ignore
export const integrationMap: {
    [key in IntegrationEnum]: DumbIntegrationBody;
} = {
    SLACK: {
        displayName: 'Slack',
        imageSource: SlackIcon,
        renderModal: SlackModal
    },
    TWILIO: {
        displayName: 'SMS',
        imageSource: SMSIcon,
        renderModal: TwilioModal
    },
    EMAIL: {
        displayName: 'Email',
        imageSource: EmailIcon,
        renderModal: EmailModal
    }
};

export const fakeIntegrationMap: {
    [key in NonRealIntegrationKeysEnum]: FakeDumbIntegrationBody;
} = {
    ALPACA: {
        displayName: 'Alpaca',
        imageSource: AlpacaIcon,
        cannotRemoveMsg:
            'You cannot remove the Alpaca integration from a real-money bot. Please delete this bot to disconnect Alpaca from the bot.'
    }
};

// sorts the integrations so the active integrations go to the top
export const getSortedIntegrationKeys = (
    activeIntegrations: IntegrationDocument[]
) =>
    Object.keys(integrationMap).sort((keyA, keyB) => {
        const integrationAIsUsed = !!activeIntegrations.find(
            integration => integration.type === keyA
        );
        const integrationBIsUsed = !!activeIntegrations.find(
            integration => integration.type === keyB
        );
        return integrationAIsUsed && !integrationBIsUsed ? -1 : 1;
    });
