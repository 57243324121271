import _ from 'lodash';
import React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { getTicker } from 'src/redux/bot/selector';
import { Colors } from 'src/utils/colors';
import { IndicatorExplanation, OperatorExplanation } from 'src/utils/constants';
import { Styles } from 'src/utils/styles';
import { FrontendTrigger } from 'trellis-types';

type Props = {
    isVisible: boolean;
    onClose: () => void;
    triggerBlock: FrontendTrigger;
};

export const TriggerModal = ({ isVisible, onClose, triggerBlock }: Props) => {
    const { indicator, operator, operand } = triggerBlock;
    const ticker = useSelector(getTicker);
    const indicatorInfo = _.get(IndicatorExplanation, indicator);
    const operatorInfo = _.get(OperatorExplanation, operator);

    const solidNumber = {
        title: '',
        explanation: 'A numerical value that you set.'
    };
    const operandIsNumber = typeof operand == 'number';
    let operandInfo = _.get(IndicatorExplanation, operand);
    if (operandIsNumber) {
        solidNumber.title = 'Number ' + operand.toString();
        operandInfo = solidNumber;
    }
    const multiplier = triggerBlock.multiplier;
    const addend = triggerBlock.addend;
    const triggerSectionCollection = [
        { sectionTitle: 'Indicator', info: indicatorInfo },
        { sectionTitle: 'Operator', info: operatorInfo },
        { sectionTitle: 'Operand', info: operandInfo }
    ];

    const renderInfo = () =>
        triggerSectionCollection.map(triggerSection => {
            return (
                <div style={{ padding: '5px' }}>
                    <label style={Styles.modalSubheaderText}>
                        {triggerSection.sectionTitle +
                            ': ' +
                            _.get(triggerSection.info, 'title', 'N/A')}
                    </label>
                    <br />
                    <div style={{ color: Colors.darkGray1 }}>
                        <label style={Styles.modalDescriptionText}>
                            {_.get(triggerSection.info, 'explanation')}
                        </label>
                    </div>
                </div>
            );
        });
    return (
        <Modal
            isOpen={isVisible}
            onRequestClose={onClose}
            style={Styles.modal}
            ariaHideApp={false}
            shouldCloseOnOverlayClick
        >
            <div style={Styles.modalHeader}>
                <button onClick={onClose} style={Styles.modalCloseBtn}>
                    Close
                </button>
                <div style={Styles.modalInnerContainer}>
                    <label style={Styles.modalTitle}>
                        What is this trigger?
                    </label>
                </div>
            </div>
            <div style={styles.innerBodyContainer}>
                <div style={{ padding: '5px' }}>
                    <label style={Styles.modalSubheaderText}>
                        This trigger will pass whenever the{' '}
                        <span style={styles.highlightedWord}>
                            {_.get(
                                triggerSectionCollection[0].info,
                                'title',
                                '{indicator}'
                            ).toLowerCase()}
                        </span>
                        <span> of {ticker}</span>
                        {typeof multiplier === 'number' ? (
                            <> multiplied by {multiplier}</>
                        ) : null}
                        {typeof addend === 'number' ? (
                            <>
                                {typeof multiplier === 'number' ? ', ' : ' '}
                                plus {addend}
                            </>
                        ) : null}
                        <span style={styles.highlightedWord}>
                            {' '}
                            {_.get(
                                triggerSectionCollection[1].info,
                                'title',
                                '{operator}'
                            ).toLowerCase()}
                        </span>{' '}
                        the{' '}
                        <span style={styles.highlightedWord}>
                            {_.get(
                                triggerSectionCollection[2].info,
                                'title',
                                '{operand}'
                            ).toLowerCase()}
                        </span>
                        .
                    </label>
                </div>
                {renderInfo()}
            </div>
        </Modal>
    );
};

const styles = {
    innerBodyContainer: {
        marginTop: 100
    },
    highlightedWord: {
        color: Colors.purple
    }
};
