import React, { CSSProperties } from 'react';
import RevenueGraphic from 'src/assets/revenueGraphic.png';
import { Colors } from 'src/utils/colors';

export const LargeGraphic = () => (
    <div style={styles.container}>
        <img src={RevenueGraphic} style={styles.image} alt="" />
    </div>
);

const styles = {
    container: {
        backgroundColor: Colors.blackSqueeze,
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    } as CSSProperties,
    image: {
        objectFit: 'contain',
        width: '50%'
    } as CSSProperties
};
