import React, { CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMyBots } from 'src/redux/bot/selector';
import { getUser } from 'src/redux/user/selector';
import { Colors } from 'src/utils/colors';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import { PlanEnum } from 'trellis-types';
import { Constants } from 'src/utils/constants';

type Props = {
    disabled: boolean;
};

export const CreateBotButton = ({ disabled }: Props) => {
    const user = useSelector(getUser);
    const myBots = useSelector(getMyBots);
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const history = useHistory();
    const styles = responsiveStyles(window.innerWidth);

    const handleCreateNewBot = () => {
        if (isMobile) {
            Swal.fire({
                title: 'Sorry',
                text: 'You can only create a new bot on a computer or tablet (for now).'
            });
            return;
        }
        const botLimit = _.get(user, 'botLimit', 0);
        if (
            user?.currentPlan === PlanEnum.Free
                ? myBots.length >= botLimit
                : myBots.length >= Constants.MAX_BOTS_PREMIUM
        ) {
            Swal.fire({
                title: 'Sorry',
                text: `You cannot have more than ${botLimit} bots at one time. Please delete a bot before creating another.`,
                icon: 'error'
            });
            return;
        }
        history.push('/setup-bot/stocks');
    };

    return (
        <button
            disabled={disabled}
            onClick={handleCreateNewBot}
            style={{
                ...styles.button,
                ...(disabled && { opacity: 0.3 }),
                ...(isHovering && { backgroundColor: Colors.darkPurple })
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            Create new bot
        </button>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    button: {
        outline: 'none',
        border: 'none',
        width: 170,
        height: 40,
        fontSize: 17,
        fontWeight: 800,
        color: Colors.white,
        borderRadius: 12,
        backgroundColor: Colors.purple,
        backgroundSize: 'contain',
        cursor: 'pointer',
        fontFamily: 'Manrope',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
        ...(windowWidth < 700
            ? { position: 'absolute', top: 20, right: 20 }
            : { marginTop: 22 })
    } as CSSProperties
});
