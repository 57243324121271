import React, { CSSProperties } from 'react';
import { Colors } from 'src/utils/colors';
import MoneyBot from 'src/assets/money-bot.png';
import BotGif from 'src/assets/alpacaConnection.gif';
import { Branding } from './components/Branding';
import { isMobile } from 'react-device-detect';

export const AlpacaInstructions = () => {
    return (
        <div style={styles.background}>
            <div style={styles.descriptionContainer}>
                <Branding />
                <div style={styles.descriptionHeader}>
                    Build a trading bot without code
                </div>
                <a style={styles.signUpBtn} href="/sign-up">
                    Sign up for free
                </a>
                <img src={MoneyBot} style={styles.tradingBot} alt="" />
            </div>
            <div style={{ ...styles.descriptionContainer, paddingBottom: 55 }}>
                <div style={styles.descriptionHeader}>Benefits of Trellis</div>
                <div style={styles.descriptionTextWithMargin}>
                    <b>
                        1. Create your personal trading bot in under 5 minutes,
                        without code.
                    </b>{' '}
                    We use an <i>if this, then that</i> format so you can easily
                    create rules for your bot. Here's an example: If the 50 day
                    moving average of AAPL crosses above the 200 day moving
                    average, then buy 10 shares.
                </div>
                <div style={styles.descriptionTextWithMargin}>
                    <b>
                        2. Connect to social integrations and receive alerts
                        when your bot wants to buy or sell.
                    </b>{' '}
                    We currently support SMS, Slack, and email, with more
                    integrations on the way.
                </div>
                <div style={styles.descriptionTextWithMargin}>
                    <b>3. Support for both paper trading and live trading.</b>{' '}
                    For live trading, we use a text-to-trade system connected to
                    Alpaca. This means whenever your bot wants to make a trade,
                    it will text you before doing so. You must text back to
                    approve the trade. We'll never make a trade involving real
                    money without your approval.
                </div>
                <div style={styles.descriptionTextWithMargin}>
                    <b>
                        4. Compete against other users to see who has the best
                        bot.
                    </b>{' '}
                    Climb the leaderboard and become the highest-performing bot
                    builder!
                </div>
                <div style={styles.descriptionTextWithMargin}>
                    <b>5. Dedicated support team.</b> We'll always respond to
                    questions within a day of receiving them. We're also happy
                    to hop on a call with you for more help, as well as to help
                    you build a bot that fits your strategies.
                </div>
                <div style={styles.descriptionTextWithMargin}>
                    <b>6. It's completely free.</b>
                </div>
            </div>
            <div style={styles.descriptionContainer}>
                <div style={styles.descriptionHeader}>
                    Connect a bot to Alpaca in 2 steps
                </div>
                <div
                    style={{
                        ...styles.descriptionText,
                        marginBottom: 12,
                        marginTop: 20
                    }}
                >
                    1. Make an Alpaca account if you don't already have one.
                </div>
                <div style={styles.descriptionText}>
                    2. Create a bot that trades with real money and follow the
                    setup instructions.
                </div>
                <img src={BotGif} style={styles.screenshot} />
            </div>
            <div style={styles.descriptionHeader}>
                Automate your trading, without all the code.
            </div>
            <a
                style={{ ...styles.signUpBtn, marginBottom: 100 }}
                href="/sign-up"
            >
                Sign up today for free
            </a>
        </div>
    );
};

const styles = {
    background: {
        backgroundColor: Colors.paper,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    } as CSSProperties,
    header: {
        color: Colors.tundora,
        fontSize: 50,
        fontWeight: 'bolder',
        textAlign: 'center'
    } as CSSProperties,
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.tundora,
        textAlign: 'center',
        alignItems: 'center',
        paddingTop: 50,
        ...(!isMobile && {
            maxWidth: '50%'
        })
    } as CSSProperties,
    descriptionHeader: {
        fontSize: 35,
        fontWeight: 'bold'
    } as CSSProperties,
    descriptionText: {
        fontSize: 20,
        width: '100%'
    } as CSSProperties,
    tradingBot: {
        width: 400,
        height: 400,
        padding: 20,
        marginTop: 25
    } as CSSProperties,
    screenshot: {
        padding: 20,
        width: 610,
        height: 400,
        marginBottom: 50
    } as CSSProperties,
    signUpBtn: {
        outline: 'none',
        border: 'none',
        backgroundColor: Colors.purple,
        cursor: 'pointer',
        paddingLeft: 17,
        paddingRight: 17,
        paddingTop: 8,
        paddingBottom: 8,
        color: Colors.white,
        borderRadius: 10,
        marginTop: 32,
        fontSize: 16,
        fontWeight: 600
    },
    descriptionTextWithMargin: {
        fontSize: 20,
        width: '100%',
        marginTop: 30
    }
};
