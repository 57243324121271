import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getBot } from 'src/redux/bot/selector';
import { getUser } from 'src/redux/user/selector';
import qs from 'qs';

export const useIsVisitorUsingAccessCode = () => {
    const history = useHistory();
    const { accessCode } = qs.parse(history.location.search.slice(1)) || '';
    const bot = useSelector(getBot);
    const user = useSelector(getUser);

    return {
        accessCode,
        isVisitorUsingAccessCode: Boolean(
            // true if the accessCode exists in the url and the bot owner is not this logged in user.
            accessCode && (!bot || bot?.user !== user?._id)
        )
    };
};
