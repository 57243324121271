import React from 'react';
import AvatarEditor from 'react-avatar-editor';

type Props = {
    file: any | null;
    editorRef: any;
};

export const CropProfilePic = ({ file, editorRef }: Props) =>
    file ? (
        <div>
            <AvatarEditor
                ref={editorRef}
                image={file}
                width={200}
                height={200}
                border={15}
                borderRadius={100}
                color={[180, 180, 180, 0.8]} // RGBA
                scale={1.2}
                rotate={0}
            />
        </div>
    ) : null;
